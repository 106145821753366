import { ROUTES } from "./enums";

export const CONST_ERROR_LIST = {
  required: "required",
  pattern: "checkPattern",
  wrong_card: "invalidPan",
  wrong_date: "invalidDate",
  wrong_cvv: "invalidCvv",
  wrongNameCard: "invalidCardName",
};

export const PATTERNS = {
  PHONE: /^\+?[1-9]\d{9,14}$/,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  DATE: /^(0?[1-9]|[12][0-9]|3[01])([.,\/\-_\\ ])(0?[1-9]|1[0-2])\2(19[0-9]{2}|20[01][0-9]|2100)$/,
  DATE_ISO: /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
};

export const FILTER_PATTERNS = {
  PHONE: /^\+?\d*/g,
};

export const CONST_ERROR_LIST_PB = {
  requiredDate: "requiredDatePb",
  requiredCvv: "requiredCvvPb",
  wrong_card: "invalidPanPb",
  wrong_date: "invalidDate",
  wrong_cvv: "invalidCvv",
};

export const HTTP_ERRORS = {
  "000": {
    title: "noConnection",
    description: null,
  },
  400: {
    title: "badRequestTitle",
    description: "goBackAndRetry",
  },
  401: {
    title: "accessDenied",
    description: "callMerchantOrGoBack",
  },
  403: {
    title: "accessDenied",
    description: "callMerchantOrGoBack",
  },
  404: {
    title: "pageNotFound",
    description: "goBackAndRetry",
  },
  408: {
    title: "responseTimeExpired",
    description: "tryAgainLater",
  },
  500: {
    title: "unexpectedError",
    description: null,
  },
  502: {
    title: "unexpectedError",
    description: null,
  },
  503: {
    title: "accessDenied",
    description: "callMerchantOrGoBack",
  },
  504: {
    title: "responseTimeExpired",
    description: "tryAgainLater",
  },
};

export const PAYMENT_DATA_ERRORS = {
  210: {
    // PROCESSING_CONNECTION_ERROR
    title: "unableToProcessPayment",
  },
  211: {
    // PROCESSING_CONNECTION_TIMEOUT
    title: "unableToProcessPayment",
  },
  213: {
    // 3D_SECURE_FAILED
    title: "authorizationFailed",
  },
  1000: {
    // BANK_INTERNAL_ERROR
    title: "unableToProcessPayment",
  },
  1001: {
    // BANK_DECLINE
    title: "transactionRejectedByBank",
  },
  1023: {
    // BANK_INVALID_CVV
    title: "bankInvalidCvvDesc",
  },
  1024: {
    // BANK_INVALID_PAN
    title: "bankInvalidPanTitle",
  },
  1053: {
    // BANK_UNSUPPORTED_CARD
    title: "noResponseFromBank",
  },
  1110: {
    // BANK_CARD_LOST_STOLEN
    title: "cardLostStolen",
  },
  1111: {
    // BANK_CARD_EXPIRED
    title: "bankCardExpiredTitle",
  },
  1112: {
    // BANK_CARD_EXPIRED
    title: "bankLimitExceededTitle",
  },
  1113: {
    // BANK_CARD_TRANSACTION_NOT_PERMITTED
    title: "transactionRejectedByBank",
  },
  1114: {
    // BANK_CARD_DECLINE_BY_ISSUER
    title: "transactionRejectedByBank",
  },
  1120: {
    // BANK_INSUFFICIENT_FUNDS_ON_CARD
    title: "insufficientFunds",
  },
  1132: {
    // BANK_LIMIT_EXCEEDED
    title: "bankLimitExceededTitle",
  },
  1140: {
    // BANK_THREE_D_SECURE_FAILED
    title: "anAuthorizeError",
  },
  1141: {
    // BANK_THREE_D_SECURE_FAILED
    title: "anAuthorizeError",
  },
  1200: {
    // BANK_RISK_DECLINE
    title: "transactionRejectedByBank",
  },
};

export const STATUS_TO_ROUTER_MAP = {
  new: ROUTES.MAIN,
  opened: ROUTES.MAIN,
  form_submitted: ROUTES.PENDING,
  challenge: ROUTES.PENDING,
  challenge_accepted: ROUTES.PENDING,
  challenge_succeeded: ROUTES.PENDING,
  success: ROUTES.SUCCESS,
  failed: ROUTES.FAIL,
  failed_retry: ROUTES.REPAY,
  error: ROUTES.ERROR,
  expired: ROUTES.ERROR,
};
