import { getPrefixAndRegexp } from "./utils";

// Specific functions for payment methods
export const METHOD_TYPE_UNIQ_OPERATIONS = {
  mobile_money: {
    filter: ({ currentState, data }) => {
      const { phone } = currentState?.mobile_money || {};

      if (!phone) {
        return data;
      }

      const filteredMethods = data.filter((item) => {
        const regexp = getPrefixAndRegexp(
          item?.currency,
          item?.channel?.ui_code,
        )?.regexp;

        return regexp ? regexp.test(phone) : false;
      });

      return filteredMethods.length > 0 ? filteredMethods : data;
    },
  },
};

export default { METHOD_TYPE_UNIQ_OPERATIONS };
