import en from "./en";
import uk from "./uk";
import ru from "./ru";
import pt from "./pt";
import jp from "./jp";
import pl from "./pl";
import tr from "./tr";
import ko from "./ko";
import es from "./es";
import kz from "./kz";

export default {
  en,
  uk,
  pl,
  jp,
  tr,
  pt,
  ko,
  es,
  ru,
  kz,
};
