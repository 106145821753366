export default {
  pan: "Número do cartão",
  exp: "data de validade",
  "asquad.exp": "data de validade",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Pagar",
  payPM: "Recarregar",
  idTransTtl: "ID Transação",
  pendingTtl: "O pagamento está sendo processado",
  "mono.pendingTtl": "Operação em processamento...",
  errorTtl: "Ocorreu um erro",
  "mono.errorTtl": "Desculpe, a transação foi recusada",
  successPaymentTtl: "O pagamento foi bem-sucedido",
  "mono.successPaymentTtl": "Transação bem-sucedida",
  cvvHint: "O código CVV está indicado na parte de trás do seu cartão",
  rememberMeHint:
    "Guardamos apenas o número do cartão e a data de validade para futuros pagamentos no nosso site",
  "mono.rememberMeHint":
    "Guardamos apenas o número do cartão e a data de validade para pagamentos subsequentes no nosso site",
  sslHintTitle: "Transferência de dados segura",
  sslHintDescription:
    "É utilizado um protocolo seguro com criptografia de 256 bits",
  required: "Campo obrigatório",
  requiredDatePb: "Digite a data de validade do cartão",
  requiredCvvPb: "Digite o código CVV do cartão",
  invalidPanPb: "Por favor, insira um número de cartão válido",
  invalidPan: "Número de cartão inválido",
  selectAmountToPay: "Selecione 'Valor a pagar' da lista sugerida.",
  initiatedAmountDeclinedTitle: "Sobre a transação P2P",
  initiatedAmountDeclined:
    "A transferência P2P é um método de envio de dinheiro em que os dados são trocados diretamente entre os usuários. Portanto, nem sempre estará disponível para processar com o valor inserido. Nesses casos, escolha o valor mais próximo da sua preferência nas opções exibidas abaixo.",
  toCompleteThePaymentFollowSteps:
    "Para concluir o pagamento, siga estes passos.",
  clickSubmitToConfirm:
    "Clique em 'Enviar' para confirmar o valor do pagamento selecionado.",
  afterSelectingAmount:
    "Após selecionar o valor, você será redirecionado para a página do banco para concluir o pagamento.",
  invalidDate: "Data inválida",
  invalidCvv: "CVV inválido",
  invalidCardName: "Nome inválido no cartão",
  dateTooltip:
    "A data de validade está localizada abaixo do número do cartão (por exemplo, 08/25)",
  expPlaceholder: "MM / YY",
  successPayment: "Pagamento bem-sucedido",
  paymentId: "ID do pagamento: ",
  redirectNotice: "Você será redirecionado em: ",
  continue: "Continuar",
  failedPayment: "Pagamento falhou",
  backToMethods: "Voltar aos métodos",
  goBack: "Voltar para a loja",
  retry: "Tentar novamente",
  noConnection: "A sua ligação com a Internet está instável. Reconectando...",
  reconnected: "Ligação à Internet reconectada",
  intentionExpired: "Intenção de pagamento expirada",
  badRequestTitle: "Não podemos processar sua solicitação 😢",
  accessDenied: "Acesso negado ⛔",
  pageNotFound: "Página não encontrada ou não existe 😢",
  responseTimeExpired: "Tempo de resposta expirado ⌛",
  goBackAndRetry:
    "Volte para o site do comerciante e tente o pagamento novamente.",
  transactionStatus: "Estado da transação",
  paymentsSlowMessage:
    "Os pagamentos estão atualmente lentos no seu banco. Geralmente, os pagamentos lentos são confirmados dentro de 30 minutos.",
  receiptPaymentConfirmation:
    "Você receberá um recibo assim que seu pagamento for confirmado.",
  confirmingTransfer:
    "Estamos confirmando sua transferência. Isso pode levar cerca de",
  doNotRefreshPage: "Por favor, não atualize esta página",
  madePayment: "Eu fiz o pagamento",
  mins: "min",
  secs: "sec",
  callMerchantOrGoBack:
    "Entre em contato com o comerciante ou volte para o site do comerciante e tente o pagamento novamente.",
  unexpectedError:
    "Não foi possível concluir sua solicitação devido a um erro inesperado 😢",
  tryAgainLater: "Tente novamente mais tarde",
  noResponseFromBank: "Não foi possível processar seu pagamento.",
  tryTheFollowing: "Aconselhamos que você pague novamente ou use outro cartão.",
  unableToProcessPayment: "Ocorreu um erro durante o seu pagamento.",
  useDifferentCard:
    "Em caso de erro repetido, aconselhamos que entre em contato com nosso serviço de suporte.",
  bankInternalErrorTitle:
    "Ocorreu um erro ao processar sua transação no lado do banco.",
  orPayWithCard: "Ou pague com cartão",
  transactionRejectedByBank: "O provedor de pagamento recusou o pagamento.",
  contactBankForReason:
    "Por favor, entre em contato com sua instituição financeira para mais assistência ou use outro método.",
  bankInvalidCvvDesc: "Código CVV incorreto",
  invalidCardCredentialsTitle: "Credenciais de cartão inválidas.",
  checkCvvAndTryAgain:
    "Verifique novamente o código CVV, ele pode ser encontrado na parte de trás do seu cartão.",
  doubleCheckCardCredential:
    "Verifique suas credenciais novamente ou use um cartão diferente.",
  bankInvalidPanTitle: "Número do cartão inválido.",
  bankInvalidPanDescription: "Número do cartão incorreto.",
  checkPanAndTryAgain: "Verifique se o número do seu cartão está correto.",
  cardExpired: "Cartão expirado",
  unfortunatelyStatusBankCard:
    "Infelizmente, o status do seu cartão não permite que você faça um pagamento.",
  useAnotherCardPayment:
    "Aconselhamos que você use outro cartão para pagamento.",
  authorizationFailed: "Falha na autorização.",
  anAuthorizeError: "Erro de autorização.",
  pleasePaymentAgain: "Por favor, tente novamente ou use outro método.",
  cardLostStolen:
    "O cartão que você inseriu está registrado como 'roubado' ou 'perdido'.",
  bankCardExpiredTitle: "A data de validade do cartão está incorreta.",
  checkCorrectExpirationDate:
    "Verifique se a data de validade do seu cartão está correta ou use um cartão válido.",
  insufficientFunds: "Fundos insuficientes.",
  useRecommendation: "Use as dicas abaixo.",
  useAnotherCard: "Use outro cartão.",
  rechargeYourCard:
    "Recarregue o cartão com o valor necessário para realizar a transação.",
  comeBackAndChangeAmount:
    "Volte para a etapa de seleção e especifique o valor disponível no cartão.",
  onlinePaymentLimited:
    "Não foi possível processar sua transação porque o limite de pagamento online foi excedido ou esgotado.",
  threeDSecureFailedTitle: "Falha na autorização.",
  tryAgainWithCurrentCard: "Tente novamente com o cartão atual",
  cardAnother: "Use outro cartão",
  cardSaved: "Cartão salvo",
  rememberMe: "Lembrar-me",
  rememberMePM: "Salve seu cartão para recargas futuras.",
  bankLimitExceededTitle: "Limite de pagamento online excedido.",
  lastThreeDigits: "Últimos três dígitos no verso do cartão de crédito.",
  sessionExpired: "Sua sessão expirou.",
  sessionExpiredDesc:
    "Volte para o site do comerciante e tente o pagamento novamente.",
  monthTitle: "Mês",
  yearTitle: "Ano",
  sumTitle: "Soma",
  orderTittle: "Número do pedido",
  loadingTtl: "Por favor, aguarde",
  "pm1.loadingTtl": "Aguarde por favor",
  "privat.loadingTtl": "Aguarde...",
  "mono.descriptionPaymentTtl": "Pagamento do pedido",
  applePayTitle: "Pagamento com",
  last3Digits: "Últimos 3 dígitos no verso do cartão de crédito",
  publicAgreementStart:
    "Ao clicar no botão 'Recarregar', você aceita os termos da",
  publicAgreementLink: "oferta pública",
  publicAgreementEnd: "para a prestação de serviços",
  // NEW TEXT
  HaveAQuestionAbout:
    'Tem uma pergunta sobre seu pagamento? Clique aqui e assista ao vídeo "Como fazer?..".',
  ApprovalHappensDuring: "A aprovação acontece em até 1 hora após o pagamento.",
  PaymentNotViaBankSlip:
    "Isso significa que o pagamento NÃO é via boleto bancário.",
  PayInLotteryUsing: "Pague na Lotérica usando os detalhes abaixo:",
  ConventionCode: "Código de convênio",
  CPFCNPJNumber: "Número do CPF/CNPJ",
  secretQuestion: "Pergunta secreta",
  secretAnswer: "Responder secreta",
  recipientEmail: "E-mail destinatário",
  recipientName: "Nome destinatário",
  answerCopy: "Resposta copiada",
  emailCopy: "Email copiado",
  questionCopy: "Pergunta de segurança copiada",
  TotalDue: "Valor total",
  amount: "Quantia",
  ProductDescription: "Descrição do produto",
  SavePDF: "SALVAR PDF",
  Copy: "Copiar",
  Share: "Compartilhar",
  // PDF
  beneficiary: "Beneficiário",
  agencyBeneficiaryCode: "Agência/Código do Beneficiário",
  code: "Código",
  codeCopied: "Código copiar",
  openThe: "Abrir o",
  pastePaymentCode: "Colar código, pagamento com código",
  howToUse: "Como usar ",
  species: "Espécie",
  quantity: "Quantidade",
  ourNumber: "Nosso número",
  documentNumber: "Número de documento",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "Vencimento",
  documentValue: "Valor Documento",
  discountsAllowances: "Desconto / Abatimentos",
  otherDeductions: "Outras deduções",
  interestPenalty: "Mora / Multa",
  otherAdditions: "Outros acréscimos",
  amountCharged: "Valor cobrado",
  send: "Enviar",
  topUpAmount: "Quantidade de recarga",
  receive: "Receber",
  payer: "Sacado",
  mechanicalAuthentication: "Autenticação mecânica",
  paymentLocation: "Local de pagamento",
  documentDate: "Data do documento",
  onTheDocument: "No documento",
  docType: "Espécie doc.",
  acceptance: "Aceite",
  processingDate: "Data processamento",
  portfolio: "Carteira",
  lowerCode: "Cód. baixa",
  mechanicalAuthenticationCompensationSlip:
    "Autenticação mecânica Ficha de Compensação",
  endorserGuarantor: "Sacador/Avalista",
  instructions:
    "Instruções (Texto de responsabilidade do Beneficiário) Não receber Pagamento em Cheque. Boleto com vencimento no final de semana. ID transação: {transID} Se tiver algum problema com a compra, acesse {email}",
  boletoDetailsTitle:
    'You can pay in banks, lotéricas, Correios, supermarkets or online.Also you can save a file now by clicking on the "Save PDF" button below.',
  // NEW TRANS await all trans

  account: "Conta",
  virtualAccountTitle:
    "Use o aplicativo do seu banco para concluir esta transferência",
  bvn: "bank_verification_number",
  bankName: "Nome do banco",
  cardHolder: "Titular do cartão",
  bankCode: "Código do banco",
  accountNumber: "Número da conta",
  accountNumberCopied: "Número da conta copiado",
  bankNameCopied: "Nome do banco copiado",
  phoneCopied: "Telefone copied",
  receiverNameCopied: "Nome do recepto copied",
  cardHolderCopied: "Titular do cartão copiado",
  bankCodeCopied: "Código do banco copiado",
  accountName: "Nome da conta",
  accountNameCopied: "Nome da conta copiado",
  panCopied: "Número do cartão copiado",
  remainingTime: "Tempo restante",
  virtualAccountFooter:
    "Você receberá uma confirmação assim que o pagamento for recebido",
  preSubmitInfo:
    "Pressione o botão abaixo para gerar os detalhes do seu pagamento",
  showDetails: "Mostrar detalhes",
  hideDetails: "Ocultar detalhes",
  description: "Descrição",
  dial: "Discar",
  launchApp: "Lançar o aplicativo",
  select: "Selecionar",
  pleaseSelectOption: "Por favor, selecione uma opção",
  walletVegaInfoContent:
    "Seu primeiro depósito pode falhar devido a requisitos de segurança (lista de permissões). Nesse caso, você receberá um e-mail da Vega Wallet em seu endereço de e-mail registrado. Por favor, confirme sua conta clicando no link no e-mail e tente fazer o depósito novamente.",
  walletVegaInfoContentEnding:
    " Para informações mais detalhadas, consulte o link abaixo.",
  howToUseMustRead: "Como usar (leitura obrigatória)",
  walletVegaFormFieldsTitle:
    "Para prosseguir com o pagamento, insira seus detalhes de autorização:",
  userId: "ID do Usuário",
  userPassword: "Senha",
  "validation.walletVegaUserId": "Deve começar com U e conter 6 símbolos após",
  "validation.walletVegaUserPassword": "Deve conter de 2 a 32 símbolos",
  enterYourIdentifier: "Digite seu usuário Id",
  userIdCopy: "ID do usuário copiado",
  nameCopy: "Nome copiado",
  name: "Nome",
  howToCompletePayment: "Como concluir um pagamento?",
  openYourBankingAccount: "Abra sua conta bancária",
  chooseInteracAmount: "Escolha Interac e-transfer e preencha o valor",
  fillRecipEmailName: "Preencha o email e o nome do destinatário",
  completeSecret: "Complete a pergunta secreta e a resposta secreta",
  confirmPayment: "Confirme o pagamento",
  confirmInBankApp: "Agora confirme seu pagamento no aplicativo do seu banco",
  interacInfoTitle:
    "Atenção! Por favor, insira o valor exato e o email do destinatário. Caso contrário, os fundos não serão creditados na sua conta.",
  enterAmount: "Digite o valor",
  userNameTransactionAmount:
    "Seu nome de usuário e o valor da transação serão exibidos na sua tela para verificar seus detalhes",
  selectPaymentMethod:
    "Selecione um método de pagamento e clique em 'Pagar agora'",
  amountComplete15Mins:
    "Os fundos serão creditados em 15 minutos após a conclusão da transação.",
  iHaveMadePayment: "Eu fiz um pagamento",
  depositInfoMainTitle:
    "Por favor, encontre as etapas para efetuar o pagamento através deste método de depósito:",
  paymentNotificationUssd:
    "Prossiga com o seu pagamento, será recebida uma notificação sobre o sucesso do seu depósito",

  whereFindBlikCode: "Onde posso encontrar o código BLIK?",
  blikCodeInstructions: "Abra o aplicativo do banco no celular e faça login.",
  blikCodeOption:
    "Encontre a opção BLIK na seção de Pagamentos ou Transferências.",
  blikCodeGenerate:
    "Gere o código BLIK de 6 dígitos, válido por alguns minutos.",
  blikCodeEnter: "Insira e envie o código BLIK.",
  blikCodeLabel: "Código BLIK",
  blikCodeInvalid: "São necessários 6 dígitos",
  onYourMobileDevice: "no seu dispositivo móvel",
  transactionIdCopy: "ID da transação copiado",
  amountCopy: "Valor copiado",
  transactionFailed: "Falha na transação",
  tryAgain: "Tente novamente",
  downloadReceipt: "Baixar recibo",
  success: "Sucesso",
  backToWebsite: "Voltar ao site",
  scanQrTitlePix:
    "Abra seu APP de pagamento e ESCANEIE o código QR abaixo para pagar, ou COPIE o código Pix abaixo e cole em seu aplicativo de pagamento para finalizar a compra.",
  boletoGenerated: "Boleto gerado!",
  youWantDltCard: "Tem certeza que deseja excluir o cartão?",
  dltCardSystemTitle:
    "Os dados do cartão serão excluídos da lista de Cartões Salvos, bem como do nosso sistema.",
  cancel: "Cancelar",
  delete: "Excluir",
  manageSavedCard: "Gerenciar cartões salvos",
  amountToPay: "Quantia a pagar",
  savedCard: "Cartões salvos",
  done: "Feito",
  manage: "Gerenciar",
  chooseLanguage: "Escolha o seu idioma",
  savePdf: "Salvar PDF",
  transactionIdTitle: "Transação ID",
  nameOnCard: "Nome no cartão",
  email: "E-mail",
  firstName: "Nome",
  lastName: "Sobrenome",
  middleName: "Nome do meio",
  checkPattern: "Reveja os dados inseridos",
  "validation.birthday": "Deve estar no formato ISO: YYYY-MM-DD",
  "validation.phone":
    "Insira o número de telefone no formato internacional: + e 10-15 dígitos.",
  "validation.phone_mobile_money": "Número de telefone inválido",
  "validation.pin": "Deve ter entre 4 e 12 símbolos",
  phone: "Telefone",
  country: "País",
  state_code: "Código do estado",
  city: "Cidade",
  address: "Endereço",
  zip_code: "Código postal",
  card_pin: "Pin do cartão",
  processing_your: "Processando seu",
  payment: "pagamento",
  incorrect_value: "O valor está incorreto",
  payer_name: "Nome do pagador",
  paymentInstructions: "Instruções de pagamento",
  paymentSteps: "Por favor, encontre as etapas para efetuar o pagamento:",
  copyAlias: "Copiar CBU/Alias",
  openBankApp: "Abra seu aplicativo do Banco",
  selectMoneyTransfer: "Selecione 'Transferência de dinheiro'",
  selectNewClient: "Selecione 'Novo cliente'",
  pasteCopiedCbuCode: "Colar o código CBU/Alias ​​copiado",
  CBUCVUCopy: "Cópia CBU/CVU",
  aliasCopy: "Cópia do alias",
  alias: "Alias",
  submit: "Enviar",
  time_remaining: "Não recebi nenhum OTP: ",
  mobileMoneyMainTitle: "Insira seu Mobile Money para autorizar este pagamento",
  mobileMoneySubTitle:
    "Uma solicitação da operadora foi enviada para o seu telefone. Digite seu PIN para autorizar este pagamento.",
  mobileMoneyInfoTitle:
    "Você receberá uma confirmação de que recebemos seu pagamento",
  resend_otp: "Reenviar",
  upi_qr_title_mob:
    'Digitalize o código QR em seu dispositivo ou toque em "ABRIR APP" para concluir rapidamente seu pagamento usando UPI.',
  upi_qr_title_desk:
    "Leia o código QR em seu dispositivo para concluir rapidamente seu pagamento usando UPI.",
  choose_provider: "Escolha o provedor",
  choose_mobile_operator: "Escolha o operador móvel",
  choose_currency: "Escolha a moeda",
  choose_receiver_bank: "Escolha o banco receptor",
  receiverName: "Nome do receptor",
  bankAccountInfoTitle:
    "Por favor, faça um pagamento para a seguinte conta bancária",

  // crypto title
  scanQRToTopUpWallet: "Escanear QR para carregar a carteira",
  tryAnotherMethod: "Por favor, tente outro método de pagamento.",
  verification_code: "Código de verificação",
  number: "Número",
  scanQrTitleOpay:
    "Abra o aplicativo de pagamento e escaneie o código QR para pagar",
  birthday: "Aniversário",
  account_number: "Número da conta",
  bank_code: "Código do banco",
  account_dob_day: "Data de criação da conta",
  account_dob_month: "Mês de criação da conta",
  account_dob_year: "Ano de criação da conta",
  pin: "Pin",
  search: "Procurar",
  dropdownEmpty: "Nada foi encontrado. Tente outra consulta",
  chooseMethod: "Escolha a forma de pagamento para iniciar o pagamento",
  leftHpp: "Tem certeza de que deseja cancelar este pagamento?",
  noConnectionTryAgain:
    "Sua conexão com a internet está instável. Por favor, tente novamente",
  selectedMethodError:
    "Ocorreu um erro durante a seleção do método. Por favor, tente novamente",
  submitError:
    "Ocorreu um erro ao enviar o formulário. Por favor, tente novamente",
  removeCardError:
    "Ocorreu um erro ao remover o cartão. Por favor, tente novamente",
  "Wallet address": "Endereço da carteira",
  TAG: "TAG",
  pleaseSend: "Por favor, envie",
  byCopyCryptoData:
    "copiando o endereço da carteira ou digitalizando o código QR:",
  correctNetworkInfoTitle:
    "Certifique-se de que está a usar a rede e a moeda corretas para esta carteira. Qualquer valor será convertido automaticamente em moeda do jogo.",
  mistakeLostCryptoFound: "Erros podem resultar em perda de fundos.",
  deletedCard: "O cartão foi excluído",
  redirectNotificationText:
    "O redirecionamento automático está bloqueado. Clique no botão abaixo para continuar.",
  redirectNotificationButton: "Prosseguir para o Pagamento",
  cancelPayment: "Retornar ao comerciante",
  cancelPaymentText:
    "Algo deu errado, a transação está levando mais tempo do que o normal para ser processada. Por favor, aguarde ou retorne ao comerciante.",
  infoAboutPaymentMethodDefault:
    "Clique no botão «Gerar Detalhes de Pagamento» abaixo para continuar. Você será redirecionado para uma página com os detalhes do pagamento e instruções claras para concluir seu pagamento usando o método de sua preferência.",
  buttonPayTextDefault: "Gerar Detalhes de Pagamento",
  date_of_birth: "Data de nascimento",
  accountRequisitesTitleMakePayment:
    "Encontre os passos para efetuar o pagamento:",
  accountRequisitesCopyAccountDetails: "Copiar os dados da conta",
  accountRequisitesCopyAccountDetailsCopied: "Dados da conta copiados",
  accountRequisitesMakeTransfer:
    "Efetue a transferência usando os dados copiados com o valor exato de",
  accountRequisitesPressButton: "Pressione o botão",
  paymentConfirmationSubtitleMadePayment: "«Já fiz o pagamento»",
  accountRequisitesMadeTransfer:
    "A transferência pode ser feita apenas uma vez.",
  requisitesErrorTitle: "Ocorreu um erro ao processar sua solicitação",
  requisitesErrorDescription:
    "Não foi possível obter as informações de pagamento. Por favor, volte e selecione outro método de pagamento. Pedimos desculpas pelo inconveniente.",
  youPay: "Você Paga",
  youGet: "Você Recebe",
  enterInformation:
    "Para prosseguir com a transação, por favor insira as informações abaixo.",
  iAgree: "Eu concordo com",
  termsOfService: "Termos de Serviço",
  and: "e",
  privacyPolicy: "Política de Privacidade",
  of: "de",
  proceed: "Continuar",
  paymentSuccessfully: "Pagamento processado com sucesso",
  paymentInProgress: "Pagamento em andamento",
  paymentInProgressDescriptionStart:
    "Se você já realizou o pagamento, não se preocupe! Seus fundos serão creditados em seu saldo em aproximadamente",
  minutes: "minutos",
  paymentInProgressDescriptionEnd: ", dependendo da carga da rede.",
  cryptoTopup: "Recarga",
  min: "mínima",
  paymentMinimumLimit:
    "Pagamentos abaixo do limite mínimo não serão creditados.",
  cryptoInfoTooltip: "O valor em cripto equivale a",
  rate: "cotação",
  networkFee: "taxa de rede",
  cryptoCurrencyNonActiveTitle:
    "Para prosseguir com seu pagamento, siga estas etapas:",
  cryptoCurrencyNonActiveFirstPoint:
    'Retorne à página de opções de pagamento clicando no botão "Voltar aos métodos".',
  cryptoCurrencyNonActiveSecondPoint:
    "Selecione uma criptomoeda alternativa da lista de opções disponíveis.",
  cryptoCurrencyNonActiveThirdPoint:
    "Confirme sua nova seleção e tente novamente o processo de pagamento.",
  cryptoCurrencyNonActiveDisclaimer:
    "A criptomoeda selecionada está atualmente indisponível para transações. Por favor, escolha outra moeda das opções disponíveis para prosseguir.",
};
