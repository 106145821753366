export default {
  pan: "カード番号",
  exp: "有効期限",
  "asquad.exp": "有効期限",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "支払う",
  payPM: "補充する",
  idTransTtl: "取引ID",
  pendingTtl: "お支払い処理中",
  "mono.pendingTtl": "処理中の操作...",
  errorTtl: "エラーが発生しました",
  "mono.errorTtl": "申し訳ありませんが、取引が拒否されました",
  successPaymentTtl: "支払いが成功しました",
  "mono.successPaymentTtl": "取引が成功しました",
  cvvHint: "CVVコードはカードの裏面に記載されています",
  rememberMeHint:
    "今後の当サイトでの支払いのために、カード番号と有効期限のみを保存します",
  "mono.rememberMeHint":
    "当サイトでの次回以降のお支払いのために、カード番号と有効期限のみを保存します",
  sslHintTitle: "安全なデータ転送",
  sslHintDescription: "256ビット暗号化の安全なプロトコルを使用しています",
  required: "必須項目",
  requiredDatePb: "カードの有効期限を入力してください",
  requiredCvvPb: "カードのCVVコードを入力してください",
  invalidPanPb: "有効なカード番号を入力してください",
  invalidPan: "無効なカード番号",
  invalidDate: "無効な日付",
  invalidCvv: "無効なCVV",
  invalidCardName: "無効なカード名義",
  expPlaceholder: "MM / YY",
  successPayment: "支払いが成功しました",
  paymentId: "支払いID: ",
  redirectNotice: "次の間にリダイレクトされます: ",
  continue: "続行",
  failedPayment: "支払いに失敗しました",
  backToMethods: "方法に戻る",
  goBack: "ストアに戻る",
  retry: "再試行",
  noConnection: "インターネット接続が不安定です。再接続中...",
  reconnected: "インターネット接続が再接続されました",
  intentionExpired: "支払いの意図が期限切れです",
  badRequestTitle: "ご要望を処理できません 😢",
  accessDenied: "アクセスが拒否されました ⛔",
  pageNotFound: "ページが見つからないか存在しません 😢",
  responseTimeExpired: "応答時間が経過しました ⌛",
  goBackAndRetry:
    "マーチャントのウェブサイトに戻って支払いを再試行してください。",
  callMerchantOrGoBack:
    "マーチャントに連絡するか、マーチャントのウェブサイトに戻って支払いを再試行してください。",
  unexpectedError: "予期しないエラーにより、ご要望を完了できません 😢",
  tryAgainLater: "後でもう一度お試しください",
  noResponseFromBank: "お支払いを処理できません。",
  tryTheFollowing: "もう一度お支払いするか、別のカードをご利用ください。",
  unableToProcessPayment: "お支払い中にエラーが発生しました。",
  useDifferentCard:
    "エラーが繰り返される場合は、サポートサービスにご連絡ください。",
  bankInternalErrorTitle: "銀行側で取引処理中にエラーが発生しました",
  transactionRejectedByBank: "支払いプロバイダーが支払いを拒否しました。",
  contactBankForReason:
    "さらなる支援のために金融機関にご連絡いただくか、別の方法をご利用ください。",
  bankInvalidCvvDesc: "CVVコードが間違っています。",
  invalidCardCredentialsTitle: "無効なカード情報。",
  checkCvvAndTryAgain:
    "CVVコードを再度確認してください。カードの裏面に記載されています。",
  doubleCheckCardCredential: "情報を再確認するか、別のカードをご利用ください。",
  bankInvalidPanTitle: "無効なカード番号。",
  bankInvalidPanDescription: "カード番号が間違っています。",
  checkPanAndTryAgain: "正しいカード番号を入力したことを確認してください",
  cardExpired: "カードの有効期限が切れています",
  unfortunatelyStatusBankCard:
    "残念ながら、カードの状態によりお支払いできません。",
  useAnotherCardPayment: "別のカードでのお支払いをお勧めします。",
  authorizationFailed: "認証に失敗しました。",
  anAuthorizeError: "認証エラー。",
  pleasePaymentAgain: "もう一度お試しになるか、別の方法をご利用ください。",
  cardLostStolen:
    "入力されたカードは「盗難」または「紛失」として記録されています。",
  bankCardExpiredTitle: "カードの有効期限が正しくありません。",
  checkCorrectExpirationDate:
    "カードの有効期限が正しいか確認するか、有効なカードをご利用ください。",
  insufficientFunds: "残高不足です。",
  useRecommendation: "以下のヒントをご利用ください。",
  useAnotherCard: "別のカードを使用する。",
  rechargeYourCard: "取引に必要な金額をカードにチャージしてください。",
  comeBackAndChangeAmount:
    "選択段階に戻り、カードにある金額を指定してください。",
  onlinePaymentLimited:
    "オンライン支払いの制限が超過または使い果たされたため、取引を処理できませんでした。",
  threeDSecureFailedTitle: "認証に失敗しました。",
  tryAgainWithCurrentCard: "現在のカードで再試行する",
  cardAnother: "別のカードを使用する",
  cardSaved: "保存されたカード",
  rememberMe: "次回から自動入力する",
  rememberMePM: "将来の支払いのためにカードを保存する",
  bankLimitExceededTitle: "オンライン支払い限度額を超えました。",
  lastThreeDigits: "クレジットカード裏面の最後の3桁",
  sessionExpired: "セッションの有効期限が切れました。",
  sessionExpiredDesc:
    "マーチャントのウェブサイトに戻って支払いを再試行してください。",
  monthTitle: "月",
  yearTitle: "年",
  sumTitle: "合計",
  orderTittle: "注文",
  loadingTtl: "お待ちください",
  "pm1.loadingTtl": "お待ちください",
  "privat.loadingTtl": "お待ちください...",
  "mono.descriptionPaymentTtl": "注文の支払い",
  applePayTitle: "で支払う",
  last3Digits: "カード裏面の最後の3桁",
  publicAgreementStart: "「補充」ボタンをクリックすることで、サービス提供の",
  publicAgreementLink: "公開オファー",
  publicAgreementEnd: "の条件に同意したことになります",
  // NEW TEXT
  HaveAQuestionAbout:
    "お支払いについてご質問がありますか？ こちらをクリックして「使い方」ビデオをご覧ください。",
  ApprovalHappensDuring: "承認は支払い後1時間以内に行われます。",
  PaymentNotViaBankSlip:
    "これは支払いが銀行伝票を介していないことを意味します。",
  PayInLotteryUsing: "以下の詳細を使用して宝くじで支払います：",
  ConventionCode: "協定コード",
  CPFCNPJNumber: "CPF/CNPJ番号",
  TotalDue: "合計金額",
  amount: "金額",
  ProductDescription: "製品説明",
  SavePDF: "PDFを保存",
  Copy: "コピー",
  Share: "共有",
  // PDF
  beneficiary: "受益者",
  agencyBeneficiaryCode: "機関/受益者コード",
  species: "種類",
  quantity: "数量",
  payer_name: "支払者名",
  paymentInstructions: "支払い手順",
  paymentSteps: "支払い手順は以下のとおりです：",
  copyAlias: "CBU/別名をコピー",
  openBankApp: "銀行アプリを開く",
  selectMoneyTransfer: "「送金」を選択",
  selectNewClient: "「新規顧客」を選択",
  pasteCopiedCbuCode: "コピーしたCBU/別名コードを貼り付ける",
  CBUCVUCopy: "CBU/CVUをコピー",
  aliasCopy: "別名をコピー",
  alias: "別名",
  ourNumber: "当社の番号",
  documentNumber: "書類番号",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "期日",
  documentValue: "書類の金額",
  discountsAllowances: "割引/手当",
  otherDeductions: "その他の控除",
  interestPenalty: "利息/罰金",
  otherAdditions: "その他の追加",
  amountCharged: "請求額",
  send: "送信",
  "Amount copied": "金額がコピーされました",
  topUpAmount: "チャージ金額",
  receive: "受け取る",
  payer: "支払者",
  mechanicalAuthentication: "機械的な認証：",
  cutOnTheDottedLine: "点線に沿って切り取る",
  paymentLocation: "支払い場所：期日までにどの銀行でも支払い可能",
  documentDate: "書類の日付",
  onTheDocument: "書類上で",
  docType: "書類タイプ",
  acceptance: "受諾",
  processingDate: "処理日",
  portfolio: "ポートフォリオ",
  lowerCode: "下部コード",
  mechanicalAuthenticationCompensationSlip: "機械的な認証補償スリップ",
  endorserGuarantor: "裏書人/保証人",
  instructions:
    "指示（受益者の責任下のテキスト）小切手による支払いを受け付けません。週末に期日を迎えるボレート。取引ID：{transID} 購入に問題がある場合は、{email}にアクセスしてください",
  boletoDetailsTitle:
    "銀行、宝くじ、郵便局、スーパーマーケット、またはオンラインで支払うことができます。また、以下の「PDFを保存」ボタンをクリックしてファイルを保存することもできます。",
  // NEW TRANS await all trans

  account: "口座",
  virtualAccountTitle: "この振込を完了するために銀行アプリを使用してください",
  bvn: "銀行確認番号",
  bankName: "銀行名",
  cardHolder: "カード名義人",
  bankCode: "銀行コード",
  accountNumber: "口座番号",
  accountNumberCopied: "口座番号がコピーされました",
  bankNameCopied: "銀行名がコピーされました",
  phoneCopied: "電話番号がコピーされました",
  receiverNameCopied: "受取人名がコピーされました",
  cardHolderCopied: "カード名義人がコピーされました",
  bankCodeCopied: "銀行コードがコピーされました",
  accountNameCopied: "口座名がコピーされました",
  panCopied: "カード番号がコピーされました",
  accountName: "口座名",
  remainingTime: "残り時間",
  virtualAccountFooter: "お支払いを受け取り次第、確認が送信されます",
  preSubmitInfo: "お支払いの詳細を生成するには、以下のボタンを押してください",
  showDetails: "詳細を表示",
  hideDetails: "詳細を非表示",
  description: "説明",
  transactionIdCopy: "取引IDがコピーされました",
  amountCopy: "金額がコピーされました",
  transactionFailed: "取引に失敗しました",
  userIdCopy: "ユーザーIDをコピーしました",
  nameCopy: "名前がコピーされました",
  name: "名前",
  depositInfoMainTitle: "この入金方法でお支払いを行う手順：",
  launchApp: "アプリを起動",
  howToCompletePayment: "支払いを完了する方法は？",
  openYourBankingAccount: "銀行口座を開く",
  chooseInteracAmount: "Interac e-transferを選択し、金額を入力",
  fillRecipEmailName: "受取人のメールアドレスと名前を入力",
  completeSecret: "秘密の質問と秘密の答えを完了",
  confirmPayment: "支払いを確認",
  confirmInBankApp: "今すぐあなたの銀行のアプリでお支払いを確認してください",
  interacInfoTitle:
    "注意！正確な金額と受取人のメールアドレスを入力してください。そうしないと、資金が口座に入金されません。",
  select: "選択",
  pleaseSelectOption: "選択してください",
  walletVegaInfoContent:
    "セキュリティ要件（ホワイトリスト）のため、最初の入金が失敗する場合があります。その場合、登録されたメールアドレスにVega Walletからメールが届きます。メール内のリンクをクリックしてアカウントを確認し、再度入金をお試しください。",
  walletVegaInfoContentEnding:
    " 詳細については、以下のリンクをご参照ください。",
  howToUseMustRead: "使い方（必読）",
  walletVegaFormFieldsTitle:
    "お支払いを続行するには、認証情報を入力してください：",
  userId: "ユーザーID",
  userPassword: "パスワード",
  "validation.walletVegaUserId":
    "Uで始まり、その後に6つのシンボルを含める必要があります",
  "validation.walletVegaUserPassword":
    "2から32のシンボルを含める必要があります",
  enterYourIdentifier: "ユーザーIDを入力",
  enterAmount: "金額を入力",
  userNameTransactionAmount:
    "ユーザー名と取引金額が画面に表示され、詳細を確認できます",
  selectPaymentMethod:
    "お支払い方法を選択し、「今すぐ支払う」をクリックしてください",
  amountComplete15Mins: "取引完了後、資金は15分以内にチャージされます。",
  iHaveMadePayment: "支払いを完了しました",
  secretQuestion: "秘密の質問",
  secretAnswer: "秘密の答え",
  recipientEmail: "受取人のメールアドレス",
  recipientName: "受取人の名前",
  answerCopy: "答えがコピーされました",
  questionCopy: "秘密の質問がコピーされました",
  emailCopy: "メールアドレスがコピーされました",
  dial: "ダイヤル",
  paymentNotificationUssd:
    "お支払いを続行してください。入金が成功すると通知が届きます",
  whereFindBlikCode: "BLIKコードはどこで見つけられますか？",
  blikCodeInstructions: "銀行のモバイルアプリを開いてログインします。",
  blikCodeOption:
    "「支払い」または「振込」セクションでBLIKオプションを見つけます。",
  blikCodeGenerate: "6桁のBLIKコードを生成します。これは数分間有効です。",
  blikCodeEnter: "BLIKコードを入力して送信します。",
  blikCodeLabel: "BLIKコード",
  blikCodeInvalid: "6桁が必要です",
  onYourMobileDevice: "モバイルデバイスで",
  tryAgain: "再試行",
  downloadReceipt: "領収書をダウンロード",
  paymentSuccessfully: "お支払いが \n 正常に処理されました",
  scanQrTitlePix:
    "支払いアプリを開き、以下のQRコードをスキャンして支払うか、以下のPixコードをコピーして支払いアプリに貼り付けて購入を完了します。",
  boletoGenerated: "ボレートが生成されました！",
  youWantDltCard: "カードを削除してもよろしいですか？",
  dltCardSystemTitle:
    "カードデータは保存されたカードリストおよびシステムから削除されます。",
  cancel: "キャンセル",
  delete: "削除",
  manageSavedCard: "保存されたカードを管理",
  savedCard: "保存されたカード",
  transactionStatus: "取引ステータス",
  paymentsSlowMessage:
    "現在、お使いの銀行での支払いが遅れています。遅延支払いは通常30分以内に確認されます。",
  receiptPaymentConfirmation: "お支払いが確認され次第、領収書が送信されます",
  doNotRefreshPage: "このページを更新しないでください",
  madePayment: "支払いを完了しました",
  mins: "分",
  secs: "秒",
  confirmingTransfer: "お振込を確認しています。約かかります",
  done: "完了",
  manage: "管理",
  chooseLanguage: "言語を選択",
  savePdf: "PDFを保存",
  transactionIdTitle: "取引ID",
  nameOnCard: "カード名義人",
  email: "メールアドレス",
  firstName: "名",
  lastName: "姓",
  orPayWithCard: "またはカードで支払う",
  dateTooltip: "有効期限はカード番号の下にあります（例：08/25）",
  middleName: "ミドルネーム",
  checkPattern: "入力データを確認",
  "validation.birthday": "ISO形式でなければなりません: YYYY-MM-DD",
  "validation.phone": "電話番号を国際形式で入力してください：+と10〜15桁。",
  "validation.phone_mobile_money": "無効な電話番号",
  "validation.pin": "4〜12文字でなければなりません",
  phone: "電話番号",
  country: "国",
  state_code: "州コード",
  city: "市",
  address: "住所",
  zip_code: "郵便番号",
  card_pin: "カード暗証番号",
  processing_your: "処理中",
  payment: "お支払い",
  incorrect_value: "値が正しくありません",
  selectAmountToPay: "提案されたリストから「支払額」を選択してください。",
  initiatedAmountDeclinedTitle: "P2P送金について",
  initiatedAmountDeclined:
    "P2P送金は、ユーザー間で直接データをやりとりする送金方法です。その為ご入力頂いたご希望の金額ではお取引きができない場合もあります。その際は以下に表示されるオプションの中から、ご希望に近い金額をお選びください。",
  toCompleteThePaymentFollowSteps:
    "お支払いを完了するには、以下の手順に従ってください。",
  clickSubmitToConfirm:
    "選択した支払金額を確認するには「送信」をクリックしてください。",
  afterSelectingAmount:
    "金額を選択した後、支払いを完了するために銀行のページにリダイレクトされます。",
  submit: "送信",
  time_remaining: "OTPを受け取っていませんか： ",
  mobileMoneyMainTitle:
    "この支払いを認証するためにモバイルマネーを入力してください",
  mobileMoneySubTitle:
    "オペレーターから電話にリクエストが送信されました。支払いを認証するためにPINを入力してください。",
  mobileMoneyInfoTitle: "お支払いを受け取り次第、確認が送信されます",
  resend_otp: "再送信",
  amountToPay: "支払う金額",
  upi_qr_title_mob:
    "デバイスでQRコードをスキャンするか、「アプリを開く」をタップしてUPIでお支払いをすばやく完了します。",
  upi_qr_title_desk:
    "デバイスでQRコードをスキャンし、UPIでお支払いをすばやく完了します。",
  choose_provider: "プロバイダーを選択",
  choose_mobile_operator: "モバイルオペレーターを選択",
  choose_currency: "通貨を選択",
  choose_receiver_bank: "受取銀行を選択",
  receiverName: "受取人名",
  bankAccountInfoTitle: "以下の銀行口座にお支払いください",

  // 仮想通貨関連のタイトル
  scanQRToTopUpWallet: "ウォレットをチャージするためにQRコードをスキャン",
  tryAnotherMethod: "別のお支払い方法をお試しください。",
  verification_code: "認証コード",
  number: "番号",
  scanQrTitleOpay: "支払いアプリを開き、以下のQRコードをスキャン",
  birthday: "誕生日",
  account_number: "口座番号",
  bank_code: "銀行コード",
  code: "コード",
  account_dob_day: "口座作成日",
  account_dob_month: "口座作成月",
  account_dob_year: "口座作成年",
  pin: "暗証番号",
  search: "検索",
  dropdownEmpty: "何も見つかりませんでした。別のクエリを試してください",
  chooseMethod: "支払いを開始するにはお支払い方法を選択してください",
  leftHpp: "この支払いをキャンセルしてもよろしいですか？",
  noConnectionTryAgain:
    "インターネット接続が不安定です。もう一度お試しください",
  selectedMethodError:
    "方法選択中にエラーが発生しました。もう一度お試しください",
  submitError: "フォーム送信中にエラーが発生しました。もう一度お試しください",
  removeCardError:
    "カードを削除中にエラーが発生しました。もう一度お試しください",
  pleaseSend: "送信してください",
  byCopyCryptoData:
    "ウォレットのアドレスをコピーするか、QR コードをスキャンすることで:",
  correctNetworkInfoTitle:
    "このウォレットに対して正しいネットワークと通貨を使用していることを確認してください。任意の金額はゲーム内通貨に自動的に変換されます。",
  "Wallet address": "ウォレットアドレス",
  TAG: "タグ",
  mistakeLostCryptoFound: "間違いは資金の損失を招く可能性があります。",
  deletedCard: "カードが削除されました",
  redirectNotificationText:
    "自動リダイレクトがブロックされています。続行するには、下のボタンをクリックしてください。",
  redirectNotificationButton: "支払いに進む",
  cancelPayment: "販売者に戻る",
  cancelPaymentText:
    "問題が発生しました。取引の処理に通常より時間がかかっています。お待ちいただくか、販売者に戻ってください。",
  infoAboutPaymentMethodDefault:
    "続行するには、以下の「支払い詳細を生成する」ボタンをクリックしてください。ご希望の方法でお支払いを完了するための支払い詳細と明確な手順が表示されるページにリダイレクトされます。",
  buttonPayTextDefault: "支払い詳細を生成する",
  date_of_birth: "生年月日",
  accountRequisitesTitleMakePayment: "お支払い手順をご確認ください：",
  accountRequisitesCopyAccountDetails: "口座情報をコピーする",
  accountRequisitesCopyAccountDetailsCopied: "口座情報がコピーされました",
  accountRequisitesMakeTransfer:
    "コピーされた口座情報を使用して正確な金額を送金してください",
  accountRequisitesPressButton: "ボタンをクリックしてください",
  paymentConfirmationSubtitleMadePayment: "«支払いが完了しました。»",
  accountRequisitesMadeTransfer: "送金は一度のみ可能です。",
  requisitesErrorTitle: "リクエストの処理中にエラーが発生しました",
  requisitesErrorDescription:
    "支払い情報の取得に失敗しました。戻って別の支払い方法を選択してください。ご不便をおかけして申し訳ありません。",
  youPay: "あなたが支払う",
  youGet: "あなたが受け取る",
  enterInformation: "取引を続けるために、以下の情報を入力してください。",
  iAgree: "私は同意します",
  termsOfService: "利用規約",
  and: "および",
  privacyPolicy: "プライバシーポリシー",
  of: "の",
  proceed: "続行",
  paymentInProgress: "支払い処理中",
  paymentInProgressDescriptionStart:
    "お支払いが完了している場合、ご安心ください！お客様の資金はおよそ",
  minutes: "分",
  paymentInProgressDescriptionEnd:
    "で残高に反映されます。ネットワークの負荷により時間が前後する場合があります。",
  cryptoTopup: "チャージ額",
  min: "最小",
  paymentMinimumLimit: "最低限度額未満の支払いは反映されません。",
  cryptoInfoTooltip: "暗号通貨の金額は次の通りです",
  rate: "レート",
  networkFee: "ネットワーク手数料",
  cryptoCurrencyNonActiveTitle:
    "お支払いを続行するには、以下の手順に従ってください:",
  cryptoCurrencyNonActiveFirstPoint:
    "「支払い方法に戻る」ボタンをクリックして、支払いオプションページに戻ります。",
  cryptoCurrencyNonActiveSecondPoint:
    "利用可能なオプションのリストから別の暗号通貨を選択します。",
  cryptoCurrencyNonActiveThirdPoint:
    "新しい選択を確認し、支払い手続きを再試行します。",
  cryptoCurrencyNonActiveDisclaimer:
    "選択した暗号通貨は現在取引に利用できません。続行するには、利用可能なオプションから別の通貨を選択してください。",
  backToWebsite: "ウェブサイトに戻る",
};
