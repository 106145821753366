const UssdIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2164_1242)">
      <mask
        id="mask0_2164_1242"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2164_1242)">
        <path
          d="M6 23C5.45 23 4.97917 22.8042 4.5875 22.4125C4.19583 22.0208 4 21.55 4 21V3C4 2.45 4.19583 1.97917 4.5875 1.5875C4.97917 1.19583 5.45 1 6 1H16C16.55 1 17.0208 1.19583 17.4125 1.5875C17.8042 1.97917 18 2.45 18 3V7H16V6H6V18H16V17H18V21C18 21.55 17.8042 22.0208 17.4125 22.4125C17.0208 22.8042 16.55 23 16 23H6ZM14.95 13.2L19.9 8.25C20.0833 8.06667 20.3167 7.975 20.6 7.975C20.8833 7.975 21.1167 8.06667 21.3 8.25C21.4833 8.43333 21.575 8.66667 21.575 8.95C21.575 9.23333 21.4833 9.46667 21.3 9.65L15.65 15.3C15.45 15.5 15.2167 15.6 14.95 15.6C14.6833 15.6 14.45 15.5 14.25 15.3L11.4 12.45C11.2167 12.2667 11.125 12.0333 11.125 11.75C11.125 11.4667 11.2167 11.2333 11.4 11.05C11.5833 10.8667 11.8167 10.775 12.1 10.775C12.3833 10.775 12.6167 10.8667 12.8 11.05L14.95 13.2Z"
          fill="#0062EB"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2164_1242">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default UssdIcon;
