import api from "./api";
import testData from "./testData";

let state = {};

const setState = (newState) => {
  state = { ...state, ...newState };

  return state;
};

const getState = () => {
  return state;
};

const initCrypto = async ({ sessionId, signature }) => {
  if (testData.isTestMode(sessionId, signature)) {
    testData.subscribePostMessage();
  }

  return new Promise(async (resolve) => {
    const pollInitCrypto = async () => {
      try {
        const data = await api.getInitDataCrypto({
          sessionId,
          signature,
          state,
        });
        setState({ ...data });

        resolve(data);
      } catch (error) {
        console.error("Error polling initCrypto:", error);
      }
    };

    pollInitCrypto();

    const pollingCrypto = setInterval(pollInitCrypto, 20000);

    return () => clearInterval(pollingCrypto);
  });
};

export default {
  get: getState,
  set: setState,
  initCrypto,
};
