const BankTransferIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 11V16C5 16.2833 5.09583 16.5208 5.2875 16.7125C5.47917 16.9042 5.71667 17 6 17C6.28333 17 6.52083 16.9042 6.7125 16.7125C6.90417 16.5208 7 16.2833 7 16V11C7 10.7167 6.90417 10.4792 6.7125 10.2875C6.52083 10.0958 6.28333 10 6 10C5.71667 10 5.47917 10.0958 5.2875 10.2875C5.09583 10.4792 5 10.7167 5 11ZM11 11V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V11C13 10.7167 12.9042 10.4792 12.7125 10.2875C12.5208 10.0958 12.2833 10 12 10C11.7167 10 11.4792 10.0958 11.2875 10.2875C11.0958 10.4792 11 10.7167 11 11ZM2.2875 20.7125C2.47917 20.9042 2.71667 21 3 21H13.544C13.2607 20.3826 13.079 19.7087 13.0205 19H3C2.71667 19 2.47917 19.0958 2.2875 19.2875C2.09583 19.4792 2 19.7167 2 20C2 20.2833 2.09583 20.5208 2.2875 20.7125ZM19 12.5C18.2987 12.5 17.6256 12.6203 17 12.8414V11C17 10.7167 17.0958 10.4792 17.2875 10.2875C17.4792 10.0958 17.7167 10 18 10C18.2833 10 18.5208 10.0958 18.7125 10.2875C18.9042 10.4792 19 10.7167 19 11V12.5ZM2.9 8H21C21.2833 8 21.5208 7.90417 21.7125 7.7125C21.9042 7.52083 22 7.28333 22 7V6.625C22 6.44167 21.9542 6.26667 21.8625 6.1C21.7708 5.93333 21.6333 5.80833 21.45 5.725L12.9 1.45C12.6167 1.31667 12.3167 1.25 12 1.25C11.6833 1.25 11.3833 1.31667 11.1 1.45L2.5 5.75C2.35 5.83333 2.22917 5.94167 2.1375 6.075C2.04583 6.20833 2 6.36667 2 6.55V7.1C2 7.35 2.0875 7.5625 2.2625 7.7375C2.4375 7.9125 2.65 8 2.9 8Z"
      fill="#0062EB"
    />
    <path
      d="M17.74 19.7H19C19.1133 19.7 19.2083 19.6617 19.285 19.585C19.3617 19.5083 19.4 19.4133 19.4 19.3C19.4 19.1867 19.3617 19.0917 19.285 19.015C19.2083 18.9383 19.1133 18.9 19 18.9H17.74L18.09 18.54C18.1633 18.4667 18.2 18.375 18.2 18.265C18.2 18.155 18.16 18.06 18.08 17.98C18.0067 17.9067 17.9133 17.87 17.8 17.87C17.6867 17.87 17.5933 17.9067 17.52 17.98L16.48 19.02C16.4 19.1 16.36 19.1933 16.36 19.3C16.36 19.4067 16.4 19.5 16.48 19.58L17.52 20.62C17.5933 20.6933 17.685 20.7317 17.795 20.735C17.905 20.7383 18 20.7 18.08 20.62C18.1533 20.5467 18.1917 20.455 18.195 20.345C18.1983 20.235 18.1633 20.14 18.09 20.06L17.74 19.7ZM20.26 18.1L19.91 18.46C19.8367 18.5333 19.8 18.625 19.8 18.735C19.8 18.845 19.84 18.94 19.92 19.02C19.9933 19.0933 20.0867 19.13 20.2 19.13C20.3133 19.13 20.4067 19.0933 20.48 19.02L21.52 17.98C21.6 17.9 21.64 17.8067 21.64 17.7C21.64 17.5933 21.6 17.5 21.52 17.42L20.48 16.38C20.4067 16.3067 20.315 16.2683 20.205 16.265C20.095 16.2617 20 16.3 19.92 16.38C19.8467 16.4533 19.8083 16.545 19.805 16.655C19.8017 16.765 19.8367 16.86 19.91 16.94L20.26 17.3H19C18.8867 17.3 18.7917 17.3383 18.715 17.415C18.6383 17.4917 18.6 17.5867 18.6 17.7C18.6 17.8133 18.6383 17.9083 18.715 17.985C18.7917 18.0617 18.8867 18.1 19 18.1H20.26ZM19 22.5C18.4467 22.5 17.9267 22.395 17.44 22.185C16.9533 21.975 16.53 21.69 16.17 21.33C15.81 20.97 15.525 20.5467 15.315 20.06C15.105 19.5733 15 19.0533 15 18.5C15 17.9467 15.105 17.4267 15.315 16.94C15.525 16.4533 15.81 16.03 16.17 15.67C16.53 15.31 16.9533 15.025 17.44 14.815C17.9267 14.605 18.4467 14.5 19 14.5C19.5533 14.5 20.0733 14.605 20.56 14.815C21.0467 15.025 21.47 15.31 21.83 15.67C22.19 16.03 22.475 16.4533 22.685 16.94C22.895 17.4267 23 17.9467 23 18.5C23 19.0533 22.895 19.5733 22.685 20.06C22.475 20.5467 22.19 20.97 21.83 21.33C21.47 21.69 21.0467 21.975 20.56 22.185C20.0733 22.395 19.5533 22.5 19 22.5Z"
      fill="#0062EB"
    />
  </svg>
);
export default BankTransferIcon;
