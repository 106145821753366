import defaultColorSchema from "./defaultColorScheme";
import { COLORS } from "../constants/themeConstants";
import errorIconKomb from "../assets/icons/errorIconKomb.svg";
import themeElementStylesScheme from "./themeElementStylesScheme";

const { kombineElementStylesSchema } = themeElementStylesScheme;

const blue = {
  ...defaultColorSchema,
  formHeaderBackground: COLORS.BLUE,
  successHeaderPageBackground: COLORS.BlUE_LIGHT,
  headerGradient: `radial-gradient(at top, ${COLORS.BLUE},  ${COLORS.BLUE} 92%, transparent 50%)`,
  mainColor: COLORS.BLUE,
  buttonColor: COLORS.BLUE,
  notificationColor: COLORS.GREEN,
  notificationColorError: COLORS.DANGER,
  tittleColor: COLORS.DEFAULT_BLACK,
  showSecurityIcon: true,

  //   fonts
  fontUrls: [
    "https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap",
  ],
  mainFont:
    "'WalsheimLC', 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  // mainUrlFont: "/fonts/GTWalsheimLC.ttf",
  // mainNameFont: "WalsheimLC",
  regularFont:
    "'WalsheimLCRegular', 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  // regularNameFont: "WalsheimLCRegular",
  // regularUrlFont: "/fonts/GTWalsheimLCRegular.ttf",
  mediumFont:
    "'WalsheimLCMedium', 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  // mediumNameFont: "WalsheimLCMedium",
  // mediumUrlFont: "/fonts/GTWalsheimLCMedium.ttf",
  loaderName: "AsquadLoader",
};

const noname = {
  ...defaultColorSchema,
};

const green = {
  // bettranscat
  ...defaultColorSchema,
  formHeaderBackground: COLORS.DARK_GREEN,
  successHeaderPageBackground: COLORS.GREEN_LIGHT,
  headerGradient: `radial-gradient(at top, ${COLORS.DARK_GREEN}, ${COLORS.DARK_GREEN} 92%, transparent 50%)`,
  mainColor: COLORS.DARK_GREEN,
  buttonColor: COLORS.DARK_GREEN,
  notificationColor: COLORS.GREEN,
  tittleColor: COLORS.DEFAULT_BLACK,
  headerTitleColor: "#1C1D25",
  successPageBackground: COLORS.GREEN_LIGHT,

  //   fonts
  fontUrls: [
    "https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap",
  ],
  mainFont:
    "'WalsheimLC','Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  // mainUrlFont: "/fonts/GTWalsheimLC.ttf",
  // mainNameFont: "WalsheimLC",
  regularFont:
    "'WalsheimLCRegular','Rubik','Helvetica Neue', Helvetica, Arial, sans-serif",
  // regularNameFont: "WalsheimLCRegular",
  // regularUrlFont: "/fonts/GTWalsheimLCRegular.ttf",
  mediumFont:
    "'WalsheimLCMedium','Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  // mediumNameFont: "WalsheimLCMedium",
  // mediumUrlFont: "/fonts/GTWalsheimLCMedium.ttf",
  loaderName: "BallLoader",
};

const pink = {
  // flexi
  ...defaultColorSchema,
  formHeaderBackground: COLORS.PINK,
  successHeaderPageBackground: COLORS.PINK,
  headerGradient: `radial-gradient(at top, ${COLORS.PINK}, ${COLORS.PINK} 92%, transparent 50%)`,
  mainColor: COLORS.PINK,
  buttonColor: COLORS.PINK,
};

const purple = {
  // payriver
  ...defaultColorSchema,
};

const blueOrange = {
  ...defaultColorSchema,
  formHeaderBackground: "#031938",
  headerGradient: `radial-gradient(at top, #031938,  #031938 92%, transparent 50%)`,
  mainColor: "#F4511E",
  buttonColor: "#F4511E",
  notificationColor: COLORS.GREEN,
};

const darkBlue = {
  // kombine
  ...defaultColorSchema,
  ...kombineElementStylesSchema,
  formHeaderBackground: "#031938",
  bodyBackground: "rgb(240, 244, 245)",
  headerGradient: `radial-gradient(at top, #031938,  #031938 92%, transparent 50%)`,
  successTitleColor: COLORS.WHITE,
  mainColor: "#031938",
  errorTitleColor: "#FF3456",
  mainTextColor: "#FFFFFFE5",
  subMainInfoTextColor: "#FFFFFF99",
  infoBlockBackground: "#05224C",
  remainingTimeBackground: "#10366B",
  remainingTimeTitleColor: "#FFFFFF",
  remainingTimeTimesColor: "#FFFFFF",
  innerLineBackground: "#10366B",
  popupTextTitleColor: COLORS.WHITE,
  errorIcon: errorIconKomb,
  iconCopyColorIcon: "#FFFFFF",
  successPageBackground: "#031938",
  loaderName: "KombineLoader",
  notificationColor: COLORS.WHITE,
  notificationTextColor: COLORS.DEFAULT_BLACK,
  fontUrls: [
    "https://fonts.googleapis.com/css2?family=Inter:wght@300..900&display=swap",
  ],
  mainFont: "'Inter', 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  regularFont:
    "'Inter', 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  mediumFont:
    "'Inter', 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
};

const blackGreen = {
  // n1
  ...defaultColorSchema,
  formHeaderBackground: "rgb(26, 26, 33)",
  headerGradient: `radial-gradient(at top, rgb(26, 26, 33), rgb(26, 26, 33) 92%, transparent 50%)`,
  mainColor: "rgb(71, 155, 102)",
  buttonColor: "rgb(71, 155, 102)",
};

const grGreenKz = {
  // asquad prod
  ...defaultColorSchema,
  formHeaderBackground: "#204F4B",
  headerGradient: `radial-gradient(at top, #204F4B, #204F4B 92%, transparent 50%)`,
  mainColor: "#172E2B",
  buttonColor: "#172E2B",
};

export default {
  blue,
  default: noname,
  green,
  purple,
  pink,
  blueOrange,
  blackGreen,
  darkBlue,
  grGreenKz,
};
