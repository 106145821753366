import { RSocketConnector } from "rsocket-core";
import { WebsocketClientTransport } from "rsocket-websocket-client";
import { Buffer } from "buffer";
import api from "./api";
import logger from "./logger";
import state, { setLoggerData } from "./state";
import { PAYMENT_METHODS } from "../constants/paymentMethods";
import connection from "./connection";

const MAX_CONNECTION_COUNT = 10;
let connectionCount = 0;

if (typeof window !== "undefined") {
  window.Buffer = Buffer;
}

const setAltMethodsData = (name, currentState, parsedData) => {
  return state.set({
    [name]: {
      ...currentState[name],
      identity: {
        ...currentState[name].identity,
        ...(parsedData?.event?.identity_container || {}),
      },
      session: {
        ...currentState[name].session,
        ...(parsedData?.event?.session_container || {}),
      },
    },
  });
};

const setExpiredData = (parsedData) => {
  const data = { status: parsedData?.event?.status };

  state.set(data);
};

const setStatus = (parsedData) => {
  const data = { status: parsedData?.event?.status };

  if (parsedData?.event?.error) {
    data.error = parsedData?.event?.error;
  }

  if (state?.get()?.status === "error" && state?.get()?.error?.goBackMethod) {
    data.status = state?.get()?.status;
    data.error = state?.get()?.error;
  }

  state.set(data);
};

const setAnyData = (parsedData) => {
  const data = { ...parsedData?.event };

  delete data.session_signature;
  delete data.session_id;
  delete data.event_date;

  if (state?.get()?.status === "error" && state?.get()?.error?.goBackMethod) {
    data.status = state?.get()?.status;
    data.error = state?.get()?.error;
  }

  state.set(data);
};

const setDTO = (parsedData) => {
  const data = parsedData?.event?.payment;
  const currentState = state.get();

  if (
    (data.status === "failed_retry" &&
      currentState.status !== "failed_retry") ||
    (currentState.pressedBackToMethod && !data.selected_method_id)
  ) {
    data.selected_method_id = null;
    data.pressedBackToMethod = false;
  }

  if (state?.get()?.status === "error" && state?.get()?.error?.goBackMethod) {
    data.status = state?.get()?.status;
    data.error = state?.get()?.error;
  }

  state.set({ ...data, hasSelectedMethod: !!data.selected_method_id });

  setLoggerData(parsedData);
};

const setFailedRetry = (parsedData) => {
  const data = { status: "failed_retry", selected_method_id: null };

  if (parsedData?.event?.error) {
    data.error = parsedData?.event?.error;
  }

  state.set(data);
};

const setReceipt = (parsedData) => {
  const data = { receiptLink: parsedData?.event?.receiptLink };

  state.set(data);
};

const setOpenedFormData = (parsedData) => {
  const data = {
    available_methods: parsedData?.event?.available_methods || [],
    status: parsedData?.event?.status,
  };

  if (parsedData?.event?.error) {
    data.error = parsedData?.event?.error;
  }

  state.set(data);
};

const eventsMapper = (parsedData) => {
  console.log(parsedData);
  const currentState = state.get();
  const { error } = parsedData?.event || {};

  if (
    error &&
    Object.keys(error).length &&
    ![
      "failed_retry",
      "payment_intention_complete",
      "payment_intention_expired",
    ].includes(parsedData?.eventType)
  ) {
    return Promise.resolve(api.getHttpErrorMessage(error));
  }

  switch (parsedData?.eventType) {
    case "payment": {
      setDTO(parsedData);

      break;
    }

    case "failed_retry": {
      setFailedRetry(parsedData);

      break;
    }

    case "receipt_ready": {
      setReceipt(parsedData);

      break;
    }

    case "apple_pay_validation": {
      setAltMethodsData(PAYMENT_METHODS.APPLE_PAY, currentState, parsedData);

      break;
    }

    case "apple_pay_routing": {
      setAltMethodsData(PAYMENT_METHODS.APPLE_PAY, currentState, parsedData);

      break;
    }

    case "google_pay_routing": {
      setAltMethodsData(PAYMENT_METHODS.GOOGLE_PAY, currentState, parsedData);

      break;
    }

    case "payment_intention_expired": {
      setExpiredData(parsedData);

      break;
    }

    case "payment_intention_complete": {
      setStatus(parsedData);

      break;
    }

    case "challenge_success": {
      setStatus(parsedData);

      break;
    }

    case "payment_form_open": {
      setOpenedFormData(parsedData);

      break;
    }

    case "challenge_accepted": {
      setStatus(parsedData);

      break;
    }

    case "challenge_required": {
      state.set({
        challenge: {
          type: parsedData?.event?.type || currentState?.challenge?.type,
          url: parsedData?.event?.url || currentState?.challenge?.url,
          pareq: parsedData?.event?.pareq || currentState?.challenge?.pareq,
          md: parsedData?.event?.md || currentState?.challenge?.md,
          term_url:
            parsedData?.event?.term_url || currentState?.challenge?.term_url,
          creq: parsedData?.event?.creq || currentState?.challenge?.creq,
          fields: parsedData?.event?.fields || currentState?.challenge?.fields,
          iframe_url:
            parsedData?.event?.iframe_url ||
            currentState?.challenge?.iframe_url,
          http_method:
            parsedData?.event?.http_method ||
            currentState?.challenge?.http_method,
          select_amount:
            parsedData?.event?.select_amount ||
            currentState?.challenge?.select_amount,
          qr: {
            code:
              parsedData?.event?.qr?.code || currentState?.challenge?.qr?.code,
            image:
              parsedData?.event?.qr?.image ||
              currentState?.challenge?.qr?.image,
          },
          br: {
            code:
              parsedData?.event?.br?.code || currentState?.challenge?.br?.code,
            code_type:
              parsedData?.event?.br?.code_type ||
              currentState?.challenge?.br?.code_type,
            fields: {
              bank_no:
                parsedData?.event?.br?.fields?.bank_no ||
                currentState?.challenge?.br?.fields?.bank_no,
              cpf:
                parsedData?.event?.br?.fields?.cpf ||
                currentState?.challenge?.br?.fields?.cpf,
              bank_code:
                parsedData?.event?.br?.fields?.bank_code ||
                currentState?.challenge?.br?.fields?.bank_code,
              pdf_url:
                parsedData?.event?.br?.fields?.pdf_url ||
                currentState?.challenge?.br?.fields?.pdf_url,
              image:
                parsedData?.event?.br?.fields?.image ||
                currentState?.challenge?.br?.fields?.image,
            },
          },
          bank_transfer_argentina: {
            account_number:
              parsedData?.event?.bank_transfer_argentina?.account_number ||
              currentState?.challenge?.bank_transfer_argentina?.account_number,
            account_number_alias:
              parsedData?.event?.bank_transfer_argentina
                ?.account_number_alias ||
              currentState?.challenge?.bank_transfer_argentina
                ?.account_number_alias,
          },
          bank_transfer_africa: {
            account_name:
              parsedData?.event?.bank_transfer_africa?.account_name ||
              currentState?.challenge?.bank_transfer_africa?.account_name,
            account_number:
              parsedData?.event?.bank_transfer_africa?.account_number ||
              currentState?.challenge?.bank_transfer_africa?.account_number,
            bank_name:
              parsedData?.event?.bank_transfer_africa?.bank_name ||
              currentState?.challenge?.bank_transfer_africa?.bank_name,
            bank_code:
              parsedData?.event?.bank_transfer_africa?.bank_code ||
              currentState?.challenge?.bank_transfer_africa?.bank_code,
            expiration_date:
              parsedData?.event?.bank_transfer_africa?.expiration_date ||
              currentState?.challenge?.bank_transfer_africa?.expiration_date,
          },
          pay_code: {
            code:
              parsedData?.event?.pay_code?.code ||
              currentState.challenge?.pay_code?.code,
            expiration_date:
              parsedData?.event?.pay_code?.expiration_date ||
              currentState.challenge?.pay_code?.expiration_date,
          },
          p2c: {
            card_number:
              parsedData?.event?.p2c?.card_number ||
              currentState.challenge?.p2c?.card_number,
            bank_name:
              parsedData?.event?.p2c?.bank_name ||
              currentState.challenge?.p2c?.bank_name,
            card_holder:
              parsedData?.event?.p2c?.card_holder ||
              currentState.challenge?.p2c?.card_holder,
          },
          account_requisites: {
            account:
              parsedData?.event?.account_requisites?.account ||
              currentState.challenge?.account_requisites?.account,
            instruction:
              parsedData?.event?.account_requisites?.instruction ||
              currentState.challenge?.account_requisites?.instruction,
          },
          bank_account: {
            account_number:
              parsedData?.event?.bank_account?.account_number ||
              currentState?.challenge?.bank_account?.account_number,
            bank_code:
              parsedData?.event?.bank_account?.bank_code ||
              currentState?.challenge?.bank_account?.bank_code,
            bank_verification_number:
              parsedData?.event?.bank_account?.bank_verification_number ||
              currentState?.challenge?.bank_account?.bank_verification_number,
            account_dob_day:
              parsedData?.event?.bank_account?.account_dob_day ||
              currentState?.challenge?.bank_account?.account_dob_day,
            account_dob_month:
              parsedData?.event?.bank_account?.account_dob_month ||
              currentState?.challenge?.bank_account?.account_dob_month,
            account_dob_year:
              parsedData?.event?.bank_account?.account_dob_year ||
              currentState?.challenge?.bank_account?.account_dob_year,
          },
          bank_code: {
            bank_code:
              parsedData?.event?.bank_code?.bank_code ||
              currentState.challenge?.bank_code?.bank_code,
          },
          ussd: {
            code:
              parsedData?.event?.ussd?.code ||
              currentState?.challenge?.ussd?.code,
          },
          interac_etransfer: {
            name:
              parsedData?.event?.interac_etransfer?.name ||
              currentState.challenge?.interac_etransfer?.name,
            email:
              parsedData?.event?.interac_etransfer?.email ||
              currentState.challenge?.interac_etransfer?.email,
            secret_answer:
              parsedData?.event?.interac_etransfer?.secret_answer ||
              currentState.challenge?.interac_etransfer?.secret_answer,
            secret_question:
              parsedData?.event?.interac_etransfer?.secret_question ||
              currentState.challenge?.interac_etransfer?.secret_question,
          },
          p2c_phone: {
            phone:
              parsedData?.event?.p2c_phone?.phone ||
              currentState.challenge?.p2c_phone?.phone,
            receiver_customer_name:
              parsedData?.event?.p2c_phone?.receiver_customer_name ||
              currentState.challenge?.p2c_phone?.receiver_customer_name,
            bank_name:
              parsedData?.event?.p2c_phone?.bank_name ||
              currentState.challenge?.p2c_phone?.bank_name,
          },
        },
        status: parsedData?.event?.status,
      });

      break;
    }

    default: {
      setAnyData(parsedData);

      break;
    }
  }
};

const retry = ({ type, sessionId, signature, init }) => {
  const data = state.get();
  const isNotReadyToRetry = data.status === "expired" && data.error;

  if (!isNotReadyToRetry) {
    if (connectionCount < MAX_CONNECTION_COUNT) {
      // eslint-disable-next-line no-use-before-define
      run({ type, sessionId, signature, init });
      connectionCount++;
    } else if (connection.getConnectionStatus()) {
      init({
        type,
        sessionId,
        signature,
        withPolling: true,
      });
    } else if (!connection.getConnectionStatus()) {
      connectionCount = 0;
    }
  }
};

export const createClient = ({ type, sessionId, signature, init }) => {
  const baseApiUrl =
    typeof window !== "undefined"
      ? `${window.location.host}/rsocket`
      : "ws.asquad.dev/rsocket";

  const responderCookie = {
    fireAndForget(payload) {
      try {
        const cookies = JSON.parse(
          payload.data
            .toString()
            ?.replace(/;\s*HttpOnly\b/gi, "")
            ?.replace(/;\s*Secure\b/gi, ""),
        );

        document.cookie = cookies["Set-Cookie"];
      } catch (error) {
        logger.error("Socket cookies parsing error", {
          error,
        });
      }
    },
  };

  const data = {
    data: `/${sessionId}/${signature}`,
    "User-Agent": navigator.userAgent,
  };

  const cookie = document.cookie;

  if (cookie) {
    data.cookie = cookie;
  }

  const client = new RSocketConnector({
    setup: {
      keepAlive: 100000000,
      lifetime: 100000,
      dataMimeType: "application/json",
      metadataMimeType: "application/json",
      payload: {
        metadata: Buffer.from(JSON.stringify(data)),
      },
    },
    transport: new WebsocketClientTransport({
      url: `${
        window?.location?.protocol === "http:" ? "ws" : "wss"
      }://${baseApiUrl}`,
      wsCreator: (url) => new WebSocket(url),
    }),
    responder: responderCookie,
  });

  try {
    const connect = client
      .connect()
      .then((socket) => {
        console.log(socket, "--socket");
        const requestor = socket.requestStream(
          {
            metadataMimeType: "application/json",
            metadata: Buffer.from(
              JSON.stringify({
                route: `/${sessionId}/${signature}`,
              }),
            ),
          },
          1,
          {
            onNext(payload, isComplete) {
              try {
                const json = payload?.data?.toString();
                let parsedData;

                try {
                  parsedData = JSON.parse(json);
                } catch (error) {
                  retry({ type, sessionId, signature, init });

                  logger.error("Socket response parsing error", {
                    error,
                  });
                }

                eventsMapper(parsedData);

                requestor.request(1);

                return {
                  data: parsedData,
                  isComplete,
                };
              } catch (error) {
                retry({ type, sessionId, signature, init });

                logger.error("Socket response processing error", {
                  error,
                });
              }
            },
            onError(error) {
              retry({ type, sessionId, signature, init });

              logger.warn("Socket connection error", {
                error,
              });
            },
            request: (n) => {
              console.log(`request(${n})`);
            },
            cancel: (n) => {
              console.log(`cancel()`, n);
            },
          },
        );

        return requestor;
      })
      .catch(() => {
        retry({ type, sessionId, signature, init });
      });

    return connect;
  } catch (error) {
    retry({ type, sessionId, signature, init });

    logger.error("Socket create client error", {
      error,
    });
  }
};

export const run = ({ type, sessionId, signature, init }) => {
  try {
    const connection = createClient({
      type,
      sessionId,
      signature,
      init,
    });

    return connection;
  } catch (error) {
    retry({ type, sessionId, signature, init });

    logger.error("Socket run error", {
      error,
    });
  }
};

export default {
  run,
};
