export default {
  pan: "Номер карты",
  exp: "Срок действия",
  "asquad.exp": "Срок действия",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Оплатить",
  payPM: "Пополнить",
  idTransTtl: "ID Транзакции",
  pendingTtl: "Платёж обрабатывается",
  "mono.pendingTtl": "Операция в обработке...",
  errorTtl: "Произошла ошибка",
  "mono.errorTtl": "Извините, транзакция отклонена",
  successPaymentTtl: "Оплата прошла успешно",
  "mono.successPaymentTtl": "Транзакция успешна",
  cvvHint: "Код CVV указан на обратной стороне карты",
  rememberMeHint:
    "Мы сохраняем только номер карты и срок действия для будущих оплат на нашем сайте",
  "mono.rememberMeHint":
    "Мы сохраняем только номер карты и срок действия для дальнейших оплат на нашем сайте",
  sslHintTitle: "Безопасная передача данных",
  selectAmountToPay: "Выберите 'Сумма к оплате' из предложенного списка.",
  initiatedAmountDeclinedTitle: "О P2P транзакции",
  initiatedAmountDeclined:
    "P2P перевод — это метод отправки денег, при котором данные обмениваются напрямую между пользователями. Поэтому он может быть недоступен для обработки введенной вами суммы. В таких случаях, пожалуйста, выберите сумму, наиболее близкую к желаемой, из предложенных ниже вариантов.",
  toCompleteThePaymentFollowSteps:
    "Чтобы завершить платеж, пожалуйста, выполните следующие шаги.",
  clickSubmitToConfirm:
    "Нажмите 'Отправить', чтобы подтвердить выбранную сумму платежа.",
  afterSelectingAmount:
    "После выбора суммы вы будете перенаправлены на страницу банка для завершения платежа.",
  sslHintDescription:
    "Используется безопасный протокол с 256-битным шифрованием",
  required: "Обязательное поле",
  requiredDatePb: "Введите срок действия карты",
  requiredCvvPb: "Укажите CVV код карты",
  invalidPanPb: "Укажите корректный номер карты",
  invalidPan: "Некорректный номер карты",
  invalidDate: "Некорректная дата",
  invalidCvv: "Некорректный CVV",
  invalidCardName: "Некорректное имя карты",
  expPlaceholder: "MM / ГГ",
  successPayment: "Оплата прошла успешно",
  paymentId: "Идентификатор платежа: ",
  redirectNotice: "Вы будете перенаправлены через: ",
  continue: "Продолжить",
  failedPayment: "Оплата не состоялась",
  backToMethods: "Назад к выбору метода",
  goBack: "Вернуться в магазин",
  retry: "Повторить попытку",
  noConnection:
    "Ваше интернет-соединение нестабильно. Повторное подключение...",
  reconnected: "Интернет-соединение восстановлено",
  intentionExpired: "Срок действия платежного намерения истек",
  badRequestTitle: "Мы не можем обработать ваш запрос 😢",
  accessDenied: "Доступ запрещен ⛔",
  pageNotFound: "Страница не найдена или не существует 😢",
  responseTimeExpired: "Время ожидания ответа истекло ⌛",
  goBackAndRetry: "Вернитесь на сайт Мерчанта и повторите попытку платежа.",
  callMerchantOrGoBack:
    "Обратитесь к Мерчанту или вернитесь на сайт Мерчанта и повторите попытку платежа.",
  unexpectedError:
    "Мы не смогли выполнить запрос из-за непредвиденной ошибки 😢",
  tryAgainLater: "Повторите попытку позже.",
  noResponseFromBank: "Невозможно обработать Ваш платеж.",
  unableToProcessPayment: "Невозможно обработать Ваш платеж.",
  tryTheFollowing: "Во время Вашей оплаты произошла ошибка.",
  useDifferentCard:
    "Советуем совершить повторную оплату или воспользоваться другой картой. В случае повторной ошибки советуем обратиться в службу поддержки.",
  bankInternalErrorTitle:
    "Во время обработки операции на стороне банка произошла ошибка",
  transactionRejectedByBank: "Платежный провайдер отклонил платеж.",
  contactBankForReason:
    "Пожалуйста, свяжитесь с вашим финансовым учреждением для получения дальнейшей помощи или используйте другой метод.",
  bankInvalidCvvDesc: "Неверный код CVV.",
  checkCvvAndTryAgain:
    "Пожалуйста, дважды проверьте код CVV, его можно найти на обратной стороне Вашей карты.",
  bankInvalidPanTitle: "Неверный номер карты.",
  bankInvalidPanDescription:
    "Один или несколько параметров Вашей карты введены неправильно.",
  checkPanAndTryAgain:
    "Пожалуйста, убедитесь, что Вы ввели правильный номер карты.",
  cardExpired: "Неверные учетные данные карты.",
  invalidCardCredentialsTitle: "Неверные учетные данные карты.",
  doubleCheckCardCredential:
    "Пожалуйста, дважды проверьте учетные данные или используйте другую карту.",
  unfortunatelyStatusBankCard:
    "К сожалению статус Вашей карты не позволяет совершить оплату.",
  useAnotherCardPayment: "Советуем воспользоваться другой картой для оплаты.",
  authorizationFailed: "Авторизация неуспешна.",
  anAuthorizeError: "Ошибка авторизации.",
  pleasePaymentAgain:
    "Пожалуйста, попробуйте снова или используйте другой метод.",
  cardLostStolen:
    "Введенная Вами карта фиксируется как 'украденная' или 'потерянная'",
  bankCardExpiredTitle: "Неверный срок действия карты.",
  checkCorrectExpirationDate:
    "Пожалуйста, проверте, корректный ли срок действия Вашей карты, или воспользуйтесь действуещей картой. ",
  useRecommendation: "Воспользуйтесь советами, которые указаны ниже.",
  useAnotherCard: "Воспользуйтесь другой картой.",
  insufficientFunds: "Недостаточно средств",
  rechargeYourCard:
    "Пополните карту на сумму, необходимую для совершения операции.",
  comeBackAndChangeAmount:
    "Вернитесь на этап выбора и укажите сумму, которая доступна на карте.",
  onlinePaymentLimited:
    "Мы не смогли обработать вашу транзакцию, так как лимит на онлайн-платежи был превышен или исчерпан.",
  threeDSecureFailedTitle: "Банк отклонил операцию из-за ошибки 3D-Secure",
  tryAgainWithCurrentCard: "Повторно проведите операцию по текущей карте",
  cardAnother: "Использовать другую карту",
  cardSaved: "Сохраненная карта",
  rememberMe: "Запомнить карту",
  rememberMePM: "Сохранить вашу карту для будующих пополнений",
  bankLimitExceededTitle: "Превышен лимит онлайн-платежей.",
  lastThreeDigits: "Последние три цифры на обратной стороне кредитной карты",
  sessionExpired: "Время сеанса истекло.",
  sessionExpiredDesc: "Вернитесь на сайт продавца и повторите попытку оплаты.",
  monthTitle: "Месяц",
  yearTitle: "Год",
  sumTitle: "Сумма",
  orderTittle: "Номер заказа",
  loadingTtl: "Пожалуйста, подождите",
  "pm1.loadingTtl": "Подождите, пожалуйста",
  "privat.loadingTtl": "Ожидайте...",
  "mono.descriptionPaymentTtl": "Оплата счета",
  last3Digits: "Последние 3 цифры на обратной стороне карты",
  applePayTitle: "Оплата через",
  publicAgreementStart:
    'Нажимая кнопку "Пополнить" Вы принимаете условия Договора',
  publicAgreementLink: "публичной оферты",
  publicAgreementEnd: "о предоставлении услуг",
  // NEW TEXT
  HaveAQuestionAbout:
    'Если у вас возник вопрос по оплате? Нажмите здесь и посмотрите видео "Как сделать?.."',
  ApprovalHappensDuring: "Одобрение происходит в течение 1 часа после оплаты.",
  PaymentNotViaBankSlip:
    "Это означает, что оплата НЕ производится через банковский перевод.",
  PayInLotteryUsing: "Оплатите в Лотерее, используя данные ниже:",
  ConventionCode: "Код конвенции",
  CPFCNPJNumber: "Номер CPF/CNPJ",
  TotalDue: "Сумма к оплате",
  amount: "Сумма",
  ProductDescription: "Описание продукта",
  secretQuestion: "Секретный вопрос",
  questionCopy: "Секретный вопрос скопирован",
  secretAnswer: "Секретный ответ",
  answerCopy: "Ответ скопирован",
  recipientEmail: "E-mail получателя",
  recipientName: "Имя получателя",
  emailCopy: "Электронное письмо скопировано",
  SavePDF: "СОХРАНИТЬ PDF",
  Copy: "Копировать",
  Share: "Поделиться",
  // PDF
  beneficiary: "Бенефициар",
  agencyBeneficiaryCode: "Агентство/Код бенефициара",
  species: "Вид",
  quantity: "Количество",
  ourNumber: "Наш номер",
  documentNumber: "Номер документа",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "Срок платежа",
  send: "Отправить",
  topUpAmount: "Сумма пополнения",
  receive: "Получить",
  documentValue: "Сумма документа",
  discountsAllowances: "Скидка / Списание",
  otherDeductions: "Другие вычеты",
  interestPenalty: "Пеня / Штраф",
  otherAdditions: "Другие начисления",
  amountCharged: "Сумма к оплате",
  payer: "Плательщик",
  mechanicalAuthentication: "Механическая аутентификация",
  paymentLocation: "Место платежа",
  documentDate: "Дата документа",
  onTheDocument: "На документе",
  docType: "Тип документа",
  acceptance: "Принятие",
  processingDate: "Дата обработки",
  portfolio: "Портфель",
  lowerCode: "Код списания",
  mechanicalAuthenticationCompensationSlip:
    "Механическая аутентификация: карточка компенсации",
  endorserGuarantor: "Поручитель/Авалист",
  instructions:
    "Инструкции (текст ответственности Бенефициара) Не принимать оплату чеком. Билет с погашением в выходные дни. ID транзакции: {transID} Если возникнут проблемы с покупкой, обратитесь по адресу {email}",
  boletoDetailsTitle:
    'Вы можете оплатить в банках, лотереях, почтовых отделениях, супермаркетах или онлайн. Вы также можете сохранить файл сейчас, нажав кнопку "Сохранить PDF" ниже.',
  // NEW TRANS await all trans

  account: "Учетная запись",
  virtualAccountTitle:
    "Используйте приложение своего банка, чтобы завершить этот перевод.",
  bvn: "Номер банковского подтверждения",
  bankName: "Название банка",
  cardHolder: "Владелец карты",
  bankCode: "Банковский код",
  accountNumber: "Номер счета",
  accountNumberCopied: "Номер счета скопирован",
  bankNameCopied: "Название банка скопировано",
  phoneCopied: "Телефон скопирован",
  receiverNameCopied: "Имя получателя скопировано",
  cardHolderCopied: "Владелец карты скопирован",
  bankCodeCopied: "Банковский код скопирован",
  accountNameCopied: "Название счета скопировано",
  panCopied: "Номер карты скопирован",
  remainingTime: "Оставшееся время",
  accountName: "Название счета",
  virtualAccountFooter: "Вы получите подтверждение после получения платежа",
  preSubmitInfo:
    "Нажмите кнопку ниже, чтобы сгенерировать детали вашего платежа",
  showDetails: "Показать детали",
  hideDetails: "Скрыть детали",
  description: "Описание",
  transactionIdCopy: "ID транзакции скопирован",
  amountCopy: "Сумма скопированна",
  transactionFailed: "Ошибка транзакции",
  tryAgain: "Попробуйте еще раз",
  downloadReceipt: "Скачать квитанцию",
  success: "Успех",
  backToWebsite: "Вернуться на сайт",
  scanQrTitlePix:
    "Откройте свое платежное приложение и СКАНируйте QR-код ниже, чтобы оплатить, или КОПИРУЙТЕ приведенный ниже код Pix и вставьте его в свое платежное приложение, чтобы завершить покупку.",
  boletoGenerated: "Boleto создано!",
  youWantDltCard: "Вы уверены, что хотите удалить карту?",
  dltCardSystemTitle:
    "Данные карты будут удалены из списка сохраненных карт, а также из нашей системы.",
  cancel: "Отмена",
  delete: "Удалить",
  manageSavedCard: "Управление сохраненными картами",
  savedCard: "Cохраненные карты",
  done: "Готово",
  manage: "Управлять",
  chooseLanguage: "Выберите язык",
  savePdf: "Сохранить PDF",
  transactionIdTitle: "ID Транзакции",
  nameOnCard: "Имя на карте",
  email: "Электронная почта",
  firstName: "Имя",
  lastName: "Фамилия",
  middleName: "Отчество",
  checkPattern: "Проверьте введенные данные",
  "validation.birthday": "Должно быть в формате ISO: YYYY-MM-DD",
  "validation.phone":
    "Введите номер телефона в международном формате: + и 10-15 цифр.",
  "validation.phone_mobile_money": "Неверный номер телефона",
  "validation.pin": "Должно быть от 4 до 12 символов",
  phone: "Телефон",
  country: "Страна",
  state_code: "Код региона",
  city: "Город",
  address: "Адрес",
  zip_code: "Почтовый индекс",
  card_pin: "Карточный пин",
  processing_your: "Обрабатываем ваш",
  payment: "платеж",
  incorrect_value: "Значение неверно",
  submit: "Отправить",
  payer_name: "Имя плательщика",
  paymentInstructions: "Инструкции по оплате",
  paymentSteps: "Пожалуйста, найдите инструкции по осуществлению платежа:",
  copyAlias: "Скопировать CBU/Псевдоним",
  openBankApp: "Откройте программу своего банка",
  selectMoneyTransfer: "Выберите 'Money Transfer'",
  selectNewClient: "Выберите 'Новый клиент'",
  pasteCopiedCbuCode: "Вставьте скопированный код CBU/псевдоним",
  CBUCVUCopy: "CBU/CVU скопирован",
  aliasCopy: "Псевдоним скопирован",
  alias: "Псевдоним",
  time_remaining: "Не получил OTP: ",
  codeCopied: "Код скопирован",
  openThe: "Открыть",
  pastePaymentCode: "Вставьте код, оплата с кодом",
  howToUse: "Как использовать ",
  mobileMoneyMainTitle:
    "Введите свои Mobile Money, чтобы авторизовать этот платеж",
  mobileMoneySubTitle:
    "На ваш телефон отправлен запрос от оператора, введите свой PIN-код для авторизации этого платежа.",
  mobileMoneyInfoTitle:
    "Вам придет подтверждение, как только мы получим ваш платеж",
  resend_otp: "Отправить",
  dial: "Наберите",
  launchApp: "Запустить приложение",
  select: "Выбрать",
  pleaseSelectOption: "Пожалуйста, выберите опцию",
  walletVegaInfoContent:
    "Ваш первый депозит может не пройти из-за требований безопасности (внесения в белый список). В этом случае вы получите письмо от Vega Wallet на ваш зарегистрированный адрес электронной почты. Пожалуйста, подтвердите свой аккаунт, нажав на ссылку в письме, затем попробуйте сделать депозит снова.",
  walletVegaInfoContentEnding:
    " Для получения более подробной информации, пожалуйста, обратитесь к ссылке ниже.",
  howToUseMustRead: "Как использовать (обязательно прочитать)",
  walletVegaFormFieldsTitle:
    "Чтобы продолжить оплату, пожалуйста, введите свои данные авторизации:",
  userId: "ID пользователя",
  userPassword: "Пароль",
  "validation.walletVegaUserId":
    "Должен начинаться с U и содержать 6 символов после него",
  "validation.walletVegaUserPassword": "Должен содержать от 2 до 32 символов",
  userIdCopy: "ID пользователя скопировано",
  nameCopy: "Имя скопировано",
  name: "Имя",
  howToCompletePayment: "Как завершить платеж?",
  openYourBankingAccount: "Откройте свой банковский аккаунт",
  chooseInteracAmount: "Выберите Interac e-transfer и укажите сумму",
  fillRecipEmailName: "Введите email и имя получателя",
  completeSecret: "Завершите секретный вопрос и секретный ответ",
  confirmPayment: "Подтвердите платеж",
  confirmInBankApp: "Теперь подтвердите свой платеж в приложении вашего банка",
  interacInfoTitle:
    "Внимание! Пожалуйста, введите точную сумму и email получателя. В противном случае средства не будут зачислены на ваш счет.",
  enterYourIdentifier: "Введите ваш Id",
  enterAmount: "Введите сумму",
  userNameTransactionAmount:
    "Ваше имя пользователя и сумма транзакции будут отображены на вашем экране для проверки ваших данных",
  selectPaymentMethod: "Выберите способ оплаты и нажмите 'Оплатить сейчас'",
  amountComplete15Mins:
    "Средства будут зачислены через 15 минут после завершения транзакции.",
  iHaveMadePayment: "Я совершил платеж",
  depositInfoMainTitle:
    "Пожалуйста, ознакомьтесь с приведёнными ниже пунктами для осуществления платежа с помощью этого метода депозита:",
  onYourMobileDevice: "на вашем мобильном устройстве",
  amountToPay: "Сумма к оплате",
  paymentNotificationUssd:
    "По завершении оплаты вы получите уведомление об успешном зачислении вашего депозита",
  whereFindBlikCode: "Где я могу найти код BLIK?",
  blikCodeInstructions:
    "Откройте мобильное приложение вашего банка и войдите в систему.",
  blikCodeOption: "Найдите опцию BLIK в разделе Платежи или Переводы.",
  blikCodeGenerate:
    "Сгенерируйте 6-значный код BLIK, который действителен в течение нескольких минут.",
  blikCodeEnter: "Введите и отправьте код BLIK.",
  blikCodeLabel: "Код BLIK",
  blikCodeInvalid: "Требуется 6 цифр",
  upi_qr_title_mob:
    "Отсканируйте QR-код на своем устройстве или нажмите «ОТКРЫТЬ ПРИЛОЖЕНИЕ», чтобы быстро завершить платеж с помощью UPI.",
  upi_qr_title_desk:
    "Сканируйте QR-код на своем устройстве, чтобы быстро завершить платеж с помощью UPI.",
  choose_provider: "Выберите провайдера",
  choose_mobile_operator: "Выберите мобильного оператора",
  transactionStatus: "Статус транзакции",
  paymentsSlowMessage:
    "Ваш банк в настоящее время замедляет обработку платежей. Обычно медленные платежи подтверждаются в течение 30 минут.",
  receiptPaymentConfirmation:
    "Вы получите чек после подтверждения вашего платежа.",
  confirmingTransfer: "Мы подтверждаем ваш перевод. Это может занять около",
  doNotRefreshPage: "Пожалуйста, не обновляйте эту страницу",
  madePayment: "Я сделал(а) платеж",
  mins: "мин",
  secs: "сек",
  choose_currency: "Выберите валюту",
  choose_receiver_bank: "Выберите банк получателя",
  receiverName: "Имя получателя",
  bankAccountInfoTitle:
    "Пожалуйста, произведите платеж на следующий банковский счет",
  dateTooltip:
    "Срок действия карты находится ниже номера карты (например, 08/25)",

  // crypto title
  scanQRToTopUpWallet: "Сканировать QR-код для пополнения кошелька",
  orPayWithCard: "Или оплата картой",
  tryAnotherMethod: "Пожалуйста, выберите другой способ оплаты.",
  verification_code: "Код подтверждения",
  number: "Номер",
  scanQrTitleOpay:
    "Откройте платежное приложение и отсканируйте QR-код, чтобы оплатить",
  birthday: "День рождения",
  account_number: "Номер счета",
  bank_code: "Код банка",
  code: "Код",
  account_dob_day: "Дата создания счета",
  account_dob_month: "Месяц создания счета",
  account_dob_year: "Год создания счета",
  pin: "Пин",
  search: "Поиск",
  dropdownEmpty: "Ничего не было найдено. Попробуйте другой запрос",
  chooseMethod: "Пожалуйста, выберите способ оплаты, чтобы начать оплату",
  leftHpp: "Вы уверены, что хотите отменить этот платеж?",
  noConnectionTryAgain:
    "Ваше интернет-соединение нестабильно. Пожалуйста, попробуйте снова",
  selectedMethodError:
    "Произошла ошибка при выборе метода. Пожалуйста, попробуйте снова.",
  submitError:
    "Произошла ошибка при отправке формы. Пожалуйста, попробуйте еще раз",
  removeCardError:
    "Произошла ошибка при удалении карты. Пожалуйста, попробуйте еще раз",
  "Wallet address": "Адрес кошелька",
  TAG: "ТЕГ",
  pleaseSend: "Пожалуйста, отправьте",
  byCopyCryptoData: "скопировав адрес кошелька или отсканировав QR-код:",
  correctNetworkInfoTitle:
    "Убедитесь, что вы используете правильную сеть и валюту для этого кошелька. Любая сумма будет автоматически конвертирована в игровую валюту.",
  mistakeLostCryptoFound: "Ошибки могут привести к потере средств.",
  deletedCard: "Карта была удалена",
  redirectNotificationText:
    "Автоматическое перенаправление заблокировано. Пожалуйста, нажмите кнопку ниже, чтобы продолжить.",
  redirectNotificationButton: "Перейти к оплате",
  cancelPayment: "Вернуться к продавцу",
  cancelPaymentText:
    "Что-то пошло не так, транзакция обрабатывается дольше, чем обычно. Пожалуйста, подождите или вернитесь к продавцу",
  infoAboutPaymentMethodDefault:
    "Нажмите кнопку «Получить реквизиты оплаты» ниже, чтобы продолжить. Вы будете перенаправлены на страницу с реквизитами и четкими инструкциями для завершения платежа с помощью предпочитаемого вами метода.",
  buttonPayTextDefault: "Получить реквизиты оплаты",
  date_of_birth: "Дата рождения",
  accountRequisitesTitleMakePayment: "Найдите шаги, как произвести оплату:",
  accountRequisitesCopyAccountDetails: "Скопируйте реквизиты счета",
  accountRequisitesCopyAccountDetailsCopied: "Реквизиты счета скопированы",
  accountRequisitesMakeTransfer:
    "Совершите перевод по скопированным реквизитам на точную сумму",
  accountRequisitesPressButton: "Нажмите кнопку",
  paymentConfirmationSubtitleMadePayment: "«Я произвел оплату»",
  accountRequisitesMadeTransfer: "Перевод можно сделать только один раз.",
  requisitesErrorTitle: "Произошла ошибка при обработке вашего запроса",
  requisitesErrorDescription:
    "Не удалось получить информацию об оплате. Пожалуйста, вернитесь и выберите другой метод оплаты. Извините за неудобства.",
  youPay: "Вы платите",
  youGet: "Вы получаете",
  enterInformation: "Чтобы продолжить транзакцию, введите информацию ниже.",
  iAgree: "Я согласен с",
  termsOfService: "Условиями обслуживания",
  and: "и",
  privacyPolicy: "Политикой конфиденциальности",
  of: "от",
  paymentSuccessfully: "Платеж успешный",
  proceed: "Продолжить",
  paymentInProgress: "Платеж в процессе",
  paymentInProgressDescriptionStart:
    "Если вы совершили платеж, не волнуйтесь! Ваши средства будут зачислены на ваш баланс приблизительно через",
  minutes: "минут",
  paymentInProgressDescriptionEnd: ", в зависимости от нагрузки сети.",
  cryptoTopup: "Пополнение",
  min: "минимум",
  paymentMinimumLimit: "Платежи ниже минимального лимита не будут зачислены.",
  cryptoInfoTooltip: "Сумма криптовалюты равна",
  rate: "курсу",
  networkFee: "комиссии сети",
  cryptoCurrencyNonActiveTitle:
    "Чтобы продолжить платеж, пожалуйста, выполните следующие шаги:",
  cryptoCurrencyNonActiveFirstPoint:
    'Вернитесь на страницу выбора оплаты, нажав кнопку "Назад к методам".',
  cryptoCurrencyNonActiveSecondPoint:
    "Выберите альтернативную криптовалюту из списка доступных опций.",
  cryptoCurrencyNonActiveThirdPoint:
    "Подтвердите новый выбор и повторите процесс оплаты.",
  cryptoCurrencyNonActiveDisclaimer:
    "Выбранная криптовалюта в настоящее время недоступна для транзакций. Пожалуйста, выберите другую валюту из доступных опций, чтобы продолжить.",
};
