import successIcon from "../assets/icons/successDefault.svg";
import errorIcon from "../assets/icons/errorIcon.svg";
import { COLORS } from "../constants/themeConstants";
import themeElementStylesScheme from "./themeElementStylesScheme";

const { defaultElementStylesSchema } = themeElementStylesScheme;

const defaultColorSchema = {
  formHeaderBackground: COLORS.DEFAULT_DARK_BLUE,
  successHeaderPageBackground: COLORS.DEFAULT_DARK_BLUE,
  headerGradient: `radial-gradient(at top, ${COLORS.DEFAULT_DARK_BLUE}, ${COLORS.DEFAULT_DARK_BLUE} 92%, transparent 50%)`,
  buttonColor: COLORS.DEFAULT_DARK_PURPLE,
  mainColor: COLORS.DEFAULT_DARK_PURPLE,
  bodyBackground: COLORS.MAIN_BODY_GREY,
  buttonTextColor: COLORS.DEFAULT_WHITE,
  notificationColor: COLORS.DEFAULT_DARK_PURPLE,
  notificationColorError: COLORS.DANGER,
  notificationTextColor: COLORS.WHITE,
  tittleColor: COLORS.DEFAULT_WHITE,
  managePopupBackgroundColor: COLORS.DEFAULT_WHITE,
  infoBlockBackground: COLORS.DEFAULT_WHITE,
  successTitleColor: COLORS.DEFAULT_BLACK,
  errorTitleColor: COLORS.DANGER,
  remainingTimeBackground: COLORS.GREY_WHITE,
  remainingTimeTitleColor: COLORS.GRAY,
  remainingTimeTimesColor: COLORS.DEFAULT_BLACK,
  innerLineBackground: "linear-gradient(0deg, #e8edee, #e8edee), #e5eaeb",
  mainFont: "'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  regularFont: "'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  mediumFont: "'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  successIcon,
  errorIcon,
  loaderName: "FlexLoader",
  ...defaultElementStylesSchema,
};

export default defaultColorSchema;
