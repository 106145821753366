import { RefObject } from "preact";
import { OPERATOR_DATA } from "../constants/operator_data";

const truncateText = (
  text: string,
  setIsTruncateText: (status: boolean) => void,
) => {
  if (text.length > 21) {
    if (setIsTruncateText) setIsTruncateText(true);

    return `${text?.slice(0, 12)}...${text?.slice(-3)}`;
  }

  return text;
};

function getPrefixAndRegexp(
  currency?: string,
  ui_code?: string,
): { prefix: string; regexp: RegExp } | null {
  for (const entry of OPERATOR_DATA) {
    if (
      entry.currency.toLowerCase() === currency?.toLowerCase() &&
      entry.ui_code.toLowerCase() === ui_code?.toLowerCase()
    ) {
      return { prefix: entry.prefix, regexp: entry.regexp };
    }
  }

  return null;
}

interface AdjustFontSizeParams {
  ref: RefObject<HTMLElement>;
  length: number;
  size: number;
}

const adjustFontSize = ({ ref, length, size }: AdjustFontSizeParams): void => {
  if (ref.current) {
    const textLength = ref.current.textContent?.length || 0;

    let fontSize = size;

    if (textLength > length) {
      fontSize = size - (textLength - length) * 0.5;

      fontSize = Math.max(fontSize, 10);
    }

    ref.current.style.fontSize = `${fontSize}px`;
  }
};

const debounce = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timerId: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

type InputType = number | string | null | undefined;

const getMax = (num1: InputType, num2: InputType): number => {
  const n1 = parseFloat(String(num1));
  const n2 = parseFloat(String(num2));

  const validNum1 = !isNaN(n1) ? n1 : Number.NEGATIVE_INFINITY;
  const validNum2 = !isNaN(n2) ? n2 : Number.NEGATIVE_INFINITY;

  return Math.max(validNum1, validNum2);
};

export { truncateText, getPrefixAndRegexp, adjustFontSize, debounce, getMax };
