export default {
  pan: "카드 번호",
  exp: "유효 기간",
  "asquad.exp": "만료 날짜",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "결제하기",
  payPM: "충전하기",
  idTransTtl: "거래 ID",
  pendingTtl: "결제 처리 중",
  "mono.pendingTtl": "처리 중...",
  errorTtl: "오류가 발생했습니다",
  "mono.errorTtl": "죄송합니다. 거래가 거부되었습니다",
  successPaymentTtl: "결제가 성공적으로 완료되었습니다",
  "mono.successPaymentTtl": "거래 성공",
  cvvHint: "CVV 코드는 카드 뒷면에 표시되어 있습니다",
  rememberMeHint:
    "당사 웹사이트에서 향후 결제를 위해 카드 번호와 만료 날짜만 저장합니다",
  "mono.rememberMeHint":
    "당사 웹사이트에서 다음 결제를 위해 카드 번호와 만료 날짜만 저장합니다",
  sslHintTitle: "안전한 데이터 전송",
  sslHintDescription: "256비트 암호화가 적용된 안전한 프로토콜이 사용됩니다",
  required: "필수 입력란",
  requiredDatePb: "카드 만료 날짜를 입력하세요",
  requiredCvvPb: "카드의 CVV 코드를 입력하세요",
  invalidPanPb: "유효한 카드 번호를 입력하세요",
  invalidPan: "유효하지 않은 카드 번호",
  invalidDate: "유효하지 않은 날짜",
  invalidCvv: "유효하지 않은 CVV",
  invalidCardName: "유효하지 않은 카드 이름",
  expPlaceholder: "MM / YY",
  successPayment: "결제 성공",
  paymentId: "결제 ID: ",
  redirectNotice: "다음 시간 내에 리디렉션됩니다: ",
  continue: "계속",
  failedPayment: "결제 실패",
  backToMethods: "방법으로 돌아가기",
  goBack: "상점으로 돌아가기",
  retry: "재시도",
  noConnection: "인터넷 연결이 불안정합니다. 재연결 중...",
  reconnected: "인터넷 연결이 재연결되었습니다",
  intentionExpired: "결제 의도가 만료되었습니다",
  badRequestTitle: "요청을 처리할 수 없습니다 😢",
  accessDenied: "접근이 거부되었습니다 ⛔",
  pageNotFound: "페이지를 찾을 수 없거나 존재하지 않습니다 😢",
  responseTimeExpired: "응답 시간이 만료되었습니다 ⌛",
  goBackAndRetry: "상인의 웹사이트로 돌아가 결제를 다시 시도하세요.",
  callMerchantOrGoBack:
    "상인에게 연락하거나 상인의 웹사이트로 돌아가 결제를 다시 시도하세요.",
  unexpectedError: "예상치 못한 오류로 요청을 완료할 수 없습니다 😢",
  tryAgainLater: "나중에 다시 시도하세요",
  noResponseFromBank: "결제를 처리할 수 없습니다.",
  tryTheFollowing: "다시 결제하거나 다른 카드를 사용하시기 바랍니다.",
  unableToProcessPayment: "결제 중 오류가 발생했습니다.",
  useDifferentCard: "오류가 반복될 경우 지원 서비스에 문의하시기 바랍니다.",
  bankInternalErrorTitle: "은행 측에서 거래 처리 중 오류가 발생했습니다",
  transactionRejectedByBank: "결제 제공자가 결제를 거부했습니다.",
  contactBankForReason:
    "추가 지원을 위해 금융 기관에 문의하시거나 다른 방법을 사용하세요.",
  bankInvalidCvvDesc: "잘못된 CVV 코드입니다.",
  invalidCardCredentialsTitle: "유효하지 않은 카드 자격 증명입니다.",
  checkCvvAndTryAgain:
    "CVV 코드를 다시 확인하세요. 카드 뒷면에서 찾을 수 있습니다.",
  doubleCheckCardCredential:
    "자격 증명을 다시 확인하시거나 다른 카드를 사용하세요.",
  bankInvalidPanTitle: "유효하지 않은 카드 번호입니다.",
  bankInvalidPanDescription: "잘못된 카드 번호입니다.",
  checkPanAndTryAgain: "올바른 카드 번호를 입력했는지 확인하세요",
  cardExpired: "카드가 만료되었습니다",
  unfortunatelyStatusBankCard:
    "유감스럽게도 카드 상태로 인해 결제할 수 없습니다.",
  useAnotherCardPayment: "다른 카드를 사용하여 결제하시기 바랍니다.",
  authorizationFailed: "승인 실패.",
  anAuthorizeError: "승인 오류.",
  pleasePaymentAgain: "다시 시도하시거나 다른 방법을 사용하세요.",
  cardLostStolen: "입력한 카드는 '도난' 또는 '분실'로 기록되어 있습니다.",
  bankCardExpiredTitle: "카드 만료 날짜가 올바르지 않습니다.",
  checkCorrectExpirationDate:
    "카드 만료 날짜가 올바른지 확인하시거나 유효한 카드를 사용하세요.",
  insufficientFunds: "잔액이 부족합니다.",
  useRecommendation: "아래의 팁을 사용하세요.",
  useAnotherCard: "다른 카드를 사용하세요.",
  rechargeYourCard: "거래를 수행하는 데 필요한 금액만큼 카드를 충전하세요.",
  comeBackAndChangeAmount:
    "선택 단계로 돌아가 카드에 사용 가능한 금액을 지정하세요.",
  onlinePaymentLimited:
    "온라인 결제 한도가 초과되었거나 소진되어 거래를 처리할 수 없습니다.",
  threeDSecureFailedTitle: "승인 실패.",
  tryAgainWithCurrentCard: "현재 카드로 다시 시도",
  cardAnother: "다른 카드 사용",
  cardSaved: "저장된 카드",
  rememberMe: "로그인 유지",
  rememberMePM: "향후 충전을 위해 카드를 저장하세요",
  bankLimitExceededTitle: "온라인 결제 한도 초과.",
  lastThreeDigits: "신용 카드 뒷면의 마지막 세 자리 숫자",
  sessionExpired: "세션이 만료되었습니다.",
  sessionExpiredDesc: "상인의 웹사이트로 돌아가 결제를 다시 시도하세요.",
  monthTitle: "월",
  yearTitle: "년",
  sumTitle: "합계",
  openThe: "열기",
  pastePaymentCode: "코드 붙여넣기, 코드로 결제",
  howToUse: "사용 방법 ",
  orderTittle: "주문",
  loadingTtl: "잠시만 기다려 주세요",
  "pm1.loadingTtl": "잠시만 기다려 주세요",
  "privat.loadingTtl": "기다리세요...",
  "mono.descriptionPaymentTtl": "주문 결제",
  applePayTitle: "결제 방법",
  last3Digits: "카드 뒷면의 마지막 3자리 숫자",
  publicAgreementStart: '"충전" 버튼을 클릭하면 약관에 동의하게 됩니다',
  publicAgreementLink: "공개 제안",
  publicAgreementEnd: "서비스 제공에 대한",
  // NEW TEXT
  HaveAQuestionAbout:
    '결제에 대한 질문이 있습니까? 여기를 클릭하여 "사용 방법" 비디오를 시청하세요.',
  ApprovalHappensDuring: "승인은 결제 후 1시간 이내에 이루어집니다.",
  PaymentNotViaBankSlip: "이는 결제가 은행 전표를 통한 것이 아님을 의미합니다.",
  PayInLotteryUsing: "아래의 세부 정보를 사용하여 복권에서 결제하세요:",
  ConventionCode: "협약 코드",
  CPFCNPJNumber: "CPF/CNPJ 번호",
  TotalDue: "총액",
  amount: "금액",
  ProductDescription: "제품 설명",
  SavePDF: "PDF 저장",
  Copy: "복사",
  Share: "공유",
  // PDF
  beneficiary: "수혜자",
  agencyBeneficiaryCode: "지점/수혜자 코드",
  species: "종",
  quantity: "수량",
  payer_name: "지불인 이름",
  paymentInstructions: "결제 지침",
  paymentSteps: "결제하는 방법은 다음과 같습니다:",
  copyAlias: "CBU/별칭 복사",
  openBankApp: "은행 앱 열기",
  selectMoneyTransfer: "'송금' 선택",
  selectNewClient: "'새 고객' 선택",
  pasteCopiedCbuCode: "복사한 CBU/별칭 코드 붙여넣기",
  CBUCVUCopy: "CBU/CVU 복사",
  aliasCopy: "별칭 복사",
  alias: "별칭",
  ourNumber: "당사 번호",
  documentNumber: "문서 번호",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "만기일",
  documentValue: "문서 가치",
  discountsAllowances: "할인/수당",
  otherDeductions: "기타 공제",
  interestPenalty: "이자/벌금",
  otherAdditions: "기타 추가",
  amountCharged: "청구 금액",
  send: "보내기",
  "Amount copied": "금액 복사됨",
  topUpAmount: "충전 금액",
  receive: "받기",
  payer: "지불인",
  mechanicalAuthentication: "기계적 인증:",
  cutOnTheDottedLine: "점선에 따라 자르세요",
  paymentLocation: "결제 장소: 만기일까지 모든 은행에서 지불 가능",
  documentDate: "문서 날짜",
  onTheDocument: "문서 상에",
  docType: "문서 유형",
  acceptance: "수락",
  processingDate: "처리 날짜",
  portfolio: "포트폴리오",
  lowerCode: "하단 코드",
  mechanicalAuthenticationCompensationSlip: "기계적 인증 보상 전표",
  endorserGuarantor: "배서인/보증인",
  instructions:
    "지침 (수혜자의 책임 하에 작성된 텍스트) 수표로 결제 받지 마세요. 주말에 만기되는 보레토. 거래 ID: {transID} 구매에 문제가 있으면 {email}에 접속하세요",
  boletoDetailsTitle:
    '은행, 복권 판매점, 우체국, 슈퍼마켓 또는 온라인에서 결제할 수 있습니다. 아래의 "PDF 저장" 버튼을 클릭하여 파일을 지금 저장할 수도 있습니다.',

  account: "계정",
  virtualAccountTitle: "은행 앱을 사용하여 이 송금을 완료하세요",
  bvn: "은행 확인 번호",
  bankName: "은행 이름",
  cardHolder: "카드 소지자",
  bankCode: "은행 코드",
  accountNumber: "계좌 번호",
  accountNumberCopied: "계좌 번호가 복사되었습니다",
  bankNameCopied: "은행 이름이 복사되었습니다",
  phoneCopied: "전화번호가 복사되었습니다",
  receiverNameCopied: "수신자 이름이 복사되었습니다",
  cardHolderCopied: "카드 소지자가 복사되었습니다",
  bankCodeCopied: "은행 코드가 복사되었습니다",
  accountNameCopied: "계좌 이름이 복사되었습니다",
  panCopied: "카드 번호가 복사되었습니다",
  accountName: "계좌 이름",
  remainingTime: "남은 시간",
  virtualAccountFooter: "결제가 접수되면 확인을 받게 됩니다",
  preSubmitInfo: "아래 버튼을 눌러 결제 정보를 생성하세요",
  showDetails: "세부 정보 표시",
  hideDetails: "세부 정보 숨기기",
  description: "설명",
  transactionIdCopy: "거래 ID가 복사되었습니다",
  amountCopy: "금액이 복사되었습니다",
  transactionFailed: "거래 실패",
  userIdCopy: "사용자 ID가 복사되었습니다",
  nameCopy: "이름이 복사되었습니다",
  name: "이름",
  depositInfoMainTitle: "이 입금 방법을 통해 결제하는 방법은 다음과 같습니다:",
  launchApp: "앱 실행",
  howToCompletePayment: "결제를 완료하는 방법은?",
  openYourBankingAccount: "은행 계좌를 여세요",
  chooseInteracAmount: "Interac e-transfer를 선택하고 금액을 입력하세요",
  fillRecipEmailName: "수신자 이메일과 이름을 입력하세요",
  completeSecret: "비밀 질문과 비밀 답변을 완료하세요",
  confirmPayment: "결제를 확인하세요",
  confirmInBankApp: "이제 은행 앱에서 결제를 확인하세요",
  interacInfoTitle:
    "주의! 정확한 금액과 수신자의 이메일을 입력하세요. 그렇지 않으면 자금이 계정에 입금되지 않습니다.",
  select: "선택",
  pleaseSelectOption: "선택하세요",
  walletVegaInfoContent:
    "보안 요구 사항(화이트리스트)으로 인해 첫 입금이 실패할 수 있습니다. 이 경우 등록된 이메일 주소로 Vega Wallet에서 이메일을 받게 됩니다. 이메일의 링크를 클릭하여 계정을 확인한 후 다시 입금을 시도하십시오.",
  walletVegaInfoContentEnding: " 자세한 정보는 아래 링크를 참조하십시오.",
  howToUseMustRead: "사용 방법(필독)",
  walletVegaFormFieldsTitle: "결제를 진행하려면 인증 세부 정보를 입력하십시오:",
  userId: "사용자 ID",
  userPassword: "비밀번호",
  "validation.walletVegaUserId":
    "U로 시작하고 그 뒤에 6개의 기호를 포함해야 합니다",
  "validation.walletVegaUserPassword": "2에서 32개의 기호를 포함해야 합니다",
  enterYourIdentifier: "사용자 ID를 입력하세요",
  enterAmount: "금액을 입력하세요",
  userNameTransactionAmount:
    "세부 정보를 확인하기 위해 사용자 이름과 거래 금액이 화면에 표시됩니다",
  selectPaymentMethod: "결제 방법을 선택하고 '지금 결제'를 클릭하세요",
  amountComplete15Mins: "거래 완료 후 15분 내에 자금이 충전됩니다.",
  iHaveMadePayment: "결제를 완료했습니다",
  secretQuestion: "비밀 질문",
  secretAnswer: "비밀 답변",
  recipientEmail: "수신자 이메일",
  recipientName: "수신자 이름",
  answerCopy: "답변이 복사되었습니다",
  questionCopy: "비밀 질문이 복사되었습니다",
  emailCopy: "이메일이 복사되었습니다",
  dial: "다이얼",
  paymentNotificationUssd:
    "결제를 진행하세요. 입금이 성공하면 알림을 받게 됩니다",
  whereFindBlikCode: "BLIK 코드는 어디에서 찾을 수 있나요?",
  blikCodeInstructions: "은행의 모바일 앱을 열고 로그인하세요.",
  blikCodeOption: "결제 또는 이체 섹션에서 BLIK 옵션을 찾으세요.",
  blikCodeGenerate:
    "6자리 BLIK 코드를 생성하세요. 이 코드는 몇 분 동안 유효합니다.",
  blikCodeEnter: "BLIK 코드를 입력하고 제출하세요.",
  blikCodeLabel: "BLIK 코드",
  blikCodeInvalid: "6자리 숫자가 필요합니다",
  onYourMobileDevice: "모바일 장치에서",
  tryAgain: "다시 시도",
  downloadReceipt: "영수증 다운로드",
  paymentSuccessfully: "결제가 성공적으로 처리되었습니다",
  scanQrTitlePix:
    "결제 앱을 열고 아래 QR 코드를 스캔하여 결제하거나 아래 Pix 코드를 복사하여 결제 앱에 붙여넣어 구매를 완료하세요.",
  boletoGenerated: "보레토가 생성되었습니다!",
  youWantDltCard: "카드를 삭제하시겠습니까?",
  dltCardSystemTitle: "카드 데이터는 저장된 카드 목록과 시스템에서 삭제됩니다.",
  cancel: "취소",
  delete: "삭제",
  manageSavedCard: "저장된 카드 관리",
  savedCard: "저장된 카드",
  transactionStatus: "거래 상태",
  paymentsSlowMessage:
    "현재 은행에서 결제가 지연되고 있습니다. 지연된 결제는 보통 30분 이내에 확인됩니다.",
  receiptPaymentConfirmation: "결제가 확인되면 영수증을 받게 됩니다",
  doNotRefreshPage: "이 페이지를 새로 고치지 마세요",
  madePayment: "결제를 완료했습니다",
  mins: "분",
  secs: "초",
  confirmingTransfer: "송금을 확인하고 있습니다. 약 시간이 걸릴 수 있습니다",
  done: "완료",
  manage: "관리",
  chooseLanguage: "언어 선택",
  savePdf: "PDF 저장",
  transactionIdTitle: "거래 ID",
  nameOnCard: "카드 상의 이름",
  email: "이메일",
  firstName: "이름",
  lastName: "성",
  orPayWithCard: "또는 카드로 결제",
  dateTooltip: "유효 기간은 카드 번호 아래에 위치해 있습니다 (예: 08/25)",
  middleName: "중간 이름",
  checkPattern: "입력 데이터 확인",
  "validation.birthday": "ISO 형식이어야 합니다: YYYY-MM-DD",
  "validation.phone":
    "전화번호를 국제 형식으로 입력하세요: + 및 10-15자리 숫자.",
  "validation.phone_mobile_money": "잘못된 전화번호",
  "validation.pin": "4~12자 사이여야 합니다",
  phone: "전화번호",
  country: "국가",
  state_code: "주 코드",
  city: "도시",
  address: "주소",
  zip_code: "우편번호",
  card_pin: "카드 핀",
  processing_your: "처리 중",
  payment: "결제",
  incorrect_value: "값이 올바르지 않습니다",
  selectAmountToPay: "제안된 목록에서 '결제할 금액'을 선택하세요.",
  initiatedAmountDeclinedTitle: "P2P 거래에 대하여",
  initiatedAmountDeclined:
    "P2P 송금은 사용자 간에 데이터를 직접 교환하는 방법입니다. 따라서 입력한 금액으로 항상 처리할 수 있는 것은 아닙니다. 이와 같은 경우, 아래 표시된 옵션 중에서 선호하는 금액에 가장 가까운 금액을 선택하십시오.",
  toCompleteThePaymentFollowSteps: "결제를 완료하려면 다음 단계를 따르세요:",
  clickSubmitToConfirm: "'제출'을 클릭하여 선택한 결제 금액을 확인하세요.",
  afterSelectingAmount:
    "금액을 선택한 후 결제를 완료하기 위해 은행의 페이지로 리디렉션됩니다.",
  submit: "제출",
  time_remaining: "OTP를 받지 못했습니까?: ",
  mobileMoneyMainTitle: "이 결제를 승인하려면 Mobile Money를 입력하세요",
  mobileMoneySubTitle:
    "운영자로부터의 요청이 전화로 전송되었습니다. 이 결제를 승인하려면 PIN을 입력하세요.",
  mobileMoneyInfoTitle: "결제를 받으면 확인을 받게 됩니다",
  resend_otp: "재전송",
  amountToPay: "결제할 금액",
  upi_qr_title_mob:
    '기기에서 QR 코드를 스캔하거나 "앱 열기"를 눌러 UPI로 빠르게 결제를 완료하세요.',
  upi_qr_title_desk:
    "기기에서 QR 코드를 스캔하여 UPI로 빠르게 결제를 완료하세요.",
  choose_provider: "제공자 선택",
  choose_mobile_operator: "모바일 운영자 선택",
  choose_currency: "통화 선택",
  choose_receiver_bank: "수신 은행 선택",
  receiverName: "수신자 이름",
  bankAccountInfoTitle: "다음 은행 계좌로 결제하세요",

  // crypto titles
  scanQRToTopUpWallet: "QR 코드를 스캔하여 지갑을 충전하세요",
  tryAnotherMethod: "다른 결제 방법을 시도하세요.",
  verification_code: "인증 코드",
  number: "번호",
  scanQrTitleOpay: "결제 앱을 열고 아래 QR 코드를 스캔하여 결제하세요",
  birthday: "생일",
  account_number: "계좌 번호",
  bank_code: "은행 코드",
  code: "코드",
  codeCopied: "코드가 복사되었습니다",
  account_dob_day: "계정 생성 일",
  account_dob_month: "계정 생성 월",
  account_dob_year: "계정 생성 연도",
  pin: "핀",
  search: "검색",
  dropdownEmpty: "아무것도 찾을 수 없습니다. 다른 검색어를 시도하세요",
  chooseMethod: "결제를 시작하려면 결제 방법을 선택하세요",
  leftHpp: "이 결제를 취소하시겠습니까?",
  noConnectionTryAgain: "인터넷 연결이 불안정합니다. 다시 시도하세요",
  selectedMethodError: "방법 선택 중 오류가 발생했습니다. 다시 시도하세요",
  submitError: "양식 제출 중 오류가 발생했습니다. 다시 시도하세요",
  removeCardError: "카드 제거 중 오류가 발생했습니다. 다시 시도하세요",
  "Wallet address": "지갑 주소",
  TAG: "태그",
  pleaseSend: "보내주세요",
  byCopyCryptoData: "지갑 주소를 복사하거나 QR 코드를 스캔하여:",
  correctNetworkInfoTitle:
    "이 지갑에 대해 올바른 네트워크와 통화를 사용하고 있는지 확인하십시오. 모든 금액은 자동으로 게임 내 통화로 변환됩니다.",
  mistakeLostCryptoFound: "실수는 자금 손실로 이어질 수 있습니다.",
  deletedCard: "카드가 삭제되었습니다",
  redirectNotificationText:
    "자동 리디렉션이 차단되었습니다. 계속하려면 아래 버튼을 클릭하세요.",
  redirectNotificationButton: "결제로 이동",
  cancelPayment: "판매자에게 돌아가기",
  cancelPaymentText:
    "문제가 발생했습니다. 거래 처리에 예상보다 시간이 오래 걸리고 있습니다. 기다리시거나 판매자에게 돌아가십시오.",
  infoAboutPaymentMethodDefault:
    "계속하려면 아래의 «결제 정보 생성» 버튼을 클릭하세요. 결제 정보와 선호하는 방법을 사용하여 결제를 완료하는 명확한 지침이 있는 페이지로 리디렉션됩니다.",
  buttonPayTextDefault: "결제 정보 생성",
  date_of_birth: "생년월일",
  accountRequisitesTitleMakePayment: "결제 방법을 안내드립니다:",
  accountRequisitesCopyAccountDetails: "계좌 정보를 복사하세요",
  accountRequisitesCopyAccountDetailsCopied: "계좌 정보가 복사되었습니다",
  accountRequisitesMakeTransfer: "복사한 계좌 정보로 정확한 금액을 이체하세요",
  accountRequisitesPressButton: "버튼을 누르세요",
  paymentConfirmationSubtitleMadePayment: "«결제를 완료했습니다»",
  accountRequisitesMadeTransfer: "송금은 한 번만 가능합니다.",
  requisitesErrorTitle: "요청을 처리하는 동안 오류가 발생했습니다",
  requisitesErrorDescription:
    "결제 정보를 가져오지 못했습니다. 돌아가서 다른 결제 수단을 선택하세요. 불편을 드려 죄송합니다.",
  youPay: "지불 금액",
  youGet: "받는 금액",
  enterInformation: "거래를 진행하려면 아래 정보를 입력하세요.",
  iAgree: "동의합니다",
  termsOfService: "서비스 약관",
  and: "및",
  privacyPolicy: "개인정보 처리방침",
  of: "의",
  proceed: "진행",
  paymentInProgress: "결제 진행 중",
  paymentInProgressDescriptionStart:
    "결제를 완료하셨다면 걱정하지 마세요! 자금은 약",
  minutes: "분",
  paymentInProgressDescriptionEnd:
    " 후에 잔액에 반영될 예정이며, 네트워크 부하에 따라 달라질 수 있습니다.",
  cryptoTopup: "충전",
  min: "최소",
  paymentMinimumLimit: "최소 한도 미만의 결제는 인정되지 않습니다.",
  cryptoInfoTooltip: "암호화폐 금액은 다음과 같습니다",
  rate: "환율",
  networkFee: "네트워크 수수료",
  cryptoCurrencyNonActiveTitle: "결제를 진행하려면 다음 단계를 따르세요:",
  cryptoCurrencyNonActiveFirstPoint:
    '"방법으로 돌아가기" 버튼을 클릭하여 결제 옵션 페이지로 돌아갑니다.',
  cryptoCurrencyNonActiveSecondPoint:
    "사용 가능한 옵션 목록에서 대체 암호화폐를 선택합니다.",
  cryptoCurrencyNonActiveThirdPoint:
    "새 선택을 확인하고 결제 과정을 다시 시도하세요.",
  cryptoCurrencyNonActiveDisclaimer:
    "선택한 암호화폐는 현재 거래에 사용할 수 없습니다. 계속하려면 사용 가능한 옵션에서 다른 통화를 선택하십시오.",
  backToWebsite: "웹사이트로 돌아가기",
};
