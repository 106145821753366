let brand = {};

const setBrand = (data) => {
  brand = { ...brand, ...data };

  localStorage.setItem("brand", JSON.stringify(brand));

  return brand;
};

const getBrand = () => {
  return brand;
};

const getColorScheme = () => {
  if (brand?.color_scheme) {
    return brand?.color_scheme;
  }

  let scheme;

  try {
    scheme = JSON.parse(localStorage.getItem("brand") || "")?.color_scheme;
  } catch (e) {}

  return scheme || "blue";
};

export default {
  getBrand,
  setBrand,
  getColorScheme,
};
