import { init as initApm } from "@elastic/apm-rum";
import axios from "axios";

let apm;
const errors = {};
const activeTransactions = {};

const logError = (error, data) => {
  console.error(error, data);

  if (apm) {
    apm.setCustomContext(data);
    apm.captureError(error);
  }
};

export default {
  log: (message, data) => {
    console.log(message, data);
  },

  warn: (message, data) => {
    console.warn(message, data);
  },

  error: logError,

  errorOnce: (error, data) => {
    if (!errors[error]) {
      logError(error, data);
      errors[error] = true;
    }
  },

  setUser: ({ id }) => {
    if (apm) {
      apm.setUserContext({ id });
    }
  },

  setCustomContext: (context) => {
    if (apm) {
      apm.setCustomContext(context);
    }
  },

  addLabels: (labels) => {
    if (apm) {
      apm.addLabels(labels);
    }
  },

  startTransaction: (name, type = "custom") => {
    if (apm) {
      return apm.startTransaction(name, type);
    }
  },

  getActiveTransaction: (name) => activeTransactions[name],

  setActiveTransaction: (name, transaction) => {
    activeTransactions[name] = transaction;
  },

  clearActiveTransaction: (name) => {
    delete activeTransactions[name];
  },

  initApm: () => {
    if (apm) {
      return false;
    }

    apm = initApm({
      serviceName: "hpp-js",
      serverUrl: "/apm",
    });

    axios.interceptors.response.use(
      (response) => {
        apm.addLabels({
          requestId: response?.data?.request_id,
        });

        return response;
      },
      (error) => {
        apm.captureError(error, {
          custom: {
            requestBody: error?.response?.config?.data,
            responseBody: error?.response?.data,
          },
        });

        return Promise.reject(error);
      },
    );
  },
};
