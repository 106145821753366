import locales from "../locales";

let currentLocale;
let isLocaleSet = false;
const supportedLocales = [
  "en",
  "uk",
  "ru",
  "pt",
  "jp",
  "pl",
  "tr",
  "ko",
  "es",
  "kz",
];
const defaultLocale = supportedLocales[0];

const setLocale = (locale) => {
  currentLocale = locale;
  isLocaleSet = true;
};

const setLocaleIfPresent = (locale) => {
  if (locale === "ua") {
    locale = "uk";
  }

  if (!locale || !supportedLocales.includes(locale)) {
    return;
  }

  setLocale(locale);
};

const getBrowserLocale = () => {
  if (typeof window === "undefined") {
    return null;
  }

  return window.navigator.language || window.navigator.userLanguage;
};

const getFallbackLocale = () => {
  const browserLocale = getBrowserLocale();
  const localeParts =
    typeof browserLocale === "string" && browserLocale.split("-");
  const localeBase = localeParts && localeParts.length && localeParts[0];

  if (!supportedLocales.includes(localeBase, defaultLocale)) {
    return defaultLocale;
  }

  return localeBase;
};

const getLocale = () => {
  return currentLocale;
};

const getIsLocaleSet = () => {
  return isLocaleSet;
};

currentLocale = getFallbackLocale();

const getMessage = ({ message, children }) => {
  const localeCode = getLocale();
  const locale = locales[localeCode];
  const fallback = children || message || "i18n error";
  const content = message && locale[message] ? locale[message] : fallback;

  return content;
};

export default {
  currentLocale,
  setLocaleIfPresent,
  getLocale,
  getIsLocaleSet,
  getMessage,
};
