export default {
  pan: "Card number",
  exp: "Expiry date",
  "asquad.exp": "Expire date",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Pay",
  payPM: "Replenish",
  idTransTtl: "ID Transaction",
  pendingTtl: "Payment is being processed",
  "mono.pendingTtl": "Operation in processing...",
  errorTtl: "An error has occurred",
  "mono.errorTtl": "Sorry, the transaction has been declined",
  successPaymentTtl: "Payment was successful",
  "mono.successPaymentTtl": "Transaction successful",
  cvvHint: "The CVV code is indicated at the back of your card",
  rememberMeHint:
    "We save only the card number and expiration date for future payments on our website",
  "mono.rememberMeHint":
    "We save only the card number and expiration date for subsequent payments on our website",
  sslHintTitle: "Secure data transfer",
  sslHintDescription: "Secure protocol with 256-bit encryption is used",
  required: "Required field",
  requiredDatePb: "Enter card expiration date",
  requiredCvvPb: "Enter the CVV code of the card",
  invalidPanPb: "Please enter a valid card number",
  invalidPan: "Invalid card number",
  invalidDate: "Invalid date",
  invalidCvv: "Invalid CVV",
  invalidCardName: "Invalid name on card",
  expPlaceholder: "MM / YY",
  successPayment: "Payment successful",
  paymentId: "Payment ID: ",
  redirectNotice: "You will be redirected within: ",
  continue: "Continue",
  failedPayment: "Payment failed",
  backToMethods: "Back to methods",
  goBack: "Back to store",
  retry: "Retry",
  noConnection: "Your internet connection is unstable. Reconnecting...",
  reconnected: "Internet connection reconnected",
  intentionExpired: "Payment intention expired",
  badRequestTitle: "We can't process your request 😢",
  accessDenied: "Access denied ⛔",
  pageNotFound: "Page not found or doesn’t exist 😢",
  responseTimeExpired: "Response time expired ⌛",
  goBackAndRetry: "Go back to Merchant’s website and retry payment.",
  callMerchantOrGoBack:
    "Contact Merchant or go back to Merchant's website and retry payment.",
  unexpectedError:
    "We’re unable to complete your request due to unexpected error 😢",
  tryAgainLater: "Try again later",
  noResponseFromBank: "Unable to process your payment.",
  tryTheFollowing: "We advise you to pay again or use another card.",
  unableToProcessPayment: "There was an error during your payment.",
  useDifferentCard:
    "In case of a repeated error we advise you to contact our support service.",
  bankInternalErrorTitle:
    "An error occurred while processing your transaction on the bank side",
  transactionRejectedByBank: "Payment provider has declined the payment.",
  contactBankForReason:
    "Please contact your financial institution for further assistance or use another method.",
  bankInvalidCvvDesc: "Incorrect CVV code.",
  invalidCardCredentialsTitle: "Invalid card credentials.",
  checkCvvAndTryAgain:
    "Please double check the CVV code, it can be found on the back of your card.",
  doubleCheckCardCredential:
    "Please double check your credentials or use a different card.",
  bankInvalidPanTitle: "Invalid card number.",
  bankInvalidPanDescription: "Incorrect card number.",
  checkPanAndTryAgain: "Please make sure you entered the correct card number",
  cardExpired: "Card expired",
  unfortunatelyStatusBankCard:
    "Unfortunately, the status of your card does not allow you to make a payment.",
  useAnotherCardPayment: "We advise you to use another card for payment.",
  authorizationFailed: "Authorization failed.",
  anAuthorizeError: "Authorization error.",
  pleasePaymentAgain: "Please try again or use another method.",
  cardLostStolen: "The card you entered is recorded as 'stolen' or 'lost'.",
  bankCardExpiredTitle: "Card expiration date is incorrect.",
  checkCorrectExpirationDate:
    "Please check if your card expiration date is correct or use a valid card.",
  insufficientFunds: "Insufficient funds.",
  useRecommendation: "Use the tips below.",
  useAnotherCard: "Use another card.",
  rechargeYourCard:
    "Refill the card by the amount necessary to make the transaction.",
  comeBackAndChangeAmount:
    "Go back to the selection stage and specify the amount available on the card.",
  onlinePaymentLimited:
    "We were unable to process your transaction because the online payment limit has been exceeded or exhausted.",
  threeDSecureFailedTitle: "Authorization failed.",
  tryAgainWithCurrentCard: "Try again with current card",
  cardAnother: "Use another card",
  cardSaved: "Saved card",
  rememberMe: "Remember me",
  rememberMePM: "Save your card for future replenishment",
  bankLimitExceededTitle: "Online Payment Limit Exceeded.",
  lastThreeDigits: "Last three digits on the back of the credit card",
  sessionExpired: "Your session has expired.",
  sessionExpiredDesc: "Go back to Merchant's website and retry payment.",
  monthTitle: "Month",
  yearTitle: "Year",
  sumTitle: "Sum",
  openThe: "Open the",
  pastePaymentCode: "Paste Code, payment with code",
  howToUse: "How to use ",
  orderTittle: "Order",
  loadingTtl: "Please wait",
  "pm1.loadingTtl": "Please wait",
  "privat.loadingTtl": "Wait...",
  "mono.descriptionPaymentTtl": "Order payment",
  applePayTitle: "Payment with",
  last3Digits: "Last 3 digits on the back of your card",
  publicAgreementStart:
    'By clicking the "Replenish" button you accept the terms of the',
  publicAgreementLink: "public offer",
  publicAgreementEnd: "for the provision of services",
  HaveAQuestionAbout:
    'Have a question about your payment? Click here and watch "How to?.." video.',
  ApprovalHappensDuring: "Approval happens during 1 hour after payment.",
  PaymentNotViaBankSlip: "It's mean the payment IS NOT via bank slip.",
  PayInLotteryUsing: "Pay in Lottery using the details below:",
  ConventionCode: "Convention code",
  CPFCNPJNumber: "CPF/CNPJ number",
  TotalDue: "Total due",
  amount: "Amount",
  ProductDescription: "Product Description",
  SavePDF: "SAVE PDF",
  Copy: "Copy",
  Share: "Share",
  beneficiary: "Beneficiary",
  agencyBeneficiaryCode: "Agency/Beneficiary Code",
  species: "Species",
  quantity: "Quantity",
  payer_name: "Payer name",
  paymentInstructions: "Payment instructions",
  paymentSteps: "Please find the steps how to make payment:",
  copyAlias: "Copy CBU/Alias",
  openBankApp: "Open your Bank app",
  selectMoneyTransfer: "Select 'Money Transfer'",
  selectNewClient: "Select 'New client'",
  pasteCopiedCbuCode: "Paste the copied CBU/Alias code",
  CBUCVUCopy: "CBU/CVU copy",
  aliasCopy: "Alias copy",
  alias: "Alias",
  ourNumber: "Our number",
  documentNumber: "Document number",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "Due date",
  documentValue: "Document value",
  discountsAllowances: "Discounts/Allowances",
  otherDeductions: "Other deductions",
  interestPenalty: "Interest/Penalty",
  otherAdditions: "Other additions",
  amountCharged: "Amount charged",
  send: "Send",
  "Amount copied": "Amount copied",
  topUpAmount: "Top up amount",
  receive: "Receive",
  payer: "Payer",
  "Wallet address": "Wallet address",
  TAG: "TAG",
  mechanicalAuthentication: "Mechanical authentication:",
  cutOnTheDottedLine: "Cut on the dotted line",
  paymentLocation: "Payment location: Payable at any bank until the due date",
  documentDate: "Document date",
  onTheDocument: "On the document",
  docType: "Doc. Type",
  acceptance: "Acceptance",
  processingDate: "Processing date",
  portfolio: "Portfolio",
  lowerCode: "Lower code",
  mechanicalAuthenticationCompensationSlip:
    "Mechanical authentication Compensation Slip",
  endorserGuarantor: "Endorser/Guarantor",
  instructions:
    "Instructions (Text under the responsibility of the Beneficiary) Do not accept payment by check. Boleto with due date on the weekend. Transaction ID: {transID} If you have any issues with the purchase, access {email}",
  boletoDetailsTitle:
    'You can pay in banks, lotteries, post offices, supermarkets or online. You can also save the file now by clicking the "Save PDF" button below.',
  account: "Account",
  virtualAccountTitle: "Use your bank app to complete this transfer",
  bvn: "Bank verification number",
  bankName: "Bank name",
  cardHolder: "Cardholder",
  bankCode: "Bank code",
  accountNumber: "Account number",
  accountNumberCopied: "Account number copied",
  bankNameCopied: "Bank name copied",
  phoneCopied: "Phone copied",
  receiverNameCopied: "Receiver name copied",
  cardHolderCopied: "Cardholder copied",
  bankCodeCopied: "Bank code copied",
  accountNameCopied: "Account name copied",
  panCopied: "Card number copied",
  accountName: "Account name",
  remainingTime: "Time remaining",
  virtualAccountFooter:
    "You will get a confirmation once the payment is received",
  preSubmitInfo: "Press the button below to generate details for your payment",
  showDetails: "Show details",
  hideDetails: "Hide details",
  description: "Description",
  transactionIdCopy: "Transaction ID copied",
  amountCopy: "Amount copied",
  transactionFailed: "Transaction failed",
  userIdCopy: "User ID copied",
  nameCopy: "Name copied",
  name: "Name",
  depositInfoMainTitle:
    "Please find the steps how to make payment via this Deposit Method:",
  launchApp: "Launch the App",
  howToCompletePayment: "How to complete a payment?",
  openYourBankingAccount: "Open your banking account",
  chooseInteracAmount: "Choose Interac e-transfer and fill Amount",
  fillRecipEmailName: "Fill recipient email and name",
  completeSecret: "Complete secret question and secret answer",
  confirmPayment: "Confirm a payment",
  confirmInBankApp: "Now confirm your payment in your bank’s app",
  interacInfoTitle:
    "Attention! Please enter the exact amount and recipient's email. Otherwise, the funds won't be credited to your account.",
  select: "Select",
  pleaseSelectOption: "Please select an option",
  walletVegaInfoContent:
    "Your first deposit may fail due to security requirements (whitelisting). In this case, you will receive an email from Vega Wallet at your registered email address. Please confirm your account by clicking on the link in the email, then try making the deposit again.",
  walletVegaInfoContentEnding:
    " For more detailed information, please refer to the link below.",
  howToUseMustRead: "How to use (must read)",
  walletVegaFormFieldsTitle:
    "To proceed with the payment, please enter your authorization details:",
  userId: "User ID",
  userPassword: "Password",
  "validation.walletVegaUserId":
    "Should start with U and contain 6 symbols after it",
  "validation.walletVegaUserPassword": "Should contain from 2 to 32 symbols",
  enterYourIdentifier: "Enter your User Id",
  enterAmount: "Enter Amount",
  userNameTransactionAmount:
    "Your user name and transaction amount will be displayed on your screen to verify your details",
  selectPaymentMethod: "Select a payment method and click on 'Pay Now'",
  amountComplete15Mins:
    "The funds will be top up in 15 minutes after transaction complete.",
  iHaveMadePayment: "I have made a payment",
  secretQuestion: "Secret question",
  secretAnswer: "Secret Answer",
  recipientEmail: "Recipient email",
  recipientName: "Recipient name",
  answerCopy: "Answer copied",
  questionCopy: "Secret question copied",
  emailCopy: "Email copied",
  dial: "Dial",
  paymentNotificationUssd:
    "Proceed with your payment, notification will be received upon the success of your deposit",
  whereFindBlikCode: "Where can I find the BLIK code?",
  blikCodeInstructions: "Open your bank's mobile app and log in.",
  blikCodeOption: "Find the BLIK option in the Payments or Transfers section.",
  blikCodeGenerate:
    "Generate the 6-digit BLIK code, which is valid for a few minutes.",
  blikCodeEnter: "Enter and Submit the BLIK code.",
  blikCodeLabel: "BLIK code",
  blikCodeInvalid: "6 digits required",
  onYourMobileDevice: "on your mobile device",
  tryAgain: "Try again",
  downloadReceipt: "Download receipt",
  success: "Success",
  backToWebsite: "Back to website",
  scanQrTitlePix:
    "Open your payment APP and SCAN the QR code below to pay, or COPY the Pix code below and paste it into your payment app to complete the purchase.",
  boletoGenerated: "Boleto generated!",
  youWantDltCard: "Are you sure you want to delete card ?",
  dltCardSystemTitle:
    "The card data will be deleted from Saved Cards list, as well as from our system.",
  cancel: "Cancel",
  delete: "Delete",
  manageSavedCard: "Manage saved cards",
  savedCard: "Saved cards",
  transactionStatus: "Transaction status",
  paymentsSlowMessage:
    "Payments are currently slow at your bank. Slow payments are usually confirmed within 30 minutes.",
  receiptPaymentConfirmation:
    "You will get a receipt once your payment is confirmed",
  doNotRefreshPage: "Please do not refresh this page",
  madePayment: "I've made the payment",
  mins: "min",
  secs: "sec",
  confirmingTransfer: "We are confirming your transfer. This could take about",
  done: "Done",
  manage: "Manage",
  chooseLanguage: "Choose language",
  savePdf: "Save PDF",
  transactionIdTitle: "Transaction ID",
  nameOnCard: "Name on card",
  email: "Email",
  firstName: "First name",
  lastName: "Last name",
  orPayWithCard: "Or pay with card",
  dateTooltip:
    "The expiration date is located below the card number (e.g., 08/25)",
  middleName: "Middle name",
  checkPattern: "Check input data",
  "validation.birthday": "Must be in ISO format: YYYY-MM-DD",
  "validation.phone":
    "Enter phone number in international format: + and 10-15 digits.",
  "validation.phone_mobile_money": "Invalid phone number",
  "validation.pin": "Must be between 4 and 12 symbols",
  phone: "Phone",
  country: "Country",
  state_code: "State code",
  city: "City",
  address: "Address",
  zip_code: "Postal code",
  card_pin: "Card pin",
  processing_your: "Processing your",
  payment: "payment",
  incorrect_value: "Value is incorrect",
  selectAmountToPay: "Select 'Amount to pay' from the suggested list.",
  initiatedAmountDeclinedTitle: "About P2P transaction",
  initiatedAmountDeclined:
    "P2P transfer is a method of sending money that data will be exchanged directly between users. Therefore, it may not always available to process with the amount you entered. In such cases, please choose the amount closest to your preference from the options displayed below.",
  toCompleteThePaymentFollowSteps:
    "To complete the payment, please follow these steps:",
  clickSubmitToConfirm:
    "Click 'Submit' to confirm the selected payment amount.",
  afterSelectingAmount:
    "After selecting the amount, you will be redirected to the bank's page to complete the payment.",
  submit: "Submit",
  time_remaining: "Didn’t receive any OTP: ",
  mobileMoneyMainTitle: "Enter your Mobile Money to authorize this payment",
  mobileMoneySubTitle:
    "A request from operator has been sent to your phone, please enter your PIN to authorize this payment.",
  mobileMoneyInfoTitle:
    "You will get a confirmation once we receive your payment",
  resend_otp: "Resend",
  amountToPay: "Amount to pay",
  upi_qr_title_mob:
    'Scan QR code on your device or tap "OPEN APP" to quick complete your payment using UPI.',
  upi_qr_title_desk:
    "Scan QR code on your device to quick complete your payment using UPI.",
  choose_provider: "Choose provider",
  choose_mobile_operator: "Choose mobile operator",
  choose_currency: "Choose currency",
  choose_receiver_bank: "Choose receiver bank",
  receiverName: "Receiver name",
  bankAccountInfoTitle: "Please make a payment to following bank account",
  scanQRToTopUpWallet: "Scan QR to top up wallet",
  tryAnotherMethod: "Please try another payment method.",
  verification_code: "Verification code",
  number: "Number",
  scanQrTitleOpay: "Open your payment APP and SCAN the QR code below to pay",
  birthday: "Birthday",
  account_number: "Account number",
  bank_code: "Bank code",
  code: "Code",
  codeCopied: "Code copied",
  account_dob_day: "Account created date",
  account_dob_month: "Account created month",
  account_dob_year: "Account created year",
  pin: "Pin",
  search: "Search",
  dropdownEmpty: "Nothing was found. Try another query",
  chooseMethod: "Please choose payment method to begin payment",
  pleaseSend: "Please send only",
  byCopyCryptoData: "by copying the wallet address or scanning the QR code:",
  correctNetworkInfoTitle:
    "Ensure you're using the correct network and currency for this wallet. Any amount will be auto-converted to in-game currency.",
  mistakeLostCryptoFound: "Mistakes may result in lost funds",
  leftHpp: "Are you sure you want to cancel this payment?",
  noConnectionTryAgain:
    "Your internet connection is unstable. Please try again",
  selectedMethodError:
    "There was an error during method selection. Please try again",
  submitError: "There was an error during submit form. Please try again",
  removeCardError:
    "There was an error while removing the card. Please try again",
  deletedCard: "Card was deleted",
  redirectNotificationText:
    "Automatic redirection is blocked. Please click the button below to continue.",
  redirectNotificationButton: "Proceed to Payment",
  cancelPayment: "Return to the merchant",
  cancelPaymentText:
    "Something went wrong, the transaction is taking longer than usual to process. Please wait or return to the merchant",
  infoAboutPaymentMethodDefault:
    "Click the «Generate Payment Details» button below to proceed. You will be redirected to a page with the payment details and clear instructions for completing your payment using your preferred method.",
  buttonPayTextDefault: "Generate Payment Details",
  date_of_birth: "Date of birth",
  accountRequisitesTitleMakePayment:
    "Please find the steps how to make payment",
  accountRequisitesCopyAccountDetails: "Copy account details",
  accountRequisitesCopyAccountDetailsCopied: "Account details copied",
  accountRequisitesMakeTransfer:
    "Make a transfer by copied account details in the exact amount of",
  accountRequisitesPressButton: "Press the button",
  paymentConfirmationSubtitleMadePayment: "«I have made a payment»",
  accountRequisitesMadeTransfer: "The transfer can only be made once.",
  requisitesErrorTitle: "An error occurred while processing your request",
  requisitesErrorDescription:
    "Failed to retrieve payment information. Please go back and select a different payment method. We apologize for the inconvenience.",
  youPay: "You Pay",
  youGet: "You Get",
  enterInformation:
    "To proceed with the transaction, please enter the information below.",
  iAgree: "I agree to",
  termsOfService: "Terms of Services",
  and: "and",
  privacyPolicy: "Privacy Policy",
  of: "of",
  proceed: "Proceed",
  paymentSuccessfully: "Payment successfully",
  paymentInProgress: "Payment in Progress",
  paymentInProgressDescriptionStart:
    "If you've made the payment, don't worry! Your funds will be credited to your balance in approximately",
  minutes: "minutes",
  paymentInProgressDescriptionEnd: ", depending on network load.",
  cryptoTopup: "Top up",
  min: "min",
  paymentMinimumLimit: "Payments below the minimum limit won't be credited.",
  cryptoInfoTooltip: "The crypto amount equals",
  rate: "rate",
  networkFee: "network fee",
  cryptoCurrencyNonActiveTitle:
    "To proceed with your payment, please follow these steps:",
  cryptoCurrencyNonActiveFirstPoint:
    'Return to the payment options page by clicking the button "Back to methods".',
  cryptoCurrencyNonActiveSecondPoint:
    "Select an alternative cryptocurrency from the list of available options.",
  cryptoCurrencyNonActiveThirdPoint:
    "Confirm your new selection and retry the payment process.",
  cryptoCurrencyNonActiveDisclaimer:
    "The selected cryptocurrency is currently unavailable for transactions. Please choose another currency from the available options to proceed.",
};
