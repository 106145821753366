import Tether from "../assets/icons/crypto/Usdt.svg";
import Usdc from "../assets/icons/crypto/Usdc.svg";
import Btc from "../assets/icons/crypto/Btc.svg";
import Bch from "../assets/icons/crypto/Bch.svg";
import Ltc from "../assets/icons/crypto/Ltc.svg";
import Eth from "../assets/icons/crypto/Eth.svg";
import Matic from "../assets/icons/crypto/Matic.svg";
import Tron from "../assets/icons/crypto/Tron.svg";
import Doge from "../assets/icons/crypto/Doge.svg";

export const getCurrencyIcon = {
  btc: Btc,
  bch: Bch,
  ltc: Ltc,
  eth: Eth,
  matic: Matic,
  trx: Tron,
  doge: Doge,
  usdt: Tether,
  usdterc20: Tether,
  usdttrc20: Tether,
  usdtmatic: Tether,
  usdcerc20: Usdc,
  usdctrc20: Usdc,
  usdcmatic: Usdc,
  usdcxlm: Usdc,
  usdc: Usdc,
};

export const formatCryptoCurrency = (
  currency: string | undefined,
  withPrefix: boolean = false,
): string | undefined => {
  const currencyValue =
    typeof currency === "string" ? currency.trim() : currency;

  if (!currencyValue) return currencyValue;

  if (currencyValue.toUpperCase().startsWith("USDT")) {
    const cryptoCode = currencyValue.slice(4).trim();
    return withPrefix && cryptoCode ? `USDT (${cryptoCode})` : "USDT";
  }

  if (currencyValue.toUpperCase().startsWith("USDC")) {
    const cryptoCode = currencyValue.slice(4).trim();
    return withPrefix && cryptoCode ? `USDC (${cryptoCode})` : "USDC";
  }

  return currencyValue;
};
