import api from "./api";
import state from "./state";
import logger from "../services/logger";

let requestCount = 0;
const MAX_TRACK_REQUESTS = 10;
let startRequestTime = null;
const REQUESTS_TIME_LIMIT = 20000;

const track = async (event, data) => {
  if (!startRequestTime) {
    startRequestTime = new Date().getTime();
  }

  const CURRENT_TIME = new Date().getTime();
  const diffTimes = CURRENT_TIME - startRequestTime;

  if (diffTimes > REQUESTS_TIME_LIMIT) {
    return Promise.resolve().then(() => {
      startRequestTime = null;
      requestCount = 0;

      logger.log("Track error: limit time", {
        limit: diffTimes,
      });

      return {
        status: "error",
      };
    });
  } else if (requestCount <= MAX_TRACK_REQUESTS) {
    const result = await api
      .trackEvent(
        {
          event,
          ...data,
        },
        state.getSession(),
      )
      .then((res) => {
        startRequestTime = null;
        requestCount = 0;

        return res;
      })
      .catch((err) => {
        if (
          (err.response && err.response.status !== 200) ||
          err?.code === "ERR_NETWORK"
        ) {
          requestCount++;

          if (requestCount === MAX_TRACK_REQUESTS) {
            logger.log("Track error: limit requests", {
              err,
              limit: requestCount,
            });

            startRequestTime = null;
            requestCount = 0;

            return {
              status: "error",
              errorData: err,
            };
          }

          return track(event, data);
        }

        return err;
      });

    return result;
  }
};

export default {
  track,
};
