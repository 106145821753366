export default {
  pan: "Numer karty",
  exp: "Data ważności",
  "asquad.exp": "Data wygaśnięcia",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Zapłać",
  payPM: "Zasil",
  idTransTtl: "ID Transakcji",
  pendingTtl: "Płatność jest przetwarzana",
  "mono.pendingTtl": "Operacja w toku...",
  errorTtl: "Wystąpił błąd",
  "mono.errorTtl": "Przepraszamy, transakcja została odrzucona",
  successPaymentTtl: "Płatność zakończona sukcesem",
  "mono.successPaymentTtl": "Transakcja zakończona sukcesem",
  cvvHint: "Kod CVV znajduje się na odwrocie twojej karty",
  rememberMeHint:
    "Zapisujemy tylko numer karty i datę ważności dla przyszłych płatności na naszej stronie",
  "mono.rememberMeHint":
    "Zapisujemy tylko numer karty i datę ważności dla przyszłych płatności na naszej stronie",
  sslHintTitle: "Bezpieczne przesyłanie danych",
  sslHintDescription:
    "Używany jest bezpieczny protokół z 256-bitowym szyfrowaniem",
  required: "Pole wymagane",
  requiredDatePb: "Wprowadź datę ważności karty",
  requiredCvvPb: "Wprowadź kod CVV karty",
  invalidPanPb: "Wprowadź prawidłowy numer karty",
  invalidPan: "Nieprawidłowy numer karty",
  invalidDate: "Nieprawidłowa data",
  invalidCvv: "Nieprawidłowy CVV",
  invalidCardName: "Nieprawidłowe imię na karcie",
  expPlaceholder: "MM / RR",
  successPayment: "Płatność zakończona sukcesem",
  paymentId: "ID płatności: ",
  redirectNotice: "Zostaniesz przekierowany za: ",
  continue: "Kontynuuj",
  failedPayment: "Płatność nie powiodła się",
  backToMethods: "Powrót do metod",
  goBack: "Powrót do sklepu",
  retry: "Spróbuj ponownie",
  noConnection:
    "Twoje połączenie internetowe jest niestabilne. Ponowne łączenie...",
  reconnected: "Połączenie internetowe zostało przywrócone",
  intentionExpired: "Intencja płatnicza wygasła",
  badRequestTitle: "Nie możemy przetworzyć twojego żądania 😢",
  accessDenied: "Dostęp zabroniony ⛔",
  pageNotFound: "Strona nie została znaleziona lub nie istnieje 😢",
  responseTimeExpired: "Czas odpowiedzi wygasł ⌛",
  goBackAndRetry: "Wróć do strony kupca i spróbuj ponownie.",
  callMerchantOrGoBack:
    "Skontaktuj się z kupcem lub wróć do strony kupca i spróbuj ponownie.",
  unexpectedError:
    "Nie możemy dokończyć twojego żądania z powodu niespodziewanego błędu 😢",
  tryAgainLater: "Spróbuj ponownie później",
  noResponseFromBank: "Nie można przetworzyć twojej płatności.",
  tryTheFollowing: "Zalecamy ponowną próbę zapłaty lub użycie innej karty.",
  unableToProcessPayment: "Wystąpił błąd podczas twojej płatności.",
  useDifferentCard:
    "W przypadku ponownego błędu zalecamy kontakt z naszą obsługą klienta.",
  bankInternalErrorTitle:
    "Wystąpił błąd podczas przetwarzania twojej transakcji po stronie banku",
  transactionRejectedByBank: "Dostawca płatności odrzucił płatność.",
  contactBankForReason:
    "Skontaktuj się ze swoją instytucją finansową lub użyj innej metody.",
  bankInvalidCvvDesc: "Nieprawidłowy kod CVV.",
  invalidCardCredentialsTitle: "Nieprawidłowe dane karty.",
  checkCvvAndTryAgain:
    "Sprawdź kod CVV, można go znaleźć na odwrocie twojej karty.",
  doubleCheckCardCredential: "Sprawdź swoje dane lub użyj innej karty.",
  bankInvalidPanTitle: "Nieprawidłowy numer karty.",
  bankInvalidPanDescription: "Nieprawidłowy numer karty.",
  checkPanAndTryAgain: "Upewnij się, że wprowadziłeś poprawny numer karty",
  cardExpired: "Karta wygasła",
  unfortunatelyStatusBankCard:
    "Niestety, status twojej karty nie pozwala na dokonanie płatności.",
  useAnotherCardPayment: "Zalecamy użycie innej karty do zapłaty.",
  authorizationFailed: "Autoryzacja nie powiodła się.",
  anAuthorizeError: "Błąd autoryzacji.",
  pleasePaymentAgain: "Spróbuj ponownie lub użyj innej metody.",
  cardLostStolen:
    "Karta, którą wprowadziłeś, jest zgłoszona jako 'skradziona' lub 'zagubiona'.",
  bankCardExpiredTitle: "Nieprawidłowa data ważności karty.",
  checkCorrectExpirationDate:
    "Sprawdź, czy data ważności karty jest poprawna lub użyj ważnej karty.",
  insufficientFunds: "Niewystarczające środki.",
  useRecommendation: "Użyj poniższych wskazówek.",
  useAnotherCard: "Użyj innej karty.",
  rechargeYourCard: "Zasil kartę kwotą niezbędną do wykonania transakcji.",
  comeBackAndChangeAmount:
    "Wróć do etapu wyboru i określ kwotę dostępną na karcie.",
  onlinePaymentLimited:
    "Nie mogliśmy przetworzyć twojej transakcji, ponieważ przekroczono limit płatności online.",
  threeDSecureFailedTitle: "Autoryzacja nie powiodła się.",
  tryAgainWithCurrentCard: "Spróbuj ponownie z obecną kartą",
  cardAnother: "Użyj innej karty",
  cardSaved: "Zapisana karta",
  rememberMe: "Zapamiętaj mnie",
  rememberMePM: "Zapisz swoją kartę do przyszłych doładowań",
  bankLimitExceededTitle: "Przekroczono limit płatności online.",
  lastThreeDigits: "Ostatnie trzy cyfry na odwrocie karty kredytowej",
  sessionExpired: "Twoja sesja wygasła.",
  sessionExpiredDesc: "Wróć do strony kupca i spróbuj ponownie.",
  monthTitle: "Miesiąc",
  yearTitle: "Rok",
  sumTitle: "Suma",
  openThe: "Otwórz",
  pastePaymentCode: "Wklej kod, płatność kodem",
  howToUse: "Jak używać ",
  orderTittle: "Zamówienie",
  loadingTtl: "Proszę czekać",
  "pm1.loadingTtl": "Proszę czekać",
  "privat.loadingTtl": "Czekaj...",
  "mono.descriptionPaymentTtl": "Płatność za zamówienie",
  applePayTitle: "Płatność za pomocą",
  last3Digits: "Ostatnie 3 cyfry na odwrocie twojej karty",
  publicAgreementStart: 'Klikając przycisk "Zasil", akceptujesz warunki',
  publicAgreementLink: "oferty publicznej",
  publicAgreementEnd: "dotyczącej świadczenia usług",
  // NOWY TEKST
  HaveAQuestionAbout:
    'Masz pytanie dotyczące płatności? Kliknij tutaj i obejrzyj wideo "Jak to zrobić?.."',
  ApprovalHappensDuring:
    "Zatwierdzenie następuje w ciągu 1 godziny po płatności.",
  PaymentNotViaBankSlip:
    "Oznacza to, że płatność NIE odbywa się za pomocą przelewu bankowego.",
  PayInLotteryUsing: "Zapłać w Loterii, korzystając z poniższych danych:",
  ConventionCode: "Kod konwencji",
  CPFCNPJNumber: "Numer CPF/CNPJ",
  TotalDue: "Całkowita należność",
  amount: "Kwota",
  ProductDescription: "Opis produktu",
  SavePDF: "ZAPISZ PDF",
  Copy: "Kopiuj",
  Share: "Udostępnij",
  // PDF
  beneficiary: "Beneficjent",
  agencyBeneficiaryCode: "Kod agencji/beneficjenta",
  species: "Gatunek",
  quantity: "Ilość",
  payer_name: "Nazwa płatnika",
  paymentInstructions: "Instrukcje płatności",
  paymentSteps: "Poniżej znajdziesz kroki jak dokonać płatności:",
  copyAlias: "Kopiuj CBU/Alias",
  openBankApp: "Otwórz aplikację bankową",
  selectMoneyTransfer: "Wybierz 'Przelew pieniężny'",
  selectNewClient: "Wybierz 'Nowy klient'",
  pasteCopiedCbuCode: "Wklej skopiowany kod CBU/Alias",
  CBUCVUCopy: "Kopia CBU/CVU",
  aliasCopy: "Kopia aliasu",
  alias: "Alias",
  ourNumber: "Nasz numer",
  documentNumber: "Numer dokumentu",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "Termin płatności",
  documentValue: "Wartość dokumentu",
  discountsAllowances: "Rabaty/Zniesienia",
  otherDeductions: "Inne potrącenia",
  interestPenalty: "Odsetki/Kara",
  otherAdditions: "Inne dodatki",
  amountCharged: "Pobrana kwota",
  send: "Wyślij",
  "Amount copied": "Kwota skopiowana",
  topUpAmount: "Kwota doładowania",
  receive: "Odbierz",
  payer: "Płatnik",
  mechanicalAuthentication: "Autoryzacja mechaniczna:",
  cutOnTheDottedLine: "Przeciąć wzdłuż linii przerywanej",
  paymentLocation: "Miejsce płatności: Do zapłaty w dowolnym banku do terminu",
  documentDate: "Data dokumentu",
  onTheDocument: "Na dokumencie",
  docType: "Typ dokumentu",
  acceptance: "Akceptacja",
  processingDate: "Data przetwarzania",
  portfolio: "Portfel",
  lowerCode: "Niższy kod",
  mechanicalAuthenticationCompensationSlip:
    "Mechaniczna autoryzacja Wypis kompensacyjny",
  endorserGuarantor: "Gwarant/Podpisujący",
  instructions:
    "Instrukcje (Tekst pod odpowiedzialnością Beneficjenta) Nie akceptuj płatności czekiem. Boleto z terminem na weekend. ID transakcji: {transID} Jeśli masz jakiekolwiek problemy z zakupem, skontaktuj się pod adresem {email}",
  boletoDetailsTitle:
    'Możesz zapłacić w bankach, loteriach, pocztach, supermarketach lub online. Możesz również zapisać plik teraz, klikając przycisk "Zapisz PDF" poniżej.',
  account: "Konto",
  virtualAccountTitle: "Użyj aplikacji bankowej, aby dokończyć ten przelew",
  bvn: "Numer weryfikacyjny banku",
  bankName: "Nazwa banku",
  cardHolder: "Posiadacz karty",
  bankCode: "Kod banku",
  accountNumber: "Numer konta",
  accountNumberCopied: "Numer konta skopiowany",
  bankNameCopied: "Nazwa banku skopiowana",
  phoneCopied: "Telefon skopiowany",
  receiverNameCopied: "Nazwa odbiorcy skopiowana",
  cardHolderCopied: "Posiadacz karty skopiowany",
  bankCodeCopied: "Kod banku skopiowany",
  accountNameCopied: "Nazwa konta skopiowana",
  panCopied: "Numer karty skopiowany",
  accountName: "Nazwa konta",
  remainingTime: "Pozostały czas",
  virtualAccountFooter:
    "Otrzymasz potwierdzenie, gdy płatność zostanie odebrana",
  preSubmitInfo:
    "Naciśnij przycisk poniżej, aby wygenerować szczegóły płatności",
  showDetails: "Pokaż szczegóły",
  hideDetails: "Ukryj szczegóły",
  description: "Opis",
  transactionIdCopy: "ID transakcji skopiowane",
  amountCopy: "Kwota skopiowana",
  transactionFailed: "Transakcja nie powiodła się",
  userIdCopy: "Identyfikator użytkownika skopiowany",
  nameCopy: "Nazwa skopiowana",
  name: "Nazwa",
  depositInfoMainTitle:
    "Poniżej znajdziesz kroki jak dokonać płatności za pomocą tej metody depozytu:",
  launchApp: "Uruchom aplikację",
  howToCompletePayment: "Jak dokończyć płatność?",
  openYourBankingAccount: "Otwórz swoje konto bankowe",
  chooseInteracAmount: "Wybierz przelew Interac i wprowadź kwotę",
  fillRecipEmailName: "Wprowadź email i nazwę odbiorcy",
  completeSecret: "Uzupełnij pytanie sekretne i odpowiedź",
  confirmPayment: "Potwierdź płatność",
  confirmInBankApp: "Teraz potwierdź swoją płatność w aplikacji swojego banku",
  interacInfoTitle:
    "Uwaga! Proszę wprowadzić dokładną kwotę i email odbiorcy. W przeciwnym razie środki nie zostaną zaksięgowane na twoim koncie.",
  select: "Wybierz",
  pleaseSelectOption: "Proszę wybrać opcję",
  walletVegaInfoContent:
    "Twój pierwszy depozyt może się nie powieść z powodu wymagań bezpieczeństwa (białej listy). W takim przypadku otrzymasz e-mail od Vega Wallet na swój zarejestrowany adres e-mail. Proszę potwierdzić swoje konto, klikając link w e-mailu, a następnie spróbuj ponownie dokonać depozytu.",
  walletVegaInfoContentEnding:
    " Aby uzyskać bardziej szczegółowe informacje, proszę odnieść się do poniższego linku.",
  howToUseMustRead: "Jak używać (koniecznie przeczytaj)",
  walletVegaFormFieldsTitle:
    "Aby kontynuować płatność, wprowadź swoje dane autoryzacyjne:",
  userId: "ID użytkownika",
  userPassword: "Hasło",
  "validation.walletVegaUserId":
    "Powinno zaczynać się od U i zawierać 6 symboli po nim",
  "validation.walletVegaUserPassword": "Powinno zawierać od 2 do 32 symboli",
  enterYourIdentifier: "Wprowadź swój identyfikator użytkownika",
  enterAmount: "Wprowadź kwotę",
  userNameTransactionAmount:
    "Twoja nazwa użytkownika i kwota transakcji będą wyświetlane na ekranie w celu weryfikacji twoich danych",
  selectPaymentMethod: "Wybierz metodę płatności i kliknij 'Zapłać teraz'",
  amountComplete15Mins:
    "Środki zostaną doładowane w ciągu 15 minut po zakończeniu transakcji.",
  iHaveMadePayment: "Dokonałem płatności",
  secretQuestion: "Pytanie sekretne",
  secretAnswer: "Odpowiedź sekretna",
  recipientEmail: "Email odbiorcy",
  recipientName: "Nazwa odbiorcy",
  answerCopy: "Odpowiedź skopiowana",
  questionCopy: "Pytanie sekretne skopiowane",
  emailCopy: "Email skopiowany",
  dial: "Numer",
  paymentNotificationUssd:
    "Kontynuuj płatność, powiadomienie zostanie wysłane po pomyślnym doładowaniu",
  whereFindBlikCode: "Gdzie mogę znaleźć kod BLIK?",
  blikCodeInstructions: "Otwórz aplikację mobilną swojego banku i zaloguj się.",
  blikCodeOption: "Znajdź opcję BLIK w sekcji Płatności lub Przelewy.",
  blikCodeGenerate:
    "Wygeneruj 6-cyfrowy kod BLIK, który jest ważny przez kilka minut.",
  blikCodeEnter: "Wprowadź i zatwierdź kod BLIK.",
  blikCodeLabel: "Kod BLIK",
  blikCodeInvalid: "Wymagane 6 cyfr",
  onYourMobileDevice: "na twoim urządzeniu mobilnym",
  tryAgain: "Spróbuj ponownie",
  downloadReceipt: "Pobierz potwierdzenie",
  paymentSuccessfully: "Płatność przetworzona \n pomyślnie",
  scanQrTitlePix:
    "Otwórz swoją aplikację płatności i ZESKANUJ poniższy kod QR, aby zapłacić, lub SKOPIUJ poniższy kod Pix i wklej go w swojej aplikacji płatności, aby dokończyć zakup.",
  boletoGenerated: "Boleto wygenerowane!",
  youWantDltCard: "Czy na pewno chcesz usunąć kartę?",
  dltCardSystemTitle:
    "Dane karty zostaną usunięte z listy zapisanych kart oraz z naszego systemu.",
  cancel: "Anuluj",
  delete: "Usuń",
  manageSavedCard: "Zarządzaj zapisanymi kartami",
  savedCard: "Zapisane karty",
  transactionStatus: "Status transakcji",
  paymentsSlowMessage:
    "Płatności są obecnie wolne w twoim banku. Wolne płatności zazwyczaj są potwierdzane w ciągu 30 minut.",
  receiptPaymentConfirmation:
    "Otrzymasz potwierdzenie, gdy twoja płatność zostanie potwierdzona",
  doNotRefreshPage: "Proszę nie odświeżać tej strony",
  madePayment: "Dokonałem płatności",
  mins: "min",
  secs: "sek",
  confirmingTransfer: "Potwierdzamy twój przelew. Może to potrwać około",
  done: "Gotowe",
  manage: "Zarządzaj",
  chooseLanguage: "Wybierz język",
  savePdf: "Zapisz PDF",
  transactionIdTitle: "ID transakcji",
  nameOnCard: "Imię na karcie",
  email: "Email",
  firstName: "Imię",
  lastName: "Nazwisko",
  orPayWithCard: "Lub zapłać kartą",
  dateTooltip: "Data ważności znajduje się poniżej numeru karty (np. 08/25)",
  middleName: "Drugie imię",
  checkPattern: "Sprawdź dane wejściowe",
  "validation.birthday": "Musi być w formacie ISO: YYYY-MM-DD",
  "validation.phone":
    "Wprowadź numer telefonu w formacie międzynarodowym: + oraz 10-15 cyfr.",
  "validation.phone_mobile_money": "Nieprawidłowy numer telefonu",
  "validation.pin": "Musi mieć od 4 do 12 znaków",
  phone: "Telefon",
  country: "Kraj",
  state_code: "Kod stanu",
  city: "Miasto",
  address: "Adres",
  zip_code: "Kod pocztowy",
  card_pin: "PIN karty",
  processing_your: "Przetwarzanie twojej",
  payment: "płatności",
  incorrect_value: "Nieprawidłowa wartość",
  selectAmountToPay: "Wybierz 'Kwotę do zapłaty' z sugerowanej listy.",
  initiatedAmountDeclinedTitle: "O transakcji P2P",
  initiatedAmountDeclined:
    "Przelew P2P to metoda wysyłania pieniędzy, w której dane są wymieniane bezpośrednio między użytkownikami. Dlatego nie zawsze jest możliwe przetwarzanie kwoty, którą wprowadziłeś. W takich przypadkach wybierz kwotę najbliższą twoim preferencjom spośród opcji wyświetlonych poniżej.",
  toCompleteThePaymentFollowSteps:
    "Aby dokończyć płatność, wykonaj następujące kroki:",
  clickSubmitToConfirm:
    "Kliknij 'Wyślij', aby potwierdzić wybraną kwotę płatności.",
  afterSelectingAmount:
    "Po wybraniu kwoty zostaniesz przekierowany na stronę banku, aby dokończyć płatność.",
  submit: "Wyślij",
  time_remaining: "Nie otrzymałeś żadnego OTP: ",
  mobileMoneyMainTitle:
    "Wprowadź swoją Mobilną Płatność, aby autoryzować tę płatność",
  mobileMoneySubTitle:
    "Wysłano żądanie od operatora na twój telefon, wprowadź swój PIN, aby autoryzować tę płatność.",
  mobileMoneyInfoTitle: "Otrzymasz potwierdzenie, gdy otrzymamy twoją płatność",
  resend_otp: "Wyślij ponownie",
  amountToPay: "Kwota do zapłaty",
  upi_qr_title_mob:
    'Zeskanuj kod QR na swoim urządzeniu lub stuknij "OTWÓRZ APLIKACJĘ", aby szybko dokończyć płatność za pomocą UPI.',
  upi_qr_title_desk:
    "Zeskanuj kod QR na swoim urządzeniu, aby szybko dokończyć płatność za pomocą UPI.",
  choose_provider: "Wybierz dostawcę",
  choose_mobile_operator: "Wybierz operator mobilny",
  choose_currency: "Wybierz walutę",
  choose_receiver_bank: "Wybierz bank odbiorcy",
  receiverName: "Nazwa odbiorcy",
  bankAccountInfoTitle: "Proszę dokonać płatności na poniższe konto bankowe",
  scanQRToTopUpWallet: "Zeskanuj QR, aby doładować portfel",
  tryAnotherMethod: "Proszę spróbować innej metody płatności.",
  verification_code: "Kod weryfikacyjny",
  number: "Numer",
  scanQrTitleOpay:
    "Otwórz swoją aplikację płatności i ZESKANUJ poniższy kod QR, aby zapłacić",
  birthday: "Data urodzenia",
  account_number: "Numer konta",
  bank_code: "Kod banku",
  code: "Kod",
  codeCopied: "Kod skopiowany",
  account_dob_day: "Data utworzenia konta - dzień",
  account_dob_month: "Data utworzenia konta - miesiąc",
  account_dob_year: "Data utworzenia konta - rok",
  pin: "PIN",
  search: "Szukaj",
  dropdownEmpty: "Nic nie znaleziono. Spróbuj innego zapytania",
  chooseMethod: "Proszę wybrać metodę płatności, aby rozpocząć płatność",
  leftHpp: "Czy na pewno chcesz anulować tę płatność?",
  noConnectionTryAgain:
    "Twoje połączenie internetowe jest niestabilne. Proszę spróbować ponownie",
  selectedMethodError:
    "Wystąpił błąd podczas wyboru metody. Proszę spróbować ponownie",
  submitError:
    "Wystąpił błąd podczas wysyłania formularza. Proszę spróbować ponownie",
  "Wallet address": "Adres portfela",
  TAG: "TAG",
  pleaseSend: "Proszę wysłać",
  byCopyCryptoData: "kopiując adres portfela lub skanując kod QR:",
  correctNetworkInfoTitle:
    "Upewnij się, że używasz poprawnej sieci i waluty dla tego portfela. Każda kwota zostanie automatycznie przeliczona na walutę w grze.",
  mistakeLostCryptoFound: "Błędy mogą prowadzić do utraty środków.",
  removeCardError:
    "Wystąpił błąd podczas usuwania karty. Proszę spróbować ponownie",
  deletedCard: "Karta została usunięta",
  redirectNotificationText:
    "Automatyczne przekierowanie jest zablokowane. Proszę kliknąć przycisk poniżej, aby kontynuować.",
  redirectNotificationButton: "Przejdź do płatności",
  cancelPayment: "Wróć do sprzedawcy",
  cancelPaymentText:
    "Coś poszło nie tak, transakcja zajmuje więcej czasu niż zwykle. Proszę poczekać lub wrócić do sprzedawcy.",
  infoAboutPaymentMethodDefault:
    "Kliknij przycisk „Generuj szczegóły płatności” poniżej, aby kontynuować. Zostaniesz przekierowany na stronę ze szczegółami płatności i jasnymi instrukcjami dotyczącymi dokończenia płatności za pomocą wybranej metody.",
  buttonPayTextDefault: "Generuj szczegóły płatności",
  date_of_birth: "Data urodzenia",
  accountRequisitesTitleMakePayment: "Znajdź kroki, jak dokonać płatności:",
  accountRequisitesCopyAccountDetails: "Skopiuj dane konta",
  accountRequisitesCopyAccountDetailsCopied: "Dane konta zostały skopiowane",
  accountRequisitesMakeTransfer:
    "Wykonaj przelew za pomocą skopiowanych danych konta na dokładną kwotę",
  accountRequisitesPressButton: "Naciśnij przycisk",
  paymentConfirmationSubtitleMadePayment: "«Dokonałem płatności»",
  accountRequisitesMadeTransfer: "Przelew można wykonać tylko raz.",
  requisitesErrorTitle: "Wystąpił błąd podczas przetwarzania Twojego żądania",
  requisitesErrorDescription:
    "Nie udało się pobrać informacji o płatności. Wróć i wybierz inną metodę płatności. Przepraszamy za niedogodności.",
  youPay: "Płacisz",
  youGet: "Otrzymujesz",
  enterInformation: "Aby kontynuować transakcję, wprowadź poniższe informacje.",
  iAgree: "Zgadzam się na",
  termsOfService: "Warunki świadczenia usług",
  and: "i",
  privacyPolicy: "Politykę prywatności",
  of: "od",
  proceed: "Kontynuuj",
  paymentInProgress: "Płatność w toku",
  paymentInProgressDescriptionStart:
    "Jeśli dokonałeś płatności, nie martw się! Twoje środki zostaną zaksięgowane na twoim saldzie za około",
  minutes: "minut",
  paymentInProgressDescriptionEnd: ", w zależności od obciążenia sieci.",
  cryptoTopup: "Doładuj",
  min: "min",
  paymentMinimumLimit:
    "Płatności poniżej minimalnego limitu nie zostaną zaksięgowane.",
  cryptoInfoTooltip: "Kwota kryptowaluty równa się",
  rate: "kursowi",
  networkFee: "opłacie sieciowej",
  cryptoCurrencyNonActiveTitle:
    "Aby kontynuować płatność, wykonaj następujące kroki:",
  cryptoCurrencyNonActiveFirstPoint:
    'Wróć do strony wyboru metod płatności, klikając przycisk "Powrót do metod".',
  cryptoCurrencyNonActiveSecondPoint:
    "Wybierz alternatywną kryptowalutę ze listy dostępnych opcji.",
  cryptoCurrencyNonActiveThirdPoint:
    "Potwierdź swój nowy wybór i ponów proces płatności.",
  cryptoCurrencyNonActiveDisclaimer:
    "Wybrana kryptowaluta jest obecnie niedostępna do transakcji. Proszę wybrać inną walutę z dostępnych opcji, aby kontynuować.",
  backToWebsite: "Powrót do strony",
};
