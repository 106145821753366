import api from "../services/api";
import logger from "../services/logger";
import state from "../services/state";

const onGooglePayButtonClick = ({ paymentRequest }) => {
  if (!paymentRequest) {
    logger.error("Google pay: no paymentRequest", {
      paymentRequest,
    });

    return;
  }

  state.redirectToPending();

  api
    .sendGooglePaymentToken(paymentRequest, state.getSession())
    .catch((error) => {
      logger.error(error);

      api.getHttpErrorMessage(error);
    });
};

export default {
  onGooglePayButtonClick,
};
