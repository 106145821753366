import EventEmitter from "events";

let hasConnection = true;

if (typeof window !== "undefined") {
  hasConnection = window?.navigator?.onLine;
}

const events = new EventEmitter();

export const getConnectionStatus = () => {
  return hasConnection;
};

export const setConnectionStatus = (status) => {
  hasConnection = status;

  events.emit("connectionUpdated", hasConnection);
};

export default {
  events,
  getConnectionStatus,
  setConnectionStatus,
};
