import api from "../services/api";
import logger from "../services/logger";
import state from "../services/state";
import testData from "./testData";

const APPLE_PAY_SDK_URL =
  "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
const APPLE_PAY_REQUIRED_VERSION = 4;

let applePaySession = null;

// Function to dynamically load the Apple Pay SDK
async function loadApplePaySDK() {
  const scriptId = "apple-pay-sdk";

  if (document.getElementById(scriptId)) {
    return;
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.id = scriptId;
    script.src = APPLE_PAY_SDK_URL;

    script.onload = () => resolve();

    script.onerror = () =>
      reject(new Error("Failed to load the Apple Pay SDK"));

    document.head.appendChild(script);
  });
}

async function initializeApplePay() {
  try {
    await loadApplePaySDK();
    logger.log("Apple Pay SDK loaded successfully.");
  } catch (error) {
    logger.error("Apple Pay SDK loading error:", error);
  }
}

const getMerchantCapabilities = (identity) => {
  const merchantCapabilitiesMap = {
    enabled_3ds: "supports3DS",
    enabled_credit_card: "supportsCredit",
    enabled_debit_card: "supportsDebit",
    enabled_emv: "supportsEMV",
  };

  return Object.keys(merchantCapabilitiesMap).reduce((acc, value) => {
    if (identity[value]) {
      acc.push(merchantCapabilitiesMap[value]);
    }

    return acc;
  }, []);
};

const getSupportedNetworks = (supportedNetworks) => {
  const supportedNetworksMap = {
    mastercard: "masterCard",
    visa: "visa",
    amex: "amex",
    discover: "discover",
    jcb: "jcb",
    unionpay: "chinaUnionPay",
    maestro: "maestro",
  };

  return supportedNetworks
    .map((key) => supportedNetworksMap[key])
    .filter((value) => !!value);
};

const validateMerchant = async (validationURL) => {
  try {
    const res = await api.validateAppleMerchant(
      { validation_url: validationURL, pixel: state.getPixelData() },
      state.getSession(),
    );

    logger.log("Apple pay button: validate merchant", { validationURL, res });

    return res.data;
  } catch (err) {
    logger.error(err);
  }
};

const completeMerchantValidation = (merchantSession) => {
  if (!applePaySession || !applePaySession.completeMerchantValidation) {
    logger.warn(
      "Apple pay error: apple pay session missed trying complete merchant validation",
      { merchantSession },
    );

    return;
  }

  logger.log("Apple pay: completeMerchantValidation called", {
    merchantSession,
  });

  try {
    applePaySession.completeMerchantValidation(merchantSession);
  } catch (err) {
    logger.log("Apple pay: completeMerchantValidation failed", err);
  }
};

const completePayment = ({ isSuccess, error }) => {
  if (!applePaySession) {
    logger.log("Apple pay: apple pay session missed trying complete payment", {
      isSuccess,
      error,
    });

    return;
  }

  const completePaymentPayload = {
    status: window.ApplePaySession.STATUS_SUCCESS,
  };

  if (error) {
    completePaymentPayload.error = error;
  }

  try {
    logger.log("Apple pay: complete payment", { completePaymentPayload });

    applePaySession.completePayment(completePaymentPayload);
  } catch (error) {
    logger.log("Apple pay: complete payment failed", {
      completePaymentPayload,
      error,
    });

    try {
      applePaySession.abort();
    } catch (error) {
      logger.error(error);
    }

    logger.error(error);
  }
};

const isIframe = testData.isIframe;

const isAllowedToShow = () => {
  if (isIframe()) {
    return false;
  }

  const supportsVersion =
    window.ApplePaySession &&
    window.ApplePaySession.supportsVersion(APPLE_PAY_REQUIRED_VERSION);
  const canMakePayments =
    window.ApplePaySession && window.ApplePaySession.canMakePayments();

  // if (window.ApplePaySession && supportsVersion && canMakePayments) {
  //   window.ApplePaySession.canMakePaymentsWithActiveCard(merchant_identifier).then(
  //     (canPay) => {
  //       setIsAllowedToShow(canPay);
  //     }
  //   );
  // }

  logger.log("Apple pay availability check: ", {
    supportsVersion: window.ApplePaySession ? supportsVersion : false,
    canMakePayments: window.ApplePaySession ? canMakePayments : false,
  });

  return window.ApplePaySession && supportsVersion && canMakePayments;
};

const onApplePayButtonClick = ({
  currency,
  identity,
  description,
  reference,
  amount,
}) => {
  if (!window.ApplePaySession) {
    return;
  }

  const { country, supported_countries, supported_networks } = identity;

  const request = {
    currencyCode: currency,
    countryCode: country,
    merchantCapabilities: getMerchantCapabilities(identity),
    supportedNetworks: getSupportedNetworks(supported_networks),
    total: {
      label: description || `Order: ${reference}`,
      type: "final",
      amount,
    },
  };

  if (
    supported_countries &&
    Array.isArray(supported_countries) &&
    supported_countries.length
  ) {
    request.supportedCountries = supported_countries;
  }

  // Create ApplePaySession
  applePaySession = new window.ApplePaySession(
    APPLE_PAY_REQUIRED_VERSION,
    request,
  );

  logger.log("Apple pay: new ApplePaySession created", { request });

  applePaySession.onvalidatemerchant = async (event) => {
    logger.log("Apple payment event session.onvalidatemerchant: ", event);

    await validateMerchant(event.validationURL);

    state.clearAppleMerchantSession();
  };

  applePaySession.onpaymentmethodselected = (event) => {
    const update = {
      newTotal: request.total,
    };

    logger.log("Apple pay: onpaymentmethodselected event: ", event);

    applePaySession.completePaymentMethodSelection(update);
  };

  applePaySession.onshippingmethodselected = () => {
    // Define ApplePayShippingMethodUpdate based on the selected shipping method.
    // No updates or errors are needed, pass an empty object.
    const update = {};
    applePaySession.completeShippingMethodSelection(update);
  };

  applePaySession.onshippingcontactselected = () => {
    // Define ApplePayShippingContactUpdate based on the selected shipping contact.
    const update = {};
    applePaySession.completeShippingContactSelection(update);
  };

  applePaySession.onpaymentauthorized = (event) => {
    logger.log("Apple payment event session.onpaymentauthorized: ", event);

    state.redirectToPending();

    api
      .sendApplePaymentToken({ ...event.payment }, state.getSession())
      .then(state.whenPaymentResolved)
      .then((isSuccess) => {
        logger.log("Apple pay: whenPaymentResolved then", { isSuccess });
        completePayment({ isSuccess });
      })
      .catch((error) => {
        logger.log("Apple pay: whenPaymentResolved catch", { error });

        completePayment({ isSuccess: false, error });
      });
  };

  applePaySession.oncancel = (event) => {
    logger.log("Apple payment event session.oncancel: ", event);
  };

  try {
    applePaySession.begin();
  } catch (err) {
    logger.error(err);
  }
};

export default {
  isAllowedToShow,
  onApplePayButtonClick,
  completeMerchantValidation,
  session: applePaySession,
  initializeApplePay,
};
