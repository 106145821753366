const MobileMoneyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 23C6.45 23 5.97917 22.8042 5.5875 22.4125C5.19583 22.0208 5 21.55 5 21V3C5 2.45 5.19583 1.97917 5.5875 1.5875C5.97917 1.19583 6.45 1 7 1H17C17.55 1 18.0208 1.19583 18.4125 1.5875C18.8042 1.97917 19 2.45 19 3V21C19 21.55 18.8042 22.0208 18.4125 22.4125C18.0208 22.8042 17.55 23 17 23H7ZM7 18H17V6H7V18Z"
      fill="#0062EB"
    />
    <path
      d="M12.0149 16.1067C11.8689 16.1067 11.7448 16.0574 11.6426 15.9588C11.5403 15.8603 11.4892 15.738 11.4892 15.592V15.132C11.1388 15.0517 10.834 14.9112 10.5748 14.7104C10.3156 14.5096 10.1057 14.2413 9.94512 13.9055C9.87942 13.7595 9.88307 13.608 9.95608 13.451C10.0291 13.2941 10.1495 13.1864 10.3175 13.128C10.4562 13.0696 10.5967 13.0732 10.7391 13.1389C10.8814 13.2046 11.0037 13.3141 11.1059 13.4675C11.2228 13.6427 11.3651 13.7741 11.533 13.8617C11.701 13.9493 11.8908 13.9931 12.1025 13.9931C12.3799 13.9931 12.599 13.942 12.7596 13.8398C12.9202 13.7376 13.0005 13.577 13.0005 13.3579C13.0005 13.1535 12.9165 12.9783 12.7486 12.8323C12.5807 12.6863 12.212 12.5293 11.6426 12.3614C11.0439 12.1789 10.6204 11.9343 10.3722 11.6277C10.124 11.321 9.99988 10.956 9.99988 10.5325C9.99988 10.0872 10.1441 9.71486 10.4325 9.41552C10.7208 9.11619 11.0731 8.92637 11.4892 8.84606V8.40802C11.4892 8.262 11.5403 8.13971 11.6426 8.04115C11.7448 7.94259 11.8689 7.89331 12.0149 7.89331C12.1682 7.89331 12.2923 7.94259 12.3872 8.04115C12.4821 8.13971 12.5296 8.262 12.5296 8.40802V8.84606C12.807 8.91177 13.0534 9.01216 13.2688 9.14722C13.4842 9.28228 13.6649 9.45568 13.8109 9.6674C13.9058 9.79881 13.9222 9.94666 13.8602 10.1109C13.7981 10.2752 13.6795 10.3938 13.5043 10.4668C13.3874 10.5179 13.2597 10.5216 13.121 10.4778C12.9822 10.434 12.8289 10.3464 12.661 10.215C12.5807 10.142 12.484 10.0854 12.3708 10.0452C12.2576 10.0051 12.1281 9.98498 11.982 9.98498C11.7119 9.98498 11.5166 10.0343 11.3962 10.1328C11.2757 10.2314 11.2155 10.3537 11.2155 10.4997C11.2155 10.6895 11.2976 10.8428 11.4619 10.9596C11.6261 11.0765 11.993 11.2188 12.5625 11.3867C13.0954 11.5474 13.5043 11.7956 13.789 12.1314C14.0737 12.4672 14.2161 12.8688 14.2161 13.336C14.2161 13.8836 14.0555 14.3034 13.7342 14.5954C13.413 14.8875 13.0115 15.0736 12.5296 15.1539V15.592C12.5296 15.738 12.4803 15.8603 12.3818 15.9588C12.2832 16.0574 12.1609 16.1067 12.0149 16.1067Z"
      fill="#0062EB"
    />
  </svg>
);
export default MobileMoneyIcon;
