import accessBankIcon from "../assets/icons/banks/access_bank.svg";
import asosavingsLoanIcon from "../assets/icons/banks/asosavings_loan.svg";
import nigeriaIntlBankIcon from "../assets/icons/banks/nigeria_intl_bank.svg";
import diamondBankIcon from "../assets/icons/banks/diamond_bank.svg";
import ecobankNigeriaIcon from "../assets/icons/banks/ecobank_nigeria.png";
import enterpriseBankIcon from "../assets/icons/banks/enterprise_bank.png";
import firstCityMonumentBankIcon from "../assets/icons/banks/first_city_monument_bank.svg";
import fidelityBankIcon from "../assets/icons/banks/fidelity_bank.svg";
import firstBankOfNigeriaIcon from "../assets/icons/banks/first_bank_of_nigeria.svg";
import guarantyTrustBankIcon from "../assets/icons/banks/guaranty_trust_bank.svg";
import heritageBankingIcon from "../assets/icons/banks/heritage_banking.svg";
import jaizBankIcon from "../assets/icons/banks/jaiz_bank.svg";
import keystoneBankIcon from "../assets/icons/banks/keystone_bank.png";
import parkwayBankIcon from "../assets/icons/banks/parkway_bank.svg";
import polarisBankIcon from "../assets/icons/banks/polaris_bank.png";
import stanbicIbtcBankIcon from "../assets/icons/banks/stanbic-ibtc_bank.png";
import standardCharteredBankIcon from "../assets/icons/banks/standard_chartered_bank.svg";
import sterlingBankIcon from "../assets/icons/banks/sterling_bank.png";
import unitedBankForAfricaIcon from "../assets/icons/banks/united_bank_for_africa.svg";
import unionBankOfNigeriaIcon from "../assets/icons/banks/union_bank_of_nigeria.svg";
import unityBankIcon from "../assets/icons/banks/unity_bank.png";
import zenithBankIcon from "../assets/icons/banks/zenith_bank.svg";
import vfdBankIcon from "../assets/icons/banks/vfdBankIcon.png";
import bankTransferIcon from "../assets/icons/banks/bankTransferIcon.svg";
import anindaHavaleIcon from "../assets/icons/banks/anindaHavaleIcon.png";
import vipHavaleIcon from "../assets/icons/banks/vipHavaleIcon.png";
import cepbankIcon from "../assets/icons/banks/cepbankIcon.png";
import expressHavaleBankIcon from "../assets/icons/banks/expressHavale.png";
import rbkBank from "../assets/icons/banks/rbkBank.svg";
import kazpostBank from "../assets/icons/banks/kazpostBank.png";
import freedomBank from "../assets/icons/banks/freedomBank.svg";
import berekeBank from "../assets/icons/banks/berekeBank.svg";
import halykBank from "../assets/icons/banks/halykBank.svg";
import kaspiBank from "../assets/icons/banks/kaspiBank.svg";
import eurasianBank from "../assets/icons/banks/eurasianBank.png";
import homeCreditBank from "../assets/icons/banks/homeCreditBank.svg";
import centerCreditBank from "../assets/icons/banks/centerCreditBank.svg";
import jusanBank from "../assets/icons/banks/jusanBank.png";
import altynBank from "../assets/icons/banks/altynBank.png";
import forteBank from "../assets/icons/banks/forteBank.svg";
import sberBank from "../assets/icons/banks/sberKzBank.png";
import spmBank from "../assets/icons/banks/spmBank.png";
import itauBankIcon from "../assets/icons/banks/itauIcon.png";
import scotiabankIcon from "../assets/icons/banks/scotiabankIcon.svg";

export const BANK_ICONS = {
  access_bank: accessBankIcon,
  "asosavings_&_loan": asosavingsLoanIcon,
  "nigeria_int'l_bank": nigeriaIntlBankIcon,
  diamond_bank: diamondBankIcon,
  ecobank_nigeria: ecobankNigeriaIcon,
  enterprise_bank: enterpriseBankIcon,
  first_city_monument_bank: firstCityMonumentBankIcon,
  fidelity_bank: fidelityBankIcon,
  first_bank_of_nigeria: firstBankOfNigeriaIcon,
  guaranty_trust_bank: guarantyTrustBankIcon,
  heritage_banking: heritageBankingIcon,
  scotiabank_empresas: scotiabankIcon,
  itau_empresas: itauBankIcon,
  jaiz_bank: jaizBankIcon,
  keystone_bank: keystoneBankIcon,
  parkway_bank: parkwayBankIcon,
  polaris_bank: polarisBankIcon,
  "stanbic-ibtc_bank": stanbicIbtcBankIcon,
  standard_chartered_bank: standardCharteredBankIcon,
  sterling_bank: sterlingBankIcon,
  united_bank_for_africa: unitedBankForAfricaIcon,
  union_bank_of_nigeria: unionBankOfNigeriaIcon,
  unity_bank: unityBankIcon,
  zenith_bank: zenithBankIcon,
  vfd: vfdBankIcon,
  300: spmBank,
  301: sberBank,
  302: forteBank,
  303: altynBank,
  304: jusanBank,
  305: centerCreditBank,
  306: homeCreditBank,
  307: eurasianBank,
  308: bankTransferIcon,
  309: kaspiBank,
  310: halykBank,
  311: berekeBank,
  312: freedomBank,
  313: kazpostBank,
  314: rbkBank,
  express_havale: expressHavaleBankIcon,
  cepbank: cepbankIcon,
  aninda_havale: anindaHavaleIcon,
  vip_havale: vipHavaleIcon,
};
