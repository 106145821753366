import logger from "./logger";

export const getSessionReferrer = () => {
  let referrer;

  try {
    referrer = JSON.parse(localStorage.getItem("referrer"));
  } catch (error) {
    logger.error("Referrer was not read from localStorage", {
      error,
    });
  }

  return referrer || {};
};

export const setSessionReferrer = (newValue) => {
  if (typeof window === "undefined") {
    return;
  }

  try {
    localStorage.setItem("referrer", JSON.stringify(newValue));
  } catch (error) {
    logger.error("Referrer was not written to localStorage", {
      error,
    });
  }
};

export default {
  getSessionReferrer,
  setSessionReferrer,
};
