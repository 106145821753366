const CardIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2164_2259)">
      <rect
        x="0.75"
        y="4.75"
        width="22.5"
        height="14.5"
        rx="1.25"
        stroke="#0062EB"
        stroke-width="1.5"
      />
      <rect x="2" y="14" width="4" height="1" rx="0.5" fill="#0062EB" />
      <rect x="2" y="16" width="7" height="1" rx="0.5" fill="#0062EB" />
      <rect x="7" y="14" width="4" height="1" rx="0.5" fill="#0062EB" />
      <rect x="12" y="14" width="4" height="1" rx="0.5" fill="#0062EB" />
      <rect x="17" y="14" width="4" height="1" rx="0.5" fill="#0062EB" />
      <path
        d="M3.11641 8.23282C2.8956 8.23282 2.67976 8.16734 2.49617 8.04467C2.31257 7.92199 2.16948 7.74764 2.08498 7.54364C2.00048 7.33964 1.97837 7.11517 2.02145 6.89861C2.06453 6.68205 2.17086 6.48312 2.32699 6.32699C2.48312 6.17086 2.68205 6.06453 2.89861 6.02145C3.11517 5.97838 3.33964 6.00048 3.54364 6.08498C3.74764 6.16948 3.922 6.31257 4.04467 6.49617C4.16734 6.67976 4.23282 6.8956 4.23282 7.11641V8.23282H3.11641Z"
        fill="#0062EB"
      />
      <path
        d="M6.86172 9.94845C6.86172 10.1693 6.79625 10.3851 6.67358 10.5687C6.5509 10.7523 6.37654 10.8954 6.17255 10.9799C5.96855 11.0644 5.74408 11.0865 5.52751 11.0434C5.31095 11.0003 5.11203 10.894 4.95589 10.7379C4.79976 10.5817 4.69343 10.3828 4.65036 10.1663C4.60728 9.94969 4.62939 9.72522 4.71389 9.52122C4.79839 9.31723 4.94148 9.14287 5.12507 9.0202C5.30866 8.89752 5.52451 8.83205 5.74532 8.83205H6.86172V9.94845Z"
        fill="#0062EB"
      />
      <path
        d="M5.92622 7.75306C5.92622 7.85928 5.89472 7.96312 5.8357 8.05144C5.77669 8.13977 5.69281 8.20861 5.59467 8.24926C5.49653 8.28991 5.38854 8.30054 5.28435 8.27982C5.18017 8.2591 5.08447 8.20794 5.00936 8.13283C4.93425 8.05772 4.88309 7.96202 4.86237 7.85784C4.84165 7.75365 4.85228 7.64566 4.89293 7.54752C4.93358 7.44938 5.00242 7.3655 5.09075 7.30649C5.17907 7.24747 5.28291 7.21597 5.38913 7.21597H5.92622V7.75306Z"
        fill="#0062EB"
      />
      <path
        d="M5.92627 6.67919C5.92627 6.57304 5.95774 6.46927 6.01671 6.381C6.07568 6.29274 6.1595 6.22394 6.25756 6.1833C6.35563 6.14266 6.46354 6.13202 6.56766 6.1527C6.67178 6.17339 6.76742 6.22448 6.84251 6.29952C6.91759 6.37456 6.96873 6.47018 6.98948 6.57429C7.01023 6.67839 6.99964 6.78631 6.95906 6.8844C6.91848 6.98249 6.84972 7.06634 6.76149 7.12536C6.67326 7.18438 6.5695 7.21591 6.46335 7.21597H5.92627V6.67919Z"
        fill="#0062EB"
      />
      <path
        d="M2.17188 9.04477V10.0061C2.17188 10.2799 2.28064 10.5425 2.47425 10.7361C2.66786 10.9297 2.93045 11.0385 3.20425 11.0385C3.47805 11.0385 3.74064 10.9297 3.93425 10.7361C4.12786 10.5425 4.23663 10.2799 4.23663 10.0061V9.04477H2.17188Z"
        fill="#0062EB"
      />
    </g>
    <defs>
      <clipPath id="clip0_2164_2259">
        <rect width="24" height="16" fill="white" transform="translate(0 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default CardIcon;
