import { route } from "preact-router";
import api, { notificationError } from "./api";
import state from "./state";
import { PRE_PROCESSED_PAYMENT_METHODS } from "../constants/paymentMethods";
import { CONST_ERROR_LIST } from "./constants";

let sendDataPromise = null;

const sendFormData = (data, session) => {
  const { status } = state.get() || {};

  data.pixel = state.getPixelData();

  if (status === "form_submitted" && sendDataPromise) {
    return sendDataPromise;
  }

  sendDataPromise = api
    .sendFormData(data, session)
    .then((resp) => {
      if (resp?.status !== 200 || resp?.code === "ERR_NETWORK") {
        state.set({
          status: "opened",
        });

        route(state.getRouteForSession("main"));
      } else if (!PRE_PROCESSED_PAYMENT_METHODS.includes(data?.method)) {
        state.setLoading({ status: false, eventName: "submit" });

        route(state.getRouteForSession(resp?.isError ? "error" : "pending"));
      }

      // second submit try on one session (failed retry case)
      sendDataPromise = null;

      return resp;
    })
    .then((resp) => {
      if (
        !PRE_PROCESSED_PAYMENT_METHODS.includes(
          state.getSelectedPaymentMethod()?.method,
        ) &&
        resp?.status === 200 &&
        resp?.code !== "ERR_NETWORK" &&
        state?.get()?.status === "opened"
      ) {
        state.set({
          isPrivacyPolicyAgreed: false,
        });
        state.redirectToPending();
      }
    });

  return sendDataPromise;
};

const removeCard = (id, session) => {
  return api.removeCard(id, session).then((res) => {
    if (res?.status !== 200 || res?.code === "ERR_NETWORK") {
      notificationError({ error: res, reason: "removeCardError" });
    } else {
      const cards = state.get()?.cards;
      const removedCardIdx = cards?.findIndex((card) => card.identifier === id);
      cards?.splice(removedCardIdx, 1);
      state.set({ cards });
    }

    return res;
  });
};

const processFormData = (data) => {
  return sendFormData(data, state.getSession());
};

const processRemoveCard = (id) => {
  return removeCard(id, state.getSession());
};

const checkLuhn = (num) => {
  const array = `${num}`
    .replace(/\s/g, "")
    .split("")
    .reverse()
    .map((x) => parseInt(x, 10));
  const lastDigit = array.splice(0, 1)[0];
  const sum =
    lastDigit +
    array.reduce((acc, val, i) => {
      if (i % 2 !== 0) {
        return acc + val;
      }

      return acc + (val === 0 ? val : (val * 2) % 9 || 9);
    }, 0);

  return sum % 10 === 0;
};

const checkDates = (month, year) => {
  const date = new Date();
  let currentMonth = date.getMonth() + 1;
  let currentYear = date.getFullYear();
  //  Temporary expire correction card date START //
  const correctionInMonths = 6;
  let computedMonth = currentMonth - correctionInMonths;
  let correctionExpireMonth =
    computedMonth < 1 ? -correctionInMonths : correctionInMonths;
  let correctionExpireYear = computedMonth < 1 ? 1 : 0;

  currentMonth -= correctionExpireMonth;
  currentYear -= correctionExpireYear;
  // Temporary expire correction card date  END //

  if (
    Number(month) > 12 ||
    Number(month) === 0 ||
    currentYear > Number(`20${year}`) ||
    (currentYear === Number(`20${year}`) && currentMonth > Number(month))
  )
    return false;

  return true;
};

const checkValuesLength = (values) => {
  const acceptedCardNumberLengths = [15, 16, 18, 19];
  const cardLength = values.card.replace(/\s/g, "").length;
  const isCardValidLength = acceptedCardNumberLengths.includes(cardLength);

  return (
    isCardValidLength &&
    values.date.replace(/\s/g, "").length === 4 &&
    values.cvv.length === 3
  );
};

const getFieldsErrors = ({ fields, values, areAllFieldsActive }) =>
  fields
    .filter(
      (field) => (field.isActive || areAllFieldsActive) && !field.nonRequired,
    )
    .reduce((acc, field) => {
      const errorKey = field.errorKey || field.name;
      let currentPattern = field.pattern;

      if (field.pattern && typeof field.pattern === "function") {
        currentPattern = field.pattern({
          method: state.getSelectedPaymentMethod(),
        });
      }

      const isValidField = currentPattern
        ? values[errorKey] && currentPattern.test(values[errorKey])
        : values[errorKey];

      if (!isValidField) {
        acc.push({
          key: errorKey,
          value: values[errorKey]
            ? field.validationMessage || CONST_ERROR_LIST.pattern
            : CONST_ERROR_LIST.required,
        });
      }

      return acc;
    }, []);

const isNonCardFormCompleted = ({
  fields,
  values,
  isManuallyTriggered,
  areAllFieldsActive,
}) => {
  return fields
    .filter(
      (field) => (field.isActive || areAllFieldsActive) && !field.nonRequired,
    )
    .every((field) => {
      const isAutosubmitPrevented =
        !isManuallyTriggered && field?.preventAutoSubmit;

      let currentPattern = field.pattern;

      if (field.pattern && typeof field.pattern === "function") {
        currentPattern = field.pattern({
          method: state.getSelectedPaymentMethod(),
        });
      }

      const isFieldCompleted = currentPattern
        ? values[field.name] && currentPattern.test(values[field.name])
        : values[field.name];

      return !isAutosubmitPrevented && isFieldCompleted;
    });
};

const validate = ({
  values,
  errors,
  method,
  fields,
  isManuallyTriggered,
  areAllFieldsActive,
}) => {
  const isValid =
    method === "card"
      ? checkValuesLength(values)
      : isNonCardFormCompleted({
          fields,
          values,
          isManuallyTriggered,
          areAllFieldsActive,
        });

  return Object.keys(errors).length === 0 && isValid;
};

const checkInterval = (val) => {
  if (Number(val) === 0 || Number(val) > 12) return false;

  return true;
};

const sanitizeCardHolderName = (value) => {
  return value?.toString()?.trim().replace(/\s+/g, " ") || "";
};

const detectCardBrand = (val) => {
  const firstChar = val.slice(0, 1);
  const cardNumber = val.replace(/\s/g, "");

  function isVerveCard(cardNumber) {
    const firstSixChars = cardNumber.slice(0, 6);
    const verveRanges = [
      { start: 506099, end: 506198 },
      { start: 650002, end: 650027 },
      { start: 507865, end: 507964 },
    ];

    return verveRanges.some((range) => {
      return firstSixChars >= range.start && firstSixChars <= range.end;
    });
  }

  if (isVerveCard(cardNumber)) {
    return "verve";
  }

  if (
    ["51", "52", "53", "54", "55"].some((prefix) =>
      cardNumber.startsWith(prefix),
    )
  ) {
    return "mastercard";
  }

  if (firstChar === "4") {
    return "visa";
  }

  if (firstChar === "9") {
    return "prostir";
  }

  if (["34", "37"].some((prefix) => cardNumber.startsWith(prefix))) {
    return "amex";
  }

  if (
    ["300", "301", "302", "303", "304", "305", "36", "38"].some((prefix) =>
      cardNumber.startsWith(prefix),
    )
  ) {
    return "dinersclub";
  }

  if (["35"].some((prefix) => cardNumber.startsWith(prefix))) {
    return "jcb";
  }

  return "";
};

export default {
  processFormData,
  processRemoveCard,
  checkLuhn,
  checkDates,
  validate,
  checkInterval,
  detectCardBrand,
  getFieldsErrors,
  sanitizeCardHolderName,
};
