import { useCallback, useEffect, useState } from "preact/hooks";
import state from "./state";
import { deepEqual } from "./deepEqual";
import brand from "../services/brand";
import theme from "./theme";
import colorSchemes from "../colorThemes/colorSchemes";
import testData from "./testData";
import connection from "./connection";
import { ThemeKeys } from "../constants/themeConstants";

export const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback]);
};

export const useData = (initialData, deps = []) => {
  const [data, setData] = useState(initialData);

  const handleDataUpdated = useCallback(
    (newData) => {
      const checkDiff = deps.reduce((acc, val) => {
        if (!acc) {
          if (!deepEqual(initialData[val], newData[val])) {
            acc = true;
          }
        }

        return acc;
      }, false);

      if ((!deepEqual(data, newData) && checkDiff) || deps.length === 0) {
        setData(newData);
      }
    },
    [data, deps, initialData],
  );

  useEffect(() => {
    state.events.on("dataUpdated", handleDataUpdated);

    return () => {
      state.events.off("dataUpdated", handleDataUpdated);
    };
  }, [handleDataUpdated, initialData]);

  return data || initialData;
};

export const useConnection = (deps = []) => {
  const [data, setData] = useState(connection.getConnectionStatus());

  const handleDataUpdated = useCallback(
    (newData) => {
      setData(newData);
    },
    [data, ...deps],
  );

  useEffect(() => {
    connection.events.on("connectionUpdated", handleDataUpdated);

    return () => {
      connection.events.off("connectionUpdated", handleDataUpdated);
    };
  }, [handleDataUpdated]);

  return data;
};

export const useColorScheme = (currentTheme) => {
  const brandScheme = brand.getColorScheme();
  const themeScheme = theme.getColorScheme(currentTheme);
  const scheme = themeScheme || brandScheme;
  const colorTheme = colorSchemes?.[scheme] || colorSchemes?.["blue"];

  if (colorTheme?.fontUrls && colorTheme?.fontUrls.length) {
    colorTheme?.fontUrls.forEach((url) => {
      theme.loadFontByUrl(url);
    });
  }

  return { colorTheme };
};

const getLogoFromPublicFields = () => {
  const selectedMethod = state.getSelectedPaymentMethod();
  const publicFields = selectedMethod?.public_fields || [];

  const logoField = publicFields?.find(
    (field) => field?.name === "hpp_logo_name",
  );

  switch (logoField?.value?.toLowerCase()) {
    case ThemeKeys.MUNZEN:
      return theme.getLogo(ThemeKeys.MUNZEN);

    default:
      return null;
  }
};

export const useLogo = (currentTheme) => {
  const { sessionId, signature } = state.getSession();
  const brandData = brand.getBrand();
  const isNonameTheme = brandData?.theme?.includes("noname");
  const isTestMode = testData.isTestMode(sessionId, signature);
  const themeLogo = theme.getLogo(currentTheme);
  const publicFieldsLogo = getLogoFromPublicFields();

  if (publicFieldsLogo) {
    return publicFieldsLogo;
  }

  if (themeLogo) {
    return themeLogo;
  }

  if (isNonameTheme) {
    return "";
  }

  if (brandData?.logo_white) {
    return brandData?.logo_white;
  }

  if (!isTestMode) {
    return `/api/brand/logo/white/${sessionId}/${signature}`;
  }

  return "";
};

export default null;
