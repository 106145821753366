export default {
  pan: "Número de tarjeta",
  exp: "Fecha de vencimiento",
  "asquad.exp": "Fecha de vencimiento",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Pagar",
  payPM: "Recargar",
  idTransTtl: "ID de transacción",
  pendingTtl: "El pago está siendo procesado",
  "mono.pendingTtl": "Operación en proceso...",
  errorTtl: "Ha ocurrido un error",
  "mono.errorTtl": "Lo sentimos, la transacción ha sido rechazada",
  successPaymentTtl: "El pago fue exitoso",
  "mono.successPaymentTtl": "Transacción exitosa",
  cvvHint: "El código CVV se indica en el reverso de su tarjeta",
  rememberMeHint:
    "Guardamos solo el número de tarjeta y la fecha de vencimiento para futuros pagos en nuestro sitio web",
  "mono.rememberMeHint":
    "Guardamos solo el número de tarjeta y la fecha de vencimiento para pagos posteriores en nuestro sitio web",
  sslHintTitle: "Transferencia de datos segura",
  sslHintDescription: "Se utiliza un protocolo seguro con cifrado de 256 bits",
  required: "Campo obligatorio",
  requiredDatePb: "Ingrese la fecha de vencimiento de la tarjeta",
  requiredCvvPb: "Ingrese el código CVV de la tarjeta",
  invalidPanPb: "Por favor, ingrese un número de tarjeta válido",
  invalidPan: "Número de tarjeta inválido",
  invalidDate: "Fecha inválida",
  invalidCvv: "CVV inválido",
  invalidCardName: "Nombre en la tarjeta inválido",
  expPlaceholder: "MM / AA",
  successPayment: "Pago exitoso",
  paymentId: "ID de pago: ",
  redirectNotice: "Será redirigido en: ",
  continue: "Continuar",
  failedPayment: "Pago fallido",
  backToMethods: "Volver a métodos",
  goBack: "Volver a la tienda",
  retry: "Reintentar",
  noConnection: "Su conexión a internet es inestable. Reconectando...",
  reconnected: "Conexión a internet restablecida",
  intentionExpired: "La intención de pago ha expirado",
  badRequestTitle: "No podemos procesar su solicitud 😢",
  accessDenied: "Acceso denegado ⛔",
  pageNotFound: "Página no encontrada o no existe 😢",
  responseTimeExpired: "El tiempo de respuesta ha expirado ⌛",
  goBackAndRetry:
    "Regrese al sitio web del comerciante e intente el pago nuevamente.",
  callMerchantOrGoBack:
    "Contacte al comerciante o regrese al sitio web del comerciante e intente el pago nuevamente.",
  unexpectedError:
    "No podemos completar su solicitud debido a un error inesperado 😢",
  tryAgainLater: "Intente nuevamente más tarde",
  noResponseFromBank: "No se puede procesar su pago.",
  tryTheFollowing: "Le aconsejamos que pague de nuevo o use otra tarjeta.",
  unableToProcessPayment: "Hubo un error durante su pago.",
  useDifferentCard:
    "En caso de un error repetido, le aconsejamos que contacte a nuestro servicio de soporte.",
  bankInternalErrorTitle:
    "Ocurrió un error al procesar su transacción en el banco",
  transactionRejectedByBank: "El proveedor de pagos ha rechazado el pago.",
  contactBankForReason:
    "Por favor, contacte a su institución financiera para obtener más ayuda o use otro método.",
  bankInvalidCvvDesc: "Código CVV incorrecto.",
  invalidCardCredentialsTitle: "Credenciales de tarjeta inválidas.",
  checkCvvAndTryAgain:
    "Por favor, verifique el código CVV; se encuentra en el reverso de su tarjeta.",
  doubleCheckCardCredential:
    "Por favor, verifique sus credenciales o use una tarjeta diferente.",
  bankInvalidPanTitle: "Número de tarjeta inválido.",
  bankInvalidPanDescription: "Número de tarjeta incorrecto.",
  checkPanAndTryAgain:
    "Por favor, asegúrese de haber ingresado el número de tarjeta correcto",
  cardExpired: "Tarjeta vencida",
  unfortunatelyStatusBankCard:
    "Desafortunadamente, el estado de su tarjeta no le permite realizar un pago.",
  useAnotherCardPayment: "Le aconsejamos que use otra tarjeta para el pago.",
  authorizationFailed: "Autorización fallida.",
  anAuthorizeError: "Error de autorización.",
  pleasePaymentAgain: "Por favor, intente de nuevo o use otro método.",
  cardLostStolen:
    "La tarjeta que ingresó está registrada como 'robada' o 'perdida'.",
  bankCardExpiredTitle: "La fecha de vencimiento de la tarjeta es incorrecta.",
  checkCorrectExpirationDate:
    "Por favor, verifique si la fecha de vencimiento de su tarjeta es correcta o use una tarjeta válida.",
  insufficientFunds: "Fondos insuficientes.",
  useRecommendation: "Use los siguientes consejos.",
  useAnotherCard: "Use otra tarjeta.",
  rechargeYourCard:
    "Recargue la tarjeta con el monto necesario para realizar la transacción.",
  comeBackAndChangeAmount:
    "Regrese a la etapa de selección y especifique el monto disponible en la tarjeta.",
  onlinePaymentLimited:
    "No pudimos procesar su transacción porque se ha excedido o agotado el límite de pago en línea.",
  threeDSecureFailedTitle: "Autorización fallida.",
  tryAgainWithCurrentCard: "Intente de nuevo con la tarjeta actual",
  cardAnother: "Use otra tarjeta",
  cardSaved: "Tarjeta guardada",
  rememberMe: "Recuérdame",
  rememberMePM: "Guarde su tarjeta para futuras recargas",
  bankLimitExceededTitle: "Se excedió el límite de pago en línea.",
  lastThreeDigits:
    "Últimos tres dígitos en el reverso de la tarjeta de crédito",
  sessionExpired: "Su sesión ha expirado.",
  sessionExpiredDesc:
    "Regrese al sitio web del comerciante e intente el pago nuevamente.",
  monthTitle: "Mes",
  yearTitle: "Año",
  sumTitle: "Suma",
  openThe: "Abra el",
  pastePaymentCode: "Pegar código, pago con código",
  howToUse: "Cómo usar ",
  orderTittle: "Pedido",
  loadingTtl: "Por favor, espere",
  "pm1.loadingTtl": "Por favor, espere",
  "privat.loadingTtl": "Espere...",
  "mono.descriptionPaymentTtl": "Pago del pedido",
  applePayTitle: "Pago con",
  last3Digits: "Últimos 3 dígitos en el reverso de su tarjeta",
  publicAgreementStart:
    'Al hacer clic en el botón "Recargar" acepta los términos de la',
  publicAgreementLink: "oferta pública",
  publicAgreementEnd: "para la prestación de servicios",
  // NEW TEXT
  HaveAQuestionAbout:
    '¿Tiene alguna pregunta sobre su pago? Haga clic aquí y vea el video "¿Cómo...?".',
  ApprovalHappensDuring:
    "La aprobación ocurre durante 1 hora después del pago.",
  PaymentNotViaBankSlip:
    "Esto significa que el pago NO es mediante boleto bancario.",
  PayInLotteryUsing: "Pague en Lotería utilizando los detalles a continuación:",
  ConventionCode: "Código de convenio",
  CPFCNPJNumber: "Número CPF/CNPJ",
  TotalDue: "Total adeudado",
  amount: "Monto",
  ProductDescription: "Descripción del producto",
  SavePDF: "GUARDAR PDF",
  Copy: "Copiar",
  Share: "Compartir",
  // PDF
  beneficiary: "Beneficiario",
  agencyBeneficiaryCode: "Código de Agencia/Beneficiario",
  species: "Especie",
  quantity: "Cantidad",
  payer_name: "Nombre del pagador",
  paymentInstructions: "Instrucciones de pago",
  paymentSteps: "Por favor, siga los pasos para realizar el pago:",
  copyAlias: "Copiar CBU/Alias",
  openBankApp: "Abra la aplicación de su banco",
  selectMoneyTransfer: "Seleccione 'Transferencia de dinero'",
  selectNewClient: "Seleccione 'Nuevo cliente'",
  pasteCopiedCbuCode: "Pegue el código CBU/Alias copiado",
  CBUCVUCopy: "Copia de CBU/CVU",
  aliasCopy: "Copia de Alias",
  alias: "Alias",
  ourNumber: "Nuestro número",
  documentNumber: "Número de documento",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "Fecha de vencimiento",
  documentValue: "Valor del documento",
  discountsAllowances: "Descuentos/Subsidios",
  otherDeductions: "Otras deducciones",
  interestPenalty: "Interés/Multa",
  otherAdditions: "Otras adiciones",
  amountCharged: "Monto cobrado",
  send: "Enviar",
  "Amount copied": "Monto copiado",
  topUpAmount: "Monto de recarga",
  receive: "Recibir",
  payer: "Pagador",
  mechanicalAuthentication: "Autenticación mecánica:",
  cutOnTheDottedLine: "Cortar en la línea de puntos",
  paymentLocation:
    "Lugar de pago: Pagadero en cualquier banco hasta la fecha de vencimiento",
  documentDate: "Fecha del documento",
  onTheDocument: "En el documento",
  docType: "Tipo de doc.",
  acceptance: "Aceptación",
  processingDate: "Fecha de procesamiento",
  portfolio: "Cartera",
  lowerCode: "Código inferior",
  mechanicalAuthenticationCompensationSlip:
    "Comprobante de compensación de autenticación mecánica",
  endorserGuarantor: "Endosante/Garante",
  instructions:
    "Instrucciones (Texto bajo la responsabilidad del Beneficiario) No acepte pagos con cheque. Boleto con fecha de vencimiento en fin de semana. ID de transacción: {transID} Si tiene algún problema con la compra, acceda a {email}",
  boletoDetailsTitle:
    'Puede pagar en bancos, loterías, oficinas de correos, supermercados o en línea. También puede guardar el archivo ahora haciendo clic en el botón "Guardar PDF" a continuación.',

  account: "Cuenta",
  virtualAccountTitle:
    "Use su aplicación bancaria para completar esta transferencia",
  bvn: "Número de verificación bancaria",
  bankName: "Nombre del banco",
  cardHolder: "Titular de la tarjeta",
  bankCode: "Código bancario",
  accountNumber: "Número de cuenta",
  accountNumberCopied: "Número de cuenta copiado",
  bankNameCopied: "Nombre del banco copiado",
  phoneCopied: "Teléfono copiado",
  receiverNameCopied: "Nombre del receptor copiado",
  cardHolderCopied: "Titular de la tarjeta copiado",
  bankCodeCopied: "Código bancario copiado",
  accountNameCopied: "Nombre de la cuenta copiado",
  panCopied: "Número de tarjeta copiado",
  accountName: "Nombre de la cuenta",
  remainingTime: "Tiempo restante",
  virtualAccountFooter:
    "Recibirá una confirmación una vez que se reciba el pago",
  preSubmitInfo:
    "Presione el botón a continuación para generar los detalles de su pago",
  showDetails: "Mostrar detalles",
  hideDetails: "Ocultar detalles",
  description: "Descripción",
  transactionIdCopy: "ID de transacción copiado",
  amountCopy: "Monto copiado",
  transactionFailed: "Transacción fallida",
  userIdCopy: "ID do usuário copiado",
  nameCopy: "Nombre copiado",
  name: "Nombre",
  depositInfoMainTitle:
    "Por favor, siga los pasos para realizar el pago mediante este método de depósito:",
  launchApp: "Iniciar la aplicación",
  howToCompletePayment: "¿Cómo completar un pago?",
  openYourBankingAccount: "Abra su cuenta bancaria",
  chooseInteracAmount: "Elija Interac e-transfer y complete el monto",
  fillRecipEmailName:
    "Complete el correo electrónico y nombre del destinatario",
  completeSecret: "Complete la pregunta secreta y la respuesta secreta",
  confirmPayment: "Confirme un pago",
  confirmInBankApp: "Ahora confirme su pago en la aplicación de su banco",
  interacInfoTitle:
    "¡Atención! Por favor, ingrese el monto exacto y el correo electrónico del destinatario. De lo contrario, los fondos no serán acreditados a su cuenta.",
  select: "Seleccionar",
  pleaseSelectOption: "Por favor, seleccione una opción",
  walletVegaInfoContent:
    "Su primer depósito puede fallar debido a requisitos de seguridad (lista blanca). En este caso, recibirá un correo electrónico de Vega Wallet en su dirección de correo electrónico registrada. Por favor, confirme su cuenta haciendo clic en el enlace del correo electrónico y luego intente realizar el depósito nuevamente.",
  walletVegaInfoContentEnding:
    " Para obtener información más detallada, consulte el enlace a continuación.",
  howToUseMustRead: "Cómo usar (lectura obligatoria)",
  walletVegaFormFieldsTitle:
    "Para proceder con el pago, por favor ingrese sus datos de autorización:",
  userId: "ID de Usuario",
  userPassword: "Contraseña",
  "validation.walletVegaUserId":
    "Debe comenzar con U y contener 6 símbolos después",
  "validation.walletVegaUserPassword": "Debe contener de 2 a 32 símbolos",
  enterYourIdentifier: "Ingrese su ID de usuario",
  enterAmount: "Ingrese el monto",
  userNameTransactionAmount:
    "Su nombre de usuario y el monto de la transacción se mostrarán en su pantalla para verificar sus detalles",
  selectPaymentMethod:
    "Seleccione un método de pago y haga clic en 'Pagar ahora'",
  amountComplete15Mins:
    "Los fondos se acreditarán en 15 minutos después de completar la transacción.",
  iHaveMadePayment: "He realizado el pago",
  secretQuestion: "Pregunta secreta",
  secretAnswer: "Respuesta secreta",
  recipientEmail: "Correo electrónico del destinatario",
  recipientName: "Nombre del destinatario",
  answerCopy: "Respuesta copiada",
  questionCopy: "Pregunta secreta copiada",
  emailCopy: "Correo electrónico copiado",
  dial: "Marcar",
  paymentNotificationUssd:
    "Proceda con su pago, se recibirá una notificación al éxito de su depósito",
  whereFindBlikCode: "¿Dónde puedo encontrar el código BLIK?",
  blikCodeInstructions: "Abra la aplicación móvil de su banco e inicie sesión.",
  blikCodeOption:
    "Encuentre la opción BLIK en la sección de Pagos o Transferencias.",
  blikCodeGenerate:
    "Genere el código BLIK de 6 dígitos, que es válido por unos minutos.",
  blikCodeEnter: "Ingrese y envíe el código BLIK.",
  blikCodeLabel: "Código BLIK",
  blikCodeInvalid: "Se requieren 6 dígitos",
  onYourMobileDevice: "en su dispositivo móvil",
  tryAgain: "Inténtalo de nuevo",
  downloadReceipt: "Descargar recibo",
  paymentSuccessfully: "Pago procesado \n exitosamente",
  scanQrTitlePix:
    "Abra su aplicación de pago y ESCANEE el código QR a continuación para pagar, o COPIE el código Pix a continuación y péguelo en su aplicación de pago para completar la compra.",
  boletoGenerated: "¡Boleto generado!",
  youWantDltCard: "¿Está seguro de que desea eliminar la tarjeta?",
  dltCardSystemTitle:
    "Los datos de la tarjeta se eliminarán de la lista de Tarjetas Guardadas, así como de nuestro sistema.",
  cancel: "Cancelar",
  delete: "Eliminar",
  manageSavedCard: "Administrar tarjetas guardadas",
  savedCard: "Tarjetas guardadas",
  transactionStatus: "Estado de la transacción",
  paymentsSlowMessage:
    "Los pagos están actualmente lentos en su banco. Los pagos lentos suelen confirmarse en un plazo de 30 minutos.",
  receiptPaymentConfirmation:
    "Recibirá un recibo una vez que se confirme su pago",
  doNotRefreshPage: "Por favor, no actualice esta página",
  madePayment: "He realizado el pago",
  mins: "min",
  secs: "seg",
  confirmingTransfer:
    "Estamos confirmando su transferencia. Esto podría tomar aproximadamente",
  done: "Hecho",
  manage: "Administrar",
  chooseLanguage: "Elegir idioma",
  savePdf: "Guardar PDF",
  transactionIdTitle: "ID de transacción",
  nameOnCard: "Nombre en la tarjeta",
  email: "Correo electrónico",
  firstName: "Nombre",
  lastName: "Apellido",
  orPayWithCard: "O pagar con tarjeta",
  dateTooltip:
    "La fecha de vencimiento se encuentra debajo del número de la tarjeta (p. ej., 08/25)",
  middleName: "Segundo nombre",
  checkPattern: "Verificar datos ingresados",
  "validation.birthday": "Debe estar en formato ISO: YYYY-MM-DD",
  "validation.phone":
    "Ingrese el número de teléfono en formato internacional: + y 10-15 dígitos.",
  "validation.phone_mobile_money": "Número de teléfono inválido",
  "validation.pin": "Debe tener entre 4 y 12 símbolos",
  phone: "Teléfono",
  country: "País",
  state_code: "Código de estado",
  city: "Ciudad",
  address: "Dirección",
  zip_code: "Código postal",
  card_pin: "PIN de la tarjeta",
  processing_your: "Procesando su",
  payment: "pago",
  incorrect_value: "El valor es incorrecto",
  selectAmountToPay: "Seleccione 'Monto a pagar' de la lista sugerida.",
  initiatedAmountDeclinedTitle: "Sobre la transacción P2P",
  initiatedAmountDeclined:
    "La transferencia P2P es un método de envío de dinero en el cual los datos se intercambian directamente entre los usuarios. Por lo tanto, es posible que no siempre esté disponible para procesar con el monto ingresado. En tales casos, por favor, elija el monto más cercano a su preferencia entre las opciones mostradas a continuación.",
  toCompleteThePaymentFollowSteps: "Para completar el pago, siga estos pasos:",
  clickSubmitToConfirm:
    "Haga clic en 'Enviar' para confirmar el monto de pago seleccionado.",
  afterSelectingAmount:
    "Después de seleccionar el monto, será redirigido a la página del banco para completar el pago.",
  submit: "Enviar",
  time_remaining: "¿No recibió ningún OTP?: ",
  mobileMoneyMainTitle: "Ingrese su Mobile Money para autorizar este pago",
  mobileMoneySubTitle:
    "Se ha enviado una solicitud del operador a su teléfono, por favor ingrese su PIN para autorizar este pago.",
  mobileMoneyInfoTitle:
    "Recibirá una confirmación una vez que recibamos su pago",
  resend_otp: "Reenviar",
  amountToPay: "Monto a pagar",
  upi_qr_title_mob:
    'Escanee el código QR en su dispositivo o toque "ABRIR APP" para completar rápidamente su pago usando UPI.',
  upi_qr_title_desk:
    "Escanee el código QR en su dispositivo para completar rápidamente su pago usando UPI.",
  choose_provider: "Elegir proveedor",
  choose_mobile_operator: "Elegir operador móvil",
  choose_currency: "Elegir moneda",
  choose_receiver_bank: "Elegir banco receptor",
  receiverName: "Nombre del receptor",
  bankAccountInfoTitle:
    "Por favor, realice un pago a la siguiente cuenta bancaria",

  // crypto titles
  scanQRToTopUpWallet: "Escanee el QR para recargar la billetera",
  tryAnotherMethod: "Por favor, intente otro método de pago.",
  verification_code: "Código de verificación",
  number: "Número",
  scanQrTitleOpay:
    "Abra su aplicación de pago y ESCANEE el código QR a continuación para pagar",
  birthday: "Cumpleaños",
  account_number: "Número de cuenta",
  bank_code: "Código bancario",
  code: "Código",
  codeCopied: "Código copiado",
  account_dob_day: "Fecha de creación de la cuenta",
  account_dob_month: "Mes de creación de la cuenta",
  account_dob_year: "Año de creación de la cuenta",
  pin: "Pin",
  search: "Buscar",
  dropdownEmpty: "No se encontró nada. Intente otra consulta",
  chooseMethod: "Por favor, elija un método de pago para comenzar",
  leftHpp: "¿Está seguro de que desea cancelar este pago?",
  pleaseSend: "Por favor envíe",
  "Wallet address": "Dirección de la billetera",
  TAG: "ETIQUETA",
  byCopyCryptoData:
    "copiando la dirección de la billetera o escaneando el código QR:",
  correctNetworkInfoTitle:
    "Asegúrese de que está utilizando la red y la moneda correctas para esta billetera. Cualquier cantidad se convertirá automáticamente en moneda del juego.",
  mistakeLostCryptoFound:
    "Los errores pueden resultar en la pérdida de fondos.",
  noConnectionTryAgain:
    "Su conexión a internet es inestable. Por favor, intente nuevamente",
  selectedMethodError:
    "Hubo un error durante la selección del método. Por favor, intente nuevamente",
  submitError:
    "Hubo un error al enviar el formulario. Por favor, intente nuevamente",
  removeCardError:
    "Hubo un error al eliminar la tarjeta. Por favor, intente nuevamente",
  deletedCard: "La tarjeta fue eliminada",
  redirectNotificationText:
    "El redireccionamiento automático está bloqueado. Por favor, haga clic en el botón de abajo para continuar.",
  redirectNotificationButton: "Proceder al Pago",
  cancelPayment: "Volver al comerciante",
  cancelPaymentText:
    "Algo salió mal, la transacción está tardando más de lo habitual en procesarse. Por favor, espere o vuelva al comerciante.",
  infoAboutPaymentMethodDefault:
    "Haga clic en el botón «Generar detalles de pago» a continuación para continuar. Será redirigido a una página con los detalles del pago e instrucciones claras para completar su pago utilizando su método preferido.",
  buttonPayTextDefault: "Generar detalles de pago",
  date_of_birth: "Fecha de nacimiento",
  accountRequisitesTitleMakePayment:
    "Encuentre los pasos para realizar el pago:",
  accountRequisitesCopyAccountDetails: "Copie los detalles de la cuenta",
  accountRequisitesCopyAccountDetailsCopied: "Detalles de la cuenta copiados",
  accountRequisitesMakeTransfer:
    "Haga la transferencia con los detalles copiados por el monto exacto de",
  accountRequisitesPressButton: "Presione el botón",
  paymentConfirmationSubtitleMadePayment: "«He realizado el pago»",
  accountRequisitesMadeTransfer:
    "La transferencia solo se puede realizar una vez.",
  requisitesErrorTitle: "Se produjo un error al procesar su solicitud",
  requisitesErrorDescription:
    "No se pudo recuperar la información de pago. Por favor, vuelva y seleccione otro método de pago. Lamentamos los inconvenientes.",
  youPay: "Usted Paga",
  youGet: "Usted Recibe",
  enterInformation:
    "Para continuar con la transacción, por favor ingrese la información a continuación.",
  iAgree: "Acepto los",
  termsOfService: "Términos del Servicio",
  and: "y",
  privacyPolicy: "Política de Privacidad",
  of: "de",
  proceed: "Continuar",
  paymentInProgress: "Pago en proceso",
  paymentInProgressDescriptionStart:
    "Si ya realizaste el pago, ¡no te preocupes! Tus fondos se acreditarán en tu saldo en aproximadamente",
  minutes: "minutos",
  paymentInProgressDescriptionEnd: ", dependiendo de la carga de la red.",
  cryptoTopup: "Recarga",
  min: "mínima",
  paymentMinimumLimit:
    "Los pagos por debajo del límite mínimo no serán acreditados.",
  cryptoInfoTooltip: "El monto en criptomonedas equivale a",
  rate: "tasa",
  networkFee: "comisión de red",
  cryptoCurrencyNonActiveTitle:
    "Para continuar con tu pago, por favor sigue estos pasos:",
  cryptoCurrencyNonActiveFirstPoint:
    'Regresa a la página de opciones de pago haciendo clic en el botón "Volver a métodos".',
  cryptoCurrencyNonActiveSecondPoint:
    "Selecciona una criptomoneda alternativa de la lista de opciones disponibles.",
  cryptoCurrencyNonActiveThirdPoint:
    "Confirma tu nueva selección y reintenta el proceso de pago.",
  cryptoCurrencyNonActiveDisclaimer:
    "La criptomoneda seleccionada no está disponible actualmente para transacciones. Por favor, elige otra moneda de las opciones disponibles para continuar.",
  backToWebsite: "Volver al sitio web",
};
