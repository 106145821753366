const challengeDataQr = {
  status: "challenge",
  challenge: {
    type: "qr",
    url: "google.com",
    qr: {
      code: "test",
      image:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAABcklEQVR4Xu2XQY6DQAwEjTjMkSfMT+BjSEHKx5KfzBM4ziHC222SLEG712ZXwhIKTOVg2d02mP8Wtj94x0lOwvgbpJhZW6yZx1z7bsJTpya4ru434HTrcLfISTU8p/vcFuR2LdYfQ2qDW+Z2HDG0BvgQ4uxPurNJy2d/VCQ0Gq3Za1REIkparGWJXgdKsnojLd3DXiVSE7/Po9VhbkOoJdSqJRUtsTU3dwh1UBMUxi4Fv1NO/GN6V0dFqFHLNsCiaM2UP10iIejPxWtvY2aCtt0YGuLGWZUcOwtrwwxmFZMYjyyMe33ODDHhoJwyhZpDnpSJlpREn/oTX7Y+FZKWVkF/OKs2LpERj9yoEBwW+EVOlnVlP3KiRm1THQ2JoFAfmTurbLQjIgXpcFlwbcCxRxBf32ExKDmmMLrkZM2IJGZVOoqEUMMq3y5REseQGDM/aNgkNcGFd+eGs2oA2b1FKwg1SpdQJjbySEx+jpOchPE/yRdfR2Zp6XYNpQAAAABJRU5ErkJggg==",
    },
  },
};

const challengeDataBrLottery = {
  status: "challenge",
  challenge: {
    type: "br",
    url: "https://acs.privatbank.ua/foo",
    pareq: "abcdefgpareq",
    md: "abcdefgmd",
    term_url: "https://checkout.asquad.io/termUrl",
    creq: "abcdefgcreq",
    br: {
      code: "21896934700000012010010005729076802960328318",
      code_type: "ITF_INTERLEAVED_2_OF_5", // enum
      fields: {
        bank_no: "10964739",
        cpf: "502.844.147-27", // auto conversion for current format itn, if symbol != 11, return itn as is. Only for lottery
      },
    },
  },
};

const challengeDataBrBoleto = {
  status: "challenge",
  challenge: {
    type: "br",
    url: "https://acs.privatbank.ua/foo",
    pareq: "abcdefgpareq",
    md: "abcdefgmd",
    term_url: "https://checkout.asquad.io/termUrl",
    creq: "abcdefgcreq",
    br: {
      code: "21896934700000012010010005729076802960328318",
      code_type: "ITF_INTERLEAVED_2_OF_5", // enum
      fields: {
        bank_code: "21890010070572907680129603283184693470000001201", // only for boleto
        pdf_url: "", // for first step empty, only for boleto
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZ8AAAA8AQAAAACRZorNAAAAXElEQVR4Xu3LsQnAIBAFUGfRLoX1cROKwg2hjXALiEKGSRpRyACpskEm+K9/5m31mou63SoHLeGZ8mNHyr5IJ83seYR41x1ddyqzaDgNEhISEhISEhISEhLSf/oA5xwSBuYMv34AAAAASUVORK5CYII=",
      },
    },
  },
};

const bankList = [
  {
    code: "access_bank",
    name: "access_bank",
    country: "JP",
  },
  {
    code: "diamond_bank",
    name: "diamond_bank",
    country: "JP",
  },
  {
    code: "test",
    name: "test",
    country: "JP",
  },
];

export default {
  challengeDataQr,
  challengeDataBrLottery,
  challengeDataBrBoleto,
  bankList,
};
