export default {
  pan: "Номер картки",
  exp: "Термін дії",
  "asquad.exp": "Термін дії",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Оплатити",
  payPM: "Поповнити",
  cvvHint: "Код CVV вказано на звороті картки",
  idTransTtl: "ID Транзакції",
  pendingTtl: "Платіж обробляється",
  "mono.pendingTtl": "Операція в обробці...",
  errorTtl: "Виникла помилка",
  "mono.errorTtl": "Вибачте, транзакція відхилена",
  successPaymentTtl: "Оплата пройшла успішно",
  "mono.successPaymentTtl": "Транзакція успішна",
  rememberMeHint:
    "Ми зберігаємо лише номер картки та термін дії для майбутніх оплат на нашому сайті",
  "mono.rememberMeHint":
    "Ми зберігаємо тільки номер картки і термін дії для подальших оплат на нашому сайті",
  sslHintTitle: "Безпечна передача даних",
  selectAmountToPay: "Виберіть 'Сума до сплати' з запропонованого списку.",
  initiatedAmountDeclinedTitle: "Про P2P транзакцію",
  initiatedAmountDeclined:
    "P2P переказ — це спосіб надсилання грошей, при якому дані обмінюються безпосередньо між користувачами. Тому він може бути недоступний для обробки з введеною вами сумою. У таких випадках, будь ласка, оберіть суму, найбільш близьку до бажаної, з варіантів, представлених нижче.",
  toCompleteThePaymentFollowSteps:
    "Щоб завершити платіж, будь ласка, виконайте ці кроки.",
  clickSubmitToConfirm:
    "Натисніть 'Відправити', щоб підтвердити обрану суму платежу.",
  sslHintDescription:
    "Використовується безпечний протокол із 256-бітним шифруванням",
  afterSelectingAmount:
    "Після вибору суми ви будете перенаправлені на сторінку банку для завершення платежу.",
  required: "Обов'язкове поле",
  requiredDatePb: "Введіть термін дії картки",
  requiredCvvPb: "Вкажіть CVV код картки",
  invalidPanPb: "Вкажіть коректний номер картки",
  invalidPan: "Некоректний номер картки",
  invalidDate: "Некоректна дата",
  invalidCvv: "Некоректний CVV",
  invalidCardName: "Некоректне ім'я картки",
  orPayWithCard: "Або оплата карткою",
  expPlaceholder: "MM / РР",
  successPayment: "Оплата пройшла успішно",
  paymentId: "Ідентифікатор платежу: ",
  redirectNotice: "Ви будете перенаправлені через: ",
  continue: "Продовжити",
  failedPayment: "Оплата не відбулася",
  backToMethods: "Обрати інший метод",
  goBack: "Повернутися до магазину",
  retry: "Повторити спробу",
  noConnection: "Ваше інтернет-з'єднання нестабільне. Повторне підключення...",
  reconnected: "Підключення до Інтернету відновлено",
  intentionExpired: "Термін дії наміру оплати минув",
  badRequestTitle: "Ми не змогли обробити ваш запит 😢",
  accessDenied: "Доступ заборонено ⛔",
  pageNotFound: "Сторінку не знайдено або вона не існує 😢",
  responseTimeExpired: "Час очікування відповіді перевищено ⌛",
  goBackAndRetry: "Поверніться на сайт Мерчанта і повторіть оплату.",
  callMerchantOrGoBack:
    "Зв'яжіться з Мерчантом або поверніться на сайт Мерчанта і повторіть платіж.",
  unexpectedError:
    "Нам не вдалося виконати ваш запит через непередбачену помилку 😢",
  tryAgainLater: "Повторіть оплату пізніше",
  unableToProcessPayment: "Неможливо обробити Ваш платіж.",
  tryTheFollowing: "Під час обробки Вашої трансакції сталася помилка.",
  useDifferentCard:
    "Радимо виконати повторну оплату або скористатися іншою картою. У разі повторення помилки радимо звернутися до технічної підтримки.",
  bankInternalErrorTitle:
    "Під час обробки операції на боці банку сталася помилка",
  transactionRejectedByBank: "Платіжний провайдер відхилив платіж.",
  contactBankForReason:
    "Будь ласка, зверніться до вашої фінансової установи за додатковою допомогою або скористайтеся іншим методом.",
  bankInvalidCvvDesc: "Неправильний код CVV.",
  invalidCardCredentialsTitle: "Неправильні параметри карти.",
  checkCvvAndTryAgain:
    "Будь ласка, двічі перевірте код СVV, його можна знайти на зворотній стороні Вашої банківської карти",
  bankInvalidPanTitle: "Неправильний номер карти.",
  bankInvalidPanDescription:
    "Один із введених параметрів Вашої карти - неправильний.",
  checkPanAndTryAgain:
    "Будь ласка, перевірте, чи дійсно ввели правильний номер банківської карти.",
  cardExpired: "Неправильний термін дії банківської карти.",
  doubleCheckCardCredential:
    "Будь ласка, перевірте чи коректну інформацію ввели, або скористайтеся іншою картою.",
  unfortunatelyStatusBankCard:
    "На жаль, статус Вашої банківської карти обмежує можливість виконати оплату.",
  useAnotherCardPayment: "Радимо скористатится іншою картою для оплати.",
  authorizationFailed: "Невдала авторизація.",
  anAuthorizeError:
    "Під час обробки Вашої трансакції сталася помилка авторизації.",
  pleasePaymentAgain:
    "Будь ласка, спробуйте ще раз або скористайтеся іншим методом.",
  cardLostStolen:
    "Зазначена Вами карта фіксується як 'вкрадена' або 'загублена'.",
  bankCardExpiredTitle:
    "Один із введених параметрів Вашої карти - неправильний.",
  checkCorrectExpirationDate:
    "Будь ласка, перевірте, чи коректно вказаний термін дії Вашої карти, або скористайтеся іншою картою",
  noResponseFromBank: "Неможливо обробити Ваш платіж.",
  insufficientFunds: "Недостатньо коштів на Вашій карті.",
  useRecommendation: "Скористайтеся рекомендаціями, які зазначені нижче.",
  useAnotherCard: "Скористайтеся іншою картою",
  rechargeYourCard:
    "Поповніть карту на суму, необхідну для здійснення операції.",
  comeBackAndChangeAmount:
    "Поверніться на етап вибору та вкажіть суму, яка доступна на карті.",
  threeDSecureFailedTitle: "Банк відхилив операцію через помилку 3D-Secure",
  tryAgainWithCurrentCard: "Повторіть операцію по поточній картці",
  cardAnother: "Використати іншу картку",
  cardSaved: "Збережена карта",
  rememberMe: "Запам'ятати картку",
  rememberMePM: "Зберегти вашу карту для майбутніх поповнень",
  bankLimitExceededTitle: "Перевищено ліміт на онлайн-оплати.",
  onlinePaymentLimited:
    "Ми не змогли обробити вашу транзакцію, оскільки ліміт онлайн-платежів був перевищений або вичерпаний.",
  lastThreeDigits: "Останні три цифри на звороті кредитної картки",
  sessionExpired: "Час сеансу минув.",
  sessionExpiredDesc:
    "Поверніться на сайт продавця та повторіть спробу оплати.",
  monthTitle: "Місяць",
  yearTitle: "Рік",
  sumTitle: "Сума",
  orderTittle: "Номер замовлення",
  loadingTtl: "Будь ласка, зачекайте",
  "pm1.loadingTtl": "Зачекайте, будь ласка",
  "privat.loadingTtl": "Зачекайте...",
  "mono.descriptionPaymentTtl": "Оплата рахунку",
  applePayTitle: "Оплата через",
  last3Digits: "Останні 3 цифри на звороті картки",
  publicAgreementStart: 'Натискаючи кнопку "Поповнити" Ви приймаєте умови',
  publicAgreementLink: "публічної оферти",
  publicAgreementEnd: "щодо надання послуг",
  transactionStatus: "Статус транзакції",
  paymentsSlowMessage:
    "Платежі в даний момент у вашому банку повільні. Зазвичай повільні платежі підтверджуються протягом 30 хвилин.",
  receiptPaymentConfirmation:
    "Ви отримаєте квитанцію після підтвердження вашого платежу.",
  confirmingTransfer: "Ми підтверджуємо ваш переказ. Це може зайняти близько",
  doNotRefreshPage: "Будь ласка, не оновлюйте цю сторінку",
  madePayment: "Я зробив(ла) платіж",
  mins: "хв",
  secs: "сек",
  HaveAQuestionAbout:
    // NEW TEXT
    'Маєте запитання щодо вашої оплати? Натисніть тут і перегляньте відео "Як зробити?..".',
  ApprovalHappensDuring:
    "Затвердження відбувається протягом 1 години після оплати.",
  PaymentNotViaBankSlip:
    "Це означає, що оплата НЕ здійснюється за допомогою банківського квитанції.",
  PayInLotteryUsing: "Оплатити в лотереї, використовуючи наступні дані:",
  ConventionCode: "Код конвенції",
  code: "Код",
  codeCopied: "Код скопійовано",
  openThe: "Відкрити",
  pastePaymentCode: "Вставте код, оплата з кодом",
  howToUse: "Як використовувати ",
  CPFCNPJNumber: "Номер CPF/CNPJ",
  secretQuestion: "Секретне питання",
  questionCopy: "Секретне питання скопійоване",
  secretAnswer: "Секретна відповідь",
  answerCopy: "Відповідь скопійовано",
  recipientEmail: "E-mail отримувача",
  recipientName: "Ім'я отримувача",
  howToCompletePayment: "Як завершити платіж?",
  openYourBankingAccount: "Відкрийте свій банківський рахунок",
  chooseInteracAmount: "Виберіть Interac e-transfer та вкажіть суму",
  fillRecipEmailName: "Введіть email та ім'я отримувача",
  completeSecret: "Заповніть секретне питання та секретну відповідь",
  confirmPayment: "Підтвердіть платіж",
  confirmInBankApp: "Тепер підтвердьте свій платіж у додатку вашого банку",
  interacInfoTitle:
    "Увага! Будь ласка, введіть точну суму та email одержувача. В іншому випадку кошти не будуть зараховані на ваш рахунок.",
  emailCopy: "Електронний лист скопійовано",
  TotalDue: "Cума до сплати",
  dateTooltip:
    "Термін дії картки знаходиться нижче номера картки (наприклад, 08/25)",
  amount: "Сума",
  ProductDescription: "Опис товару",
  SavePDF: "ЗБЕРЕГТИ PDF",
  send: "Відправити",
  receive: "Отримати",
  topUpAmount: "Сума поповнення",
  Copy: "Копіювати",
  Share: "Поділитись",
  // PDF
  beneficiary: "Бенефіціар",
  agencyBeneficiaryCode: "Агентство/Код бенефіціара",
  species: "Вид",
  quantity: "Кількість",
  ourNumber: "Наш номер",
  documentNumber: "Номер документа",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "Термін платежу",
  documentValue: "Сума документа",
  discountsAllowances: "Знижка / Абатменти",
  otherDeductions: "Інші вирахування",
  interestPenalty: "Пеня / Штраф",
  otherAdditions: "Інші нарахування",
  amountCharged: "Сума до сплати",
  payer: "Платник",
  mechanicalAuthentication: "Механічна автентифікація",
  paymentLocation: "Місце платежу",
  documentDate: "Дата документа",
  onTheDocument: "На документі",
  docType: "Тип документа",
  acceptance: "Прийняття",
  processingDate: "Дата обробки",
  portfolio: "Портфель",
  lowerCode: "Код списання",
  mechanicalAuthenticationCompensationSlip:
    "Механічна автентифікація: картка компенсації",
  endorserGuarantor: "Поручитель/Аваліст",
  instructions:
    "Інструкції (текст відповідальності Бенефіціара) Не приймати оплату чеком. Квитанція з погашенням на вихідні дні. ID транзакції: {transID} Якщо виникнуть проблеми з покупкою, зверніться за адресою {email}",
  boletoDetailsTitle:
    'Ви можете сплатити в банках, лотереях, поштових відділеннях, супермаркетах чи онлайн. Ви можете зберегти файл зараз, натиснувши кнопку "Зберегти PDF" нижче.',

  // NEW TRANS await all trans

  account: "Обліковий запис",
  virtualAccountTitle:
    "Використовуйте програму свого банку, щоб завершити цей переказ",
  bvn: "Номер підтвердження банку",
  bankName: "Назва банку",
  cardHolder: "Власник картки",
  bankCode: "Банківський код",
  accountNumber: "Номер рахунку",
  accountNumberCopied: "Номер рахунку скопійовано",
  bankNameCopied: "Назву банка скопійовано",
  phoneCopied: "Телефон скопійовано",
  receiverNameCopied: "Ім'я отримувача скопійовано",
  cardHolderCopied: "Власника картки скопійовано",
  bankCodeCopied: "Банківський код скопійовано",
  accountName: "Назва рахунку",
  accountNameCopied: "Назву рахунку скопійовано",
  panCopied: "Номер картки скопійовано",
  remainingTime: "Час, що залишився",
  virtualAccountFooter: "Після отримання платежу ви отримаєте підтвердження",
  preSubmitInfo:
    "Натисніть кнопку нижче, щоб згенерувати деталі вашого платежу",
  showDetails: "Показати деталі",
  hideDetails: "Приховати деталі",
  description: "Опис",
  transactionIdCopy: "ID транзакції скопійовано",
  amountCopy: "Сума скопійована",
  transactionFailed: "Помилка транзакції",
  tryAgain: "Спробуйте знову",
  downloadReceipt: "Завантажити квитанцію",
  success: "Успіх",
  backToWebsite: "Повернутися на сайт",
  scanQrTitlePix:
    "Відкрийте свою платіжну ПРОГРАМУ та СКАНУЙТЕ QR-код нижче, щоб оплатити, або скопіюйте код Pix нижче та вставте його в платіжну програму, щоб завершити покупку.",
  boletoGenerated: "Boleto створено!",
  youWantDltCard: "Ви впевнені, що хочете видалити картку?",
  dltCardSystemTitle:
    "Дані картки буде видалено зі списку збережених карток, а також із нашої системи.",
  cancel: "Скасувати",
  delete: "Видалити",
  manageSavedCard: "Керування збереженими картками",
  savedCard: "Збережені картки",
  done: "Готово",
  manage: "Керувати",
  chooseLanguage: "Виберіть мову",
  savePdf: "Зберегти PDF",
  transactionIdTitle: "ID Транзакції",
  nameOnCard: "Імʼя на картці",
  email: "Електронна пошта",
  firstName: "Ім'я",
  lastName: "Прізвище",
  middleName: "По батькові",
  checkPattern: "Перегляньте введені дані",
  "validation.birthday": "Має бути у форматі ISO: YYYY-MM-DD",
  "validation.phone":
    "Введіть номер телефону в міжнародному форматі: + і 10-15 цифр.",
  "validation.phone_mobile_money": "Недійсний номер телефону",
  "validation.pin": "Має бути від 4 до 12 символів",
  phone: "Телефон",
  country: "Країна",
  state_code: "Код регіону",
  launchApp: "Запустити додаток",
  userIdCopy: "ID користувача скопійовано",
  nameCopy: "Ім'я скопійовано",
  name: "Ім'я",
  select: "Вибрати",
  pleaseSelectOption: "Будь ласка, виберіть опцію",
  walletVegaInfoContent:
    "Ваш перший депозит може не вдатися через вимоги безпеки (внесення в білий список). У цьому випадку ви отримаєте електронний лист від Vega Wallet на вашу зареєстровану адресу електронної пошти. Будь ласка, підтвердіть свій обліковий запис, натиснувши на посилання в листі, а потім спробуйте знову зробити депозит.",
  walletVegaInfoContentEnding:
    " Для більш детальної інформації, будь ласка, зверніться до посилання нижче.",
  howToUseMustRead: "Як користуватися (обов'язково прочитайте)",
  walletVegaFormFieldsTitle:
    "Щоб продовжити оплату, будь ласка, введіть свої авторизаційні дані:",
  userId: "ID користувача",
  userPassword: "Пароль",
  "validation.walletVegaUserId":
    "Повинно починатися з U і містити 6 символів після нього",
  "validation.walletVegaUserPassword": "Повинно містити від 2 до 32 символів",
  enterYourIdentifier: "Введіть ваш Id",
  enterAmount: "Введіть суму",
  userNameTransactionAmount:
    "Ваше ім'я користувача та сума транзакції будуть відображені на вашому екрані для перевірки ваших даних",
  selectPaymentMethod: "Оберіть спосіб оплати та натисніть 'Оплатити зараз'",
  amountComplete15Mins:
    "Гроші будуть зараховані через 15 хвилин після завершення транзакції.",
  iHaveMadePayment: "Я здійснив платіж",
  dial: "Наберіть",
  depositInfoMainTitle:
    "Будь ласка, ознайомтеся з наведеними нижче кроками для здійснення платежу за допомогою цього методу депозиту:",
  paymentNotificationUssd:
    "Завершіть платіж, і ви отримаєте сповіщення про успішний депозит",
  whereFindBlikCode: "Де я можу знайти код BLIK?",
  blikCodeInstructions: "Відкрийте мобільний додаток вашого банку і увійдіть.",
  blikCodeOption: "Знайдіть опцію BLIK у розділі Платежі або Перекази.",
  blikCodeGenerate:
    "Згенеруйте 6-значний код BLIK, який діє протягом кількох хвилин.",
  blikCodeEnter: "Введіть і відправте код BLIK.",
  blikCodeLabel: "Код BLIK",
  blikCodeInvalid: "Потрібно 6 цифр",
  onYourMobileDevice: "на вашому мобільному пристрої",
  amountToPay: "Сума до оплати",
  payer_name: "Ім'я платника",
  paymentSteps: "Будь ласка, знайдіть кроки, як здійснити платіж:",
  paymentInstructions: "Платіжні інструкції",
  copyAlias: "Скопировать CBU/Псевдонім",
  openBankApp: "Відкрийте програму свого банку",
  selectMoneyTransfer: "Виберіть 'Money Transfer'",
  selectNewClient: "Виберіть 'Новий клієнт'",
  pasteCopiedCbuCode: "Вставте скопійований код CBU/Псевдонім",
  CBUCVUCopy: "CBU/CVU скопійовано",
  aliasCopy: "Псевдонім скопійовано",
  alias: "Псевдонім",
  city: "Місто",
  address: "Адреса",
  zip_code: "Поштовий індекс",
  card_pin: "Картковий пін",
  processing_your: "Обробляємо ваш",
  payment: "платіж",
  incorrect_value: "Значення неправильне",
  submit: "Надіслати",
  time_remaining: "Не отримав одноразовий OTP: ",
  mobileMoneyMainTitle:
    "Введіть дані Mobile Money, щоб авторизувати цей платіж",
  mobileMoneySubTitle:
    "Запит від оператора надіслано на ваш телефон, введіть свій PIN-код, щоб авторизувати цей платіж.",
  mobileMoneyInfoTitle: "Вам надійде сповіщення щойно ми отримаємо ваш платіж",
  resend_otp: "Надіслати",
  upi_qr_title_mob:
    "Відскануйте QR-код на своєму пристрої або натисніть ВІДКРИТИ ДОДАТОК, щоб швидко завершити платіж за допомогою UPI.",
  upi_qr_title_desk:
    "Відскануйте QR-код на своєму пристрої, щоб швидко завершити платіж за допомогою UPI.",
  choose_provider: "Виберіть провайдера",
  choose_mobile_operator: "Виберіть оператора мобільного зв'язку",
  choose_currency: "Виберіть валюту",
  choose_receiver_bank: "Виберіть банк отримувача",
  receiverName: "Ім'я отримувача",
  bankAccountInfoTitle:
    "Будь ласка, зробіть платіж на наступний банківський рахунок",

  // crypto title
  scanQRToTopUpWallet: "Скануйте QR-код для поповнення гаманця",
  tryAnotherMethod: "Будь ласка, спробуйте інший спосіб оплати.",
  verification_code: "Код підтвердження",
  number: "Номер",
  scanQrTitleOpay:
    "Відкрийте платіжну програму та відскануйте QR-код, щоб сплатити",
  birthday: "День народження",
  account_number: "Номер рахунку",
  bank_code: "Код банку",
  account_dob_day: "Дата створення рахунку",
  account_dob_month: "Місяць створення рахунку",
  account_dob_year: "Рік створення рахунку",
  pin: "Пін",
  search: "Пошук",
  dropdownEmpty: "Нічого не знайдено. Спробуйте інший запит",
  chooseMethod: "Виберіть спосіб оплати, щоб почати платіж",
  leftHpp: "Ви впевнені, що бажаєте скасувати цей платіж?",
  noConnectionTryAgain:
    "Ваше підключення до інтернету нестабільне. Спробуйте ще раз",
  selectedMethodError:
    "Сталася помилка під час вибору методу. Будь ласка, спробуйте ще раз",
  pleaseSend: "Будь ласка, надішліть",
  byCopyCryptoData: "скопіювавши адресу гаманця або відсканувавши QR-код:",
  correctNetworkInfoTitle:
    "Переконайтеся, що ви використовуєте правильну мережу та валюту для цього гаманця. Будь-яка сума буде автоматично конвертована в ігрову валюту.",
  mistakeLostCryptoFound: "Помилки можуть призвести до втрати коштів.",
  "Wallet address": "Адрес гаманця",
  TAG: "ТЕГ",
  submitError:
    "Сталася помилка під час відправлення форми. Будь ласка, спробуйте ще раз",
  removeCardError:
    "Сталася помилка під час видалення картки. Будь ласка, спробуйте ще раз",
  deletedCard: "Картка була видалена",
  redirectNotificationText:
    "Автоматичне перенаправлення заблоковано. Будь ласка, натисніть кнопку нижче, щоб продовжити.",
  redirectNotificationButton: "Перейти до оплати",
  cancelPayment: "Повернутися до мерчанта",
  cancelPaymentText:
    "Щось пішло не так, транзакція обробляється довше ніж зазвичай. Будь ласка, зачекайте або поверніться до мерчанта",
  infoAboutPaymentMethodDefault:
    "Натисніть кнопку «Отримати реквізити оплати» нижче, щоб продовжити. Ви будете перенаправлені на сторінку з реквізитами та чіткими інструкціями для завершення платежу за допомогою обраного вами методу.",
  buttonPayTextDefault: "Отримати реквізити оплати",
  date_of_birth: "Дата народження",
  accountRequisitesTitleMakePayment:
    "Ознайомтесь з інструкціями для здійснення платежу:",
  accountRequisitesCopyAccountDetails: "Скопіюйте реквізити рахунку",
  accountRequisitesCopyAccountDetailsCopied: "Реквізити рахунку скопійовано",
  accountRequisitesMakeTransfer:
    "Зробіть переказ за скопійованими реквізитами на точну суму",
  accountRequisitesPressButton: "Натисніть кнопку",
  paymentConfirmationSubtitleMadePayment: "«Я зробив платіж»",
  accountRequisitesMadeTransfer: "Переказ можна зробити лише один раз.",
  requisitesErrorTitle: "Виникла помилка під час обробки вашого запиту",
  requisitesErrorDescription:
    "Не вдалося отримати інформацію про оплату. Будь ласка, поверніться і виберіть інший метод оплати. Вибачте за незручності.",
  youPay: "Ви платите",
  youGet: "Ви отримуєте",
  enterInformation: "Щоб продовжити транзакцію, введіть інформацію нижче.",
  iAgree: "Я погоджуюся з",
  termsOfService: "Умовами обслуговування",
  and: "та",
  privacyPolicy: "Політикою конфіденційності",
  of: "від",
  paymentSuccessfully: "Платіж успішний",
  proceed: "Продовжити",
  paymentInProgress: "Платіж у процесі",
  paymentInProgressDescriptionStart:
    "Якщо ви здійснили платіж, не хвилюйтеся! Ваші кошти будуть зараховані на ваш баланс приблизно через",
  minutes: "хвилин",
  paymentInProgressDescriptionEnd: ", залежно від завантаженості мережі.",
  cryptoTopup: "Поповнення",
  min: "мін",
  paymentMinimumLimit:
    "Платежі нижче мінімального ліміту не будуть зараховані.",
  cryptoInfoTooltip: "Сума криптовалюти дорівнює",
  rate: "курсу",
  networkFee: "комісії мережі",
  cryptoCurrencyNonActiveTitle:
    "Щоб продовжити ваш платіж, будь ласка, виконайте наступні кроки:",
  cryptoCurrencyNonActiveFirstPoint:
    'Поверніться на сторінку вибору способів оплати, натиснувши кнопку "Назад до методів".',
  cryptoCurrencyNonActiveSecondPoint:
    "Виберіть альтернативну криптовалюту зі списку доступних опцій.",
  cryptoCurrencyNonActiveThirdPoint:
    "Підтвердіть свій новий вибір і повторіть процес оплати.",
  cryptoCurrencyNonActiveDisclaimer:
    "Обрана криптовалюта наразі недоступна для транзакцій. Будь ласка, виберіть іншу валюту зі списку доступних для продовження.",
};
