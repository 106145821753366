const TPaga = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="500.000000pt"
    height="162.000000pt"
    viewBox="0 0 500.000000 162.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,162.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M1510 1130 l0 -90 -50 0 -51 0 3 -67 3 -68 45 -1 45 0 5 -180 c4
-144 9 -185 23 -211 20 -38 68 -76 116 -92 65 -21 188 -10 228 21 8 6 5 24
-11 66 -24 60 -22 59 -93 46 -25 -5 -37 -1 -57 19 -26 26 -26 28 -26 176 l0
150 76 3 76 3 2 68 1 67 -77 0 -78 0 0 90 0 90 -90 0 -90 0 0 -90z"
      />
      <path
        d="M1131 1038 c-13 -16 -34 -36 -46 -44 -13 -8 -209 -78 -437 -154 -294
-100 -418 -146 -431 -161 -14 -18 -17 -42 -17 -141 0 -95 3 -119 13 -115 7 3
221 73 476 157 254 83 464 157 467 164 2 6 3 82 2 167 l-3 155 -24 -28z"
      />
      <path
        d="M2257 1036 c-22 -8 -49 -24 -61 -35 -30 -28 -36 -26 -36 9 l0 30 -85
0 -85 0 0 -425 0 -425 90 0 90 0 0 145 0 144 36 -24 c51 -35 109 -48 182 -42
115 9 209 73 256 176 29 65 29 217 0 282 -52 112 -141 170 -269 176 -53 2 -93
-2 -118 -11z m152 -152 c43 -25 72 -76 77 -136 13 -137 -119 -232 -236 -169
-100 53 -105 242 -8 301 41 25 128 27 167 4z"
      />
      <path
        d="M2892 1035 c-57 -16 -107 -39 -117 -55 -3 -5 8 -36 24 -69 l31 -60
45 26 c54 30 146 41 198 24 45 -15 77 -54 77 -92 l0 -29 -117 1 c-166 0 -234
-26 -273 -105 -26 -55 -25 -101 3 -156 36 -72 109 -110 212 -110 71 0 120 16
162 53 l36 31 -5 -37 -4 -37 83 0 83 0 0 214 c0 227 -6 264 -52 326 -12 17
-47 43 -77 58 -48 23 -70 27 -156 29 -65 2 -118 -2 -153 -12z m258 -394 c0
-72 -67 -119 -152 -108 -51 7 -78 32 -78 72 0 57 32 73 148 74 l82 1 0 -39z"
      />
      <path
        d="M3640 1041 c-136 -42 -214 -148 -214 -291 0 -169 111 -285 284 -298
76 -5 133 8 185 44 l38 25 -5 -51 c-6 -60 -30 -97 -79 -123 -54 -29 -188 -20
-263 17 -32 16 -61 24 -65 20 -5 -5 -21 -33 -36 -62 -28 -51 -28 -53 -9 -67
67 -51 242 -84 360 -66 128 19 201 69 248 171 20 42 21 65 24 363 l3 317 -85
0 -86 0 0 -37 0 -36 -22 21 c-43 40 -108 62 -183 61 -38 0 -81 -4 -95 -8z
m247 -183 c32 -33 37 -44 40 -95 7 -101 -53 -163 -158 -163 -130 0 -206 126
-141 232 32 52 73 69 155 65 62 -2 70 -5 104 -39z"
      />
      <path
        d="M4353 1035 c-29 -8 -71 -24 -93 -35 l-40 -20 31 -63 31 -63 48 24
c122 60 266 26 273 -64 l2 -29 -112 -2 c-121 -2 -170 -12 -221 -43 -48 -29
-72 -75 -72 -138 0 -59 15 -94 57 -134 82 -78 267 -77 342 3 l21 23 0 -37 0
-37 85 0 85 0 0 195 c0 222 -9 279 -54 337 -51 68 -111 92 -230 95 -66 2 -119
-2 -153 -12z m257 -389 c0 -96 -143 -155 -211 -87 -31 32 -36 58 -16 83 23 30
52 37 140 38 l87 0 0 -34z"
      />
      <path
        d="M668 503 l-468 -154 0 -60 c0 -50 4 -64 24 -84 49 -49 61 -47 492 94
325 106 403 135 422 156 19 23 22 37 22 116 0 65 -3 89 -12 88 -7 -1 -223 -71
-480 -156z"
      />
    </g>
  </svg>
);

export default TPaga;
