export default {
  pan: "Kart numarası",
  exp: "Son kullanma tarihi",
  "asquad.exp": "Son kullanma tarihi",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Öde",
  payPM: "Yenile",
  idTransTtl: "İşlem Kimliği",
  pendingTtl: "Ödeme işleniyor",
  "mono.pendingTtl": "İşlem sürüyor...",
  errorTtl: "Bir hata oluştu",
  "mono.errorTtl": "Üzgünüz, işlem reddedildi",
  successPaymentTtl: "Ödeme başarılı oldu",
  "mono.successPaymentTtl": "İşlem başarılı",
  cvvHint: "CVV kodu kartınızın arka yüzünde belirtilmiştir",
  rememberMeHint:
    "Gelecekteki ödemeler için kart numarası ve son kullanma tarihini web sitemizde saklıyoruz",
  "mono.rememberMeHint":
    "Web sitemizde sonraki ödemeler için sadece kart numarası ve son kullanma tarihini saklıyoruz",
  sslHintTitle: "Güvenli veri aktarımı",
  sslHintDescription: "256-bit şifrelemeli güvenli protokol kullanılır",
  required: "Zorunlu alan",
  requiredDatePb: "Kartın son kullanma tarihini girin",
  requiredCvvPb: "Kartın CVV kodunu girin",
  invalidPanPb: "Lütfen geçerli bir kart numarası girin",
  invalidPan: "Geçersiz kart numarası",
  invalidDate: "Geçersiz tarih",
  invalidCvv: "Geçersiz CVV",
  invalidCardName: "Geçersiz kart üzerindeki isim",
  expPlaceholder: "AA / YY",
  successPayment: "Ödeme başarılı",
  paymentId: "Ödeme Kimliği: ",
  redirectNotice: "Yönlendirileceksiniz: ",
  continue: "Devam et",
  failedPayment: "Ödeme başarısız",
  backToMethods: "Yöntemlere geri dön",
  goBack: "Mağazaya geri dön",
  retry: "Tekrar dene",
  noConnection: "İnternet bağlantınız kararsız. Yeniden bağlanıyor...",
  reconnected: "İnternet bağlantısı yeniden kuruldu",
  intentionExpired: "Ödeme isteği süresi doldu",
  badRequestTitle: "İsteğinizi işleyemiyoruz 😢",
  accessDenied: "Erişim reddedildi ⛔",
  pageNotFound: "Sayfa bulunamadı veya mevcut değil 😢",
  responseTimeExpired: "Yanıt süresi doldu ⌛",
  goBackAndRetry: "Satıcının web sitesine dönün ve ödemeyi tekrar deneyin.",
  callMerchantOrGoBack:
    "Satıcıyla iletişime geçin veya satıcının web sitesine dönüp ödemeyi tekrar deneyin.",
  unexpectedError:
    "Beklenmeyen bir hata nedeniyle isteğinizi tamamlayamıyoruz 😢",
  tryAgainLater: "Daha sonra tekrar deneyin",
  noResponseFromBank: "Ödemeniz işlenemiyor.",
  tryTheFollowing:
    "Tekrar ödeme yapmanızı veya başka bir kart kullanmanızı öneririz.",
  unableToProcessPayment: "Ödemeniz sırasında bir hata oluştu.",
  useDifferentCard:
    "Tekrar eden bir hata durumunda destek hizmetimizle iletişime geçmenizi öneririz.",
  bankInternalErrorTitle:
    "Bankanın tarafında işleminiz işlenirken bir hata oluştu",
  transactionRejectedByBank: "Ödeme sağlayıcısı ödemeyi reddetti.",
  contactBankForReason:
    "Lütfen daha fazla yardım için finans kurumunuzla iletişime geçin veya başka bir yöntem kullanın.",
  bankInvalidCvvDesc: "Yanlış CVV kodu.",
  invalidCardCredentialsTitle: "Geçersiz kart bilgileri.",
  checkCvvAndTryAgain:
    "Lütfen CVV kodunu iki kez kontrol edin, kartınızın arka yüzünde bulunabilir.",
  doubleCheckCardCredential:
    "Lütfen bilgilerinizi iki kez kontrol edin veya farklı bir kart kullanın.",
  bankInvalidPanTitle: "Geçersiz kart numarası.",
  bankInvalidPanDescription: "Yanlış kart numarası.",
  checkPanAndTryAgain: "Lütfen doğru kart numarası girdiğinizden emin olun",
  cardExpired: "Kartın süresi doldu",
  unfortunatelyStatusBankCard:
    "Maalesef, kartınızın durumu ödeme yapmanıza izin vermiyor.",
  useAnotherCardPayment: "Ödeme için başka bir kart kullanmanızı öneririz.",
  authorizationFailed: "Yetkilendirme başarısız.",
  anAuthorizeError: "Yetkilendirme hatası.",
  pleasePaymentAgain: "Lütfen tekrar deneyin veya başka bir yöntem kullanın.",
  cardLostStolen:
    "Girdiğiniz kart 'çalıntı' veya 'kayıp' olarak kaydedilmiştir.",
  bankCardExpiredTitle: "Kartın son kullanma tarihi yanlış.",
  checkCorrectExpirationDate:
    "Lütfen kartınızın son kullanma tarihinin doğru olduğunu kontrol edin veya geçerli bir kart kullanın.",
  insufficientFunds: "Yetersiz bakiye.",
  useRecommendation: "Aşağıdaki ipuçlarını kullanın.",
  useAnotherCard: "Başka bir kart kullanın.",
  rechargeYourCard:
    "İşlemi gerçekleştirmek için gerekli tutarı kartınıza yükleyin.",
  comeBackAndChangeAmount:
    "Seçim aşamasına geri dönün ve kartta mevcut olan tutarı belirtin.",
  onlinePaymentLimited:
    "İnternet ödeme limitiniz aşıldığı veya tükendiği için işleminizi gerçekleştiremedik.",
  threeDSecureFailedTitle: "Yetkilendirme başarısız.",
  tryAgainWithCurrentCard: "Mevcut kartla tekrar deneyin",
  cardAnother: "Başka bir kart kullanın",
  cardSaved: "Kaydedilmiş kart",
  rememberMe: "Beni hatırla",
  rememberMePM: "Gelecekteki ödemeler için kartınızı kaydedin",
  bankLimitExceededTitle: "Çevrimiçi Ödeme Limiti Aşıldı.",
  lastThreeDigits: "Kredi kartının arka yüzündeki son üç rakam",
  sessionExpired: "Oturumunuzun süresi doldu.",
  sessionExpiredDesc: "Satıcının web sitesine dönün ve ödemeyi tekrar deneyin.",
  monthTitle: "Ay",
  yearTitle: "Yıl",
  sumTitle: "Toplam",
  orderTittle: "Sipariş",
  loadingTtl: "Lütfen bekleyin",
  "pm1.loadingTtl": "Lütfen bekleyin",
  "privat.loadingTtl": "Bekleyin...",
  "mono.descriptionPaymentTtl": "Sipariş ödemesi",
  applePayTitle: "Ödeme",
  last3Digits: "Kartınızın arka yüzündeki son 3 rakam",
  publicAgreementStart: '"Yenile" düğmesine tıklayarak, hizmet sunumu için',
  publicAgreementLink: "kamu teklifinin",
  publicAgreementEnd: "şartlarını kabul edersiniz",
  // NEW TEXT
  HaveAQuestionAbout:
    'Ödemenizle ilgili bir sorunuz mu var? Buraya tıklayın ve "Nasıl Yapılır?.." videosunu izleyin.',
  ApprovalHappensDuring: "Onay, ödemeden sonra 1 saat içinde gerçekleşir.",
  PaymentNotViaBankSlip:
    "Bu, ödemenin banka makbuzu ile OLMADIĞI anlamına gelir.",
  PayInLotteryUsing: "Aşağıdaki detayları kullanarak Lotoda ödeme yapın:",
  ConventionCode: "Konvansiyon kodu",
  CPFCNPJNumber: "CPF/CNPJ numarası",
  TotalDue: "Toplam borç",
  amount: "Tutar",
  ProductDescription: "Ürün Açıklaması",
  SavePDF: "PDF'yi KAYDET",
  Copy: "Kopyala",
  Share: "Paylaş",
  // PDF
  beneficiary: "Faydalanıcı",
  agencyBeneficiaryCode: "Ajans/Faydalanıcı Kodu",
  species: "Tür",
  quantity: "Miktar",
  payer_name: "Ödeyenin adı",
  paymentInstructions: "Ödeme talimatları",
  paymentSteps: "Ödeme nasıl yapılır:",
  copyAlias: "CBU/Alias kopyala",
  openBankApp: "Banka uygulamanızı açın",
  selectMoneyTransfer: "'Para Transferi'ni seçin",
  selectNewClient: "'Yeni müşteri'yi seçin",
  pasteCopiedCbuCode: "Kopyalanan CBU/Alias kodunu yapıştırın",
  CBUCVUCopy: "CBU/CVU kopyala",
  aliasCopy: "Alias kopyala",
  alias: "Alias",
  ourNumber: "Bizim numaramız",
  documentNumber: "Belge numarası",
  cpfCnpj: "CPF/CNPJ",
  dueDate: "Vade tarihi",
  documentValue: "Belge değeri",
  discountsAllowances: "İndirimler/Ödenekler",
  otherDeductions: "Diğer kesintiler",
  interestPenalty: "Faiz/Ceza",
  otherAdditions: "Diğer eklemeler",
  amountCharged: "Alınan tutar",
  send: "Gönder",
  "Amount copied": "Tutar kopyalandı",
  topUpAmount: "Yükleme tutarı",
  receive: "Al",
  payer: "Ödeyen",
  mechanicalAuthentication: "Mekanik kimlik doğrulama:",
  cutOnTheDottedLine: "Kesik çizgi boyunca kesin",
  paymentLocation:
    "Ödeme yeri: Vade tarihine kadar herhangi bir bankada ödenebilir",
  documentDate: "Belge tarihi",
  onTheDocument: "Belgede",
  docType: "Belge Türü",
  acceptance: "Kabul",
  processingDate: "İşlem tarihi",
  portfolio: "Portföy",
  lowerCode: "Alt kod",
  mechanicalAuthenticationCompensationSlip:
    "Mekanik kimlik doğrulama Tazminat Fişi",
  endorserGuarantor: "Cirolayan/Kefil",
  instructions:
    "Talimatlar (Faydalanıcının sorumluluğundaki metin) Çekle ödeme kabul etmeyin. Hafta sonu vade tarihli Boleto. İşlem Kimliği: {transID} Satın alma ile ilgili herhangi bir sorun yaşıyorsanız, {email} adresine erişin",
  boletoDetailsTitle:
    'Bankalarda, piyangolarda, postanelerde, süpermarketlerde veya çevrimiçi olarak ödeme yapabilirsiniz. Ayrıca şimdi aşağıdaki "PDF\'yi Kaydet" düğmesine tıklayarak dosyayı kaydedebilirsiniz.',
  // NEW TRANS await all trans

  account: "Hesap",
  virtualAccountTitle:
    "Bu transferi tamamlamak için banka uygulamanızı kullanın",
  bvn: "Banka doğrulama numarası",
  bankName: "Banka adı",
  cardHolder: "Kart sahibi",
  bankCode: "Banka kodu",
  accountNumber: "Hesap numarası",
  accountNumberCopied: "Hesap numarası kopyalandı",
  bankNameCopied: "Banka adı kopyalandı",
  phoneCopied: "Telefon kopyalandı",
  receiverNameCopied: "Alıcının adı kopyalandı",
  cardHolderCopied: "Kart sahibi kopyalandı",
  bankCodeCopied: "Banka kodu kopyalandı",
  accountNameCopied: "Hesap adı kopyalandı",
  panCopied: "Kart numarası kopyalandı",
  accountName: "Hesap adı",
  remainingTime: "Kalan süre",
  virtualAccountFooter: "Ödeme alındığında bir onay alacaksınız",
  preSubmitInfo:
    "Ödemeniz için ayrıntıları oluşturmak için aşağıdaki düğmeye basın",
  showDetails: "Ayrıntıları göster",
  hideDetails: "Ayrıntıları gizle",
  description: "Açıklama",
  transactionIdCopy: "İşlem Kimliği kopyalandı",
  amountCopy: "Tutar kopyalandı",
  transactionFailed: "İşlem başarısız oldu",
  userIdCopy: "Kullanıcı kimliği kopyalandı",
  nameCopy: "İsim kopyalandı",
  name: "İsim",
  depositInfoMainTitle: "Bu Para Yatırma Yöntemi ile ödeme nasıl yapılır:",
  launchApp: "Uygulamayı başlat",
  howToCompletePayment: "Ödeme nasıl tamamlanır?",
  openYourBankingAccount: "Banka hesabınızı açın",
  chooseInteracAmount: "Interac e-transfer seçin ve Tutarı doldurun",
  fillRecipEmailName: "Alıcı e-postasını ve adını doldurun",
  completeSecret: "Gizli soru ve gizli cevabı tamamlayın",
  confirmPayment: "Ödemeyi onaylayın",
  confirmInBankApp: "Şimdi bankanızın uygulamasında ödemenizi onaylayın",
  interacInfoTitle:
    "Dikkat! Lütfen tam tutarı ve alıcının e-postasını girin. Aksi takdirde, fonlar hesabınıza aktarılmaz.",
  select: "Seç",
  pleaseSelectOption: "Lütfen bir seçenek seçin",
  walletVegaInfoContent:
    "Güvenlik gereksinimleri (beyaz listeye alma) nedeniyle ilk para yatırma işleminiz başarısız olabilir. Bu durumda, kayıtlı e-posta adresinize Vega Cüzdan'dan bir e-posta alacaksınız. Lütfen e-postadaki linke tıklayarak hesabınızı onaylayın ve ardından para yatırma işlemini tekrar deneyin.",
  walletVegaInfoContentEnding:
    " Daha detaylı bilgi için lütfen aşağıdaki linke bakın.",
  howToUseMustRead: "Nasıl kullanılır (mutlaka okuyun)",
  walletVegaFormFieldsTitle:
    "Ödemeye devam etmek için lütfen yetkilendirme bilgilerinizi girin:",
  userId: "Kullanıcı Kimliği",
  userPassword: "Şifre",
  "validation.walletVegaUserId":
    "U ile başlamalı ve ardından 6 sembol içermelidir",
  "validation.walletVegaUserPassword": "2 ile 32 sembol arasında olmalıdır",
  enterYourIdentifier: "Kullanıcı Kimliğinizi girin",
  enterAmount: "Tutarı girin",
  userNameTransactionAmount:
    "Kullanıcı adınız ve işlem tutarınız, bilgilerinizi doğrulamak için ekranınızda görüntülenecektir",
  selectPaymentMethod: "Bir ödeme yöntemi seçin ve 'Şimdi Öde'ye tıklayın",
  amountComplete15Mins:
    "İşlem tamamlandıktan sonra fonlar 15 dakika içinde yüklenecek.",
  iHaveMadePayment: "Ödemeyi yaptım",
  secretQuestion: "Gizli soru",
  secretAnswer: "Gizli cevap",
  recipientEmail: "Alıcının e-postası",
  recipientName: "Alıcının adı",
  answerCopy: "Cevap kopyalandı",
  questionCopy: "Gizli soru kopyalandı",
  emailCopy: "E-posta kopyalandı",
  dial: "Çevir",
  paymentNotificationUssd:
    "Ödemenize devam edin, para yatırmanız başarılı olduğunda bildirim alınacaktır",
  whereFindBlikCode: "BLIK kodunu nerede bulabilirim?",
  blikCodeInstructions: "Bankanızın mobil uygulamasını açın ve giriş yapın.",
  blikCodeOption: "Ödemeler veya Transferler bölümünde BLIK seçeneğini bulun.",
  blikCodeGenerate:
    "Birkaç dakika geçerli olan 6 haneli BLIK kodunu oluşturun.",
  blikCodeEnter: "BLIK kodunu girin ve gönderin.",
  blikCodeLabel: "BLIK kodu",
  blikCodeInvalid: "6 hane gerekli",
  onYourMobileDevice: "mobil cihazınızda",
  tryAgain: "Tekrar deneyin",
  downloadReceipt: "Makbuzu indir",
  paymentSuccessfully: "Ödeme başarıyla \n işlendi",
  scanQrTitlePix:
    "Ödeme uygulamanızı açın ve aşağıdaki QR kodunu TARAMA yaparak ödeyin veya aşağıdaki Pix kodunu KOPYALAYIN ve satın alma işlemini tamamlamak için ödeme uygulamanıza yapıştırın.",
  boletoGenerated: "Boleto oluşturuldu!",
  youWantDltCard: "Kartı silmek istediğinize emin misiniz?",
  dltCardSystemTitle:
    "Kart verileri Kaydedilmiş Kartlar listesinden ve sistemimizden silinecektir.",
  cancel: "İptal",
  delete: "Sil",
  manageSavedCard: "Kaydedilmiş kartları yönet",
  savedCard: "Kaydedilmiş kartlar",
  transactionStatus: "İşlem durumu",
  paymentsSlowMessage:
    "Bankanızda şu anda ödemeler yavaş. Yavaş ödemeler genellikle 30 dakika içinde onaylanır.",
  receiptPaymentConfirmation: "Ödemeniz onaylandığında bir makbuz alacaksınız",
  doNotRefreshPage: "Lütfen bu sayfayı yenilemeyin",
  madePayment: "Ödemeyi yaptım",
  mins: "dk",
  secs: "sn",
  confirmingTransfer: "Transferinizi onaylıyoruz. Yaklaşık sürebilir",
  done: "Tamamlandı",
  manage: "Yönet",
  chooseLanguage: "Dil seçin",
  savePdf: "PDF'yi kaydet",
  transactionIdTitle: "İşlem Kimliği",
  nameOnCard: "Kart üzerindeki isim",
  email: "E-posta",
  firstName: "Ad",
  lastName: "Soyad",
  orPayWithCard: "Veya kartla ödeyin",
  dateTooltip:
    "Son kullanma tarihi kart numarasının altında bulunur (örn. 08/25)",
  middleName: "İkinci ad",
  checkPattern: "Giriş verilerini kontrol edin",
  "validation.birthday": "ISO formatında olmalıdır: YYYY-MM-DD",
  "validation.phone":
    "Telefon numarasını uluslararası formatta girin: + ve 10-15 rakam.",
  "validation.phone_mobile_money": "Geçersiz telefon numarası",
  "validation.pin": "4 ila 12 sembol arasında olmalıdır",
  phone: "Telefon",
  country: "Ülke",
  state_code: "Eyalet kodu",
  city: "Şehir",
  address: "Adres",
  zip_code: "Posta kodu",
  card_pin: "Kart pini",
  processing_your: "İşleniyor",
  payment: "ödeme",
  incorrect_value: "Değer yanlış",
  submit: "Gönder",
  time_remaining: "OTP almadınız mı: ",
  initiatedAmountDeclinedTitle: "P2P işlem hakkında",
  initiatedAmountDeclined:
    "P2P transfer, verilerin kullanıcılar arasında doğrudan değiştirildiği bir para gönderme yöntemidir. Bu nedenle, girdiğiniz miktarla her zaman işlem yapılamayabilir. Bu gibi durumlarda, aşağıda görüntülenen seçeneklerden tercihlerinize en yakın olan miktarı seçin.",
  mobileMoneyMainTitle: "Bu ödemeyi yetkilendirmek için Mobil Paranızı girin",
  mobileMoneySubTitle:
    "Operatörden telefonunuza bir istek gönderildi, lütfen bu ödemeyi yetkilendirmek için PIN'inizi girin.",
  mobileMoneyInfoTitle: "Ödemenizi aldığımızda bir onay alacaksınız",
  resend_otp: "Tekrar gönder",
  amountToPay: "Ödenecek tutar",
  upi_qr_title_mob:
    'Cihazınızda QR kodunu tarayın veya UPI kullanarak ödemenizi hızlı tamamlamak için "UYGULAMAYI AÇ" a dokunun.',
  upi_qr_title_desk:
    "Cihazınızda QR kodunu tarayın ve UPI kullanarak ödemenizi hızlı tamamlayın.",
  choose_provider: "Sağlayıcıyı seçin",
  choose_mobile_operator: "Mobil operatör seçin",
  choose_currency: "Para birimini seçin",
  choose_receiver_bank: "Alıcı bankayı seçin",
  receiverName: "Alıcının adı",
  bankAccountInfoTitle: "Lütfen aşağıdaki banka hesabına ödeme yapın",
  scanQRToTopUpWallet: "Cüzdanı doldurmak için QR'ı tarayın",
  tryAnotherMethod: "Lütfen başka bir ödeme yöntemi deneyin.",
  verification_code: "Doğrulama kodu",
  number: "Numara",
  scanQrTitleOpay:
    "Ödeme uygulamanızı açın ve aşağıdaki QR kodunu TARAMA yapın",
  birthday: "Doğum günü",
  account_number: "Hesap numarası",
  bank_code: "Banka kodu",
  code: "Kod",
  account_dob_day: "Hesap oluşturma tarihi",
  account_dob_month: "Hesap oluşturma ayı",
  account_dob_year: "Hesap oluşturma yılı",
  pin: "Pin",
  search: "Ara",
  dropdownEmpty: "Hiçbir şey bulunamadı. Başka bir sorgu deneyin",
  chooseMethod: "Ödemeye başlamak için lütfen ödeme yöntemini seçin",
  leftHpp: "Bu ödemeyi iptal etmek istediğinizden emin misiniz?",
  noConnectionTryAgain: "İnternet bağlantınız kararsız. Lütfen tekrar deneyin",
  selectedMethodError:
    "Yöntem seçimi sırasında bir hata oluştu. Lütfen tekrar deneyin",
  "Wallet address": "Cüzdan adresi",
  TAG: "Etiket",
  pleaseSend: "Lütfen gönderin",
  byCopyCryptoData: "cüzdan adresini kopyalayarak veya QR kodunu tarayarak:",
  correctNetworkInfoTitle:
    "Bu cüzdan için doğru ağı ve para birimini kullandığınızdan emin olun. Herhangi bir miktar oyun içi para birimine otomatik olarak dönüştürülecektir.",
  mistakeLostCryptoFound: "Hatalar, fon kaybına neden olabilir.",
  submitError:
    "Form gönderimi sırasında bir hata oluştu. Lütfen tekrar deneyin",
  removeCardError: "Kart kaldırılırken bir hata oluştu. Lütfen tekrar deneyin",
  deletedCard: "Kart silindi",
  redirectNotificationText:
    "Otomatik yönlendirme engellendi. Devam etmek için aşağıdaki düğmeye tıklayın.",
  redirectNotificationButton: "Ödemeye Devam Et",
  cancelPayment: "Satıcıya geri dön",
  cancelPaymentText:
    "Bir şeyler ters gitti, işlem her zamankinden daha uzun sürüyor. Lütfen bekleyin veya satıcıya geri dönün.",
  infoAboutPaymentMethodDefault:
    "Devam etmek için aşağıdaki «Ödeme Detaylarını Oluştur» düğmesine tıklayın. Seçtiğiniz yöntemi kullanarak ödemenizi tamamlamak için ödeme detayları ve net talimatlarla bir sayfaya yönlendirileceksiniz.",
  buttonPayTextDefault: "Ödeme Detaylarını Oluştur",
  date_of_birth: "Doğum tarihi",
  accountRequisitesTitleMakePayment: "Ödeme yapmak için adımları bulun:",
  accountRequisitesCopyAccountDetails: "Hesap bilgilerini kopyala",
  accountRequisitesCopyAccountDetailsCopied: "Hesap bilgileri kopyalandı",
  accountRequisitesMakeTransfer:
    "Kopyalanan hesap bilgileri ile belirtilen tutarda transfer yapın",
  accountRequisitesPressButton: "Düğmesine basın",
  paymentConfirmationSubtitleMadePayment: "«Ödemeyi yaptım»",
  accountRequisitesMadeTransfer: "Transfer yalnızca bir kez yapılabilir.",
  requisitesErrorTitle: "İsteğiniz işlenirken bir hata oluştu",
  requisitesErrorDescription:
    "Ödeme bilgileri alınamadı. Lütfen geri dönüp başka bir ödeme yöntemi seçin. Verdiğimiz rahatsızlıktan dolayı özür dileriz.",
  youPay: "Ödemeniz",
  youGet: "Aldığınız",
  enterInformation:
    "İşleme devam etmek için lütfen aşağıdaki bilgileri giriniz.",
  iAgree: "Kabul ediyorum",
  termsOfService: "Hizmet Şartları",
  and: "ve",
  privacyPolicy: "Gizlilik Politikası",
  of: "şirketine ait",
  proceed: "Devam et",
  paymentInProgress: "Ödeme İşlemde",
  paymentInProgressDescriptionStart:
    "Ödemeyi yaptıysanız endişelenmeyin! Fonlarınız yaklaşık",
  minutes: "dakika",
  paymentInProgressDescriptionEnd:
    " içinde bakiyenize yansıtılacaktır, ağ yoğunluğuna bağlı olarak.",
  cryptoTopup: "Yükleme",
  min: "minimum",
  paymentMinimumLimit:
    "Minimum limitin altındaki ödemeler kredi edilmeyecektir.",
  cryptoInfoTooltip: "Kripto miktarı eşittir",
  rate: "oranı",
  networkFee: "ağ ücreti",
  cryptoCurrencyNonActiveTitle:
    "Ödemenize devam etmek için lütfen aşağıdaki adımları izleyin:",
  cryptoCurrencyNonActiveFirstPoint:
    '"Yöntemlere Geri Dön" düğmesine tıklayarak ödeme seçenekleri sayfasına geri dönün.',
  cryptoCurrencyNonActiveSecondPoint:
    "Mevcut seçenekler listesinden alternatif bir kripto para seçin.",
  cryptoCurrencyNonActiveThirdPoint:
    "Yeni seçiminizi onaylayın ve ödeme işlemini tekrar deneyin.",
  cryptoCurrencyNonActiveDisclaimer:
    "Seçilen kripto para birimi şu anda işlemler için kullanılamıyor. Devam etmek için lütfen mevcut seçeneklerden başka bir para birimi seçin.",
  backToWebsite: "Web sitesine geri dön",
};
