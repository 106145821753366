export default {
  pan: "Карт нөмірі",
  exp: "Жарамдылық мерзімі",
  "asquad.exp": "Жарамдылық мерзімі",
  cvv: "CVV",
  cvv2: "CVV2",
  pay: "Төлеу",
  payPM: "Қайта жүктеу",
  idTransTtl: "Транзакция идентификаторы",
  pendingTtl: "Төлем өңделуде",
  "mono.pendingTtl": "Операция өңделуде...",
  errorTtl: "Қате орын алды",
  "mono.errorTtl": "Кешіріңіз, транзакция қабылданбады",
  successPaymentTtl: "Төлем сәтті өтті",
  "mono.successPaymentTtl": "Транзакция сәтті өтті",
  cvvHint: "CVV коды сіздің картаңыздың артқы жағында орналасқан",
  rememberMeHint:
    "Болашақ төлемдер үшін тек карта нөмірі мен жарамдылық мерзімін сақтаймыз",
  "mono.rememberMeHint":
    "Кейінгі төлемдер үшін тек карта нөмірі мен жарамдылық мерзімін сақтаймыз",
  sslHintTitle: "Қауіпсіз деректерді беру",
  sslHintDescription: "256 биттік шифрлау арқылы қауіпсіз протокол қолданылады",
  required: "Міндетті өріс",
  requiredDatePb: "Картаның жарамдылық мерзімін енгізіңіз",
  requiredCvvPb: "Картаның CVV кодын енгізіңіз",
  invalidPanPb: "Жарамды карта нөмірін енгізіңіз",
  invalidPan: "Жарамсыз карта нөмірі",
  invalidDate: "Жарамсыз мерзім",
  invalidCvv: "Жарамсыз CVV",
  invalidCardName: "Картадағы жарамсыз аты",
  expPlaceholder: "AA / YY",
  successPayment: "Төлем сәтті өтті",
  paymentId: "Төлем ID: ",
  redirectNotice: "Сіз қайта бағытталасыз: ",
  continue: "Жалғастыру",
  failedPayment: "Төлем сәтсіз аяқталды",
  backToMethods: "Әдістерге оралу",
  goBack: "Дүкенге оралу",
  retry: "Қайтадан әрекет ету",
  noConnection: "Интернет қосылымы тұрақсыз. Қайта қосылуда...",
  reconnected: "Интернет қосылымы қалпына келтірілді",
  intentionExpired: "Төлем ниеті мерзімі аяқталды",
  badRequestTitle: "Сіздің сұрауыңызды өңдей алмаймыз 😢",
  accessDenied: "Қол жеткізуге тыйым салынды ⛔",
  pageNotFound: "Бет табылмады немесе жоқ 😢",
  responseTimeExpired: "Жауап уақыты аяқталды ⌛",
  goBackAndRetry: "Сатушының сайтына оралып, төлемді қайтадан көріңіз.",
  callMerchantOrGoBack:
    "Сатушымен байланысыңыз немесе оның сайтына оралып, төлемді қайтадан көріңіз.",
  unexpectedError: "Сұрауыңызды орындалмады, себебі күтпеген қате орын алды 😢",
  tryAgainLater: "Кейінірек қайта көріңіз",
  noResponseFromBank: "Төлеміңізді өңдеу мүмкін болмады.",
  tryTheFollowing: "Қайтадан төлемді көріңіз немесе басқа картаны қолданыңыз.",
  unableToProcessPayment: "Төлем кезінде қате орын алды.",
  useDifferentCard:
    "Қайталанған қате жағдайда, біздің қолдау қызметіне хабарласуды ұсынамыз.",
  bankInternalErrorTitle:
    "Банктің тарапынан транзакцияны өңдеу кезінде қате орын алды.",
  transactionRejectedByBank: "Төлем қызмет жеткізушісі төлемді қабылдамады.",
  contactBankForReason:
    "Қосымша көмек үшін қаржылық мекемеңізбен хабарласыңыз немесе басқа әдісті қолданыңыз.",
  bankInvalidCvvDesc: "CVV коды дұрыс емес",
  invalidCardCredentialsTitle: "Карта деректері жарамсыз.",
  checkCvvAndTryAgain:
    "CVV кодын тексеріп, оны қайтадан енгізіңіз. Ол картаңыздың артқы жағында орналасқан.",
  doubleCheckCardCredential:
    "Карта деректерін қайта тексеріңіз немесе басқа картаны қолданыңыз.",
  bankInvalidPanTitle: "Жарамсыз карта нөмірі.",
  bankInvalidPanDescription: "Карта нөмірі дұрыс емес.",
  checkPanAndTryAgain: "Карта нөмірінің дұрыстығын тексеріңіз.",
  cardExpired: "Карта мерзімі аяқталды",
  unfortunatelyStatusBankCard:
    "Өкінішке орай, картаңыздың мәртебесі төлем жасауға мүмкіндік бермейді.",
  useAnotherCardPayment: "Басқа картаны қолдануды ұсынамыз.",
  authorizationFailed: "Авторизация сәтсіз аяқталды.",
  anAuthorizeError: "Авторизация қатесі.",
  pleasePaymentAgain: "Қайтадан көріңіз немесе басқа әдісті қолданыңыз.",
  cardLostStolen:
    "Сіз енгізген карта 'ұрланған' немесе 'жоғалған' деп тіркелген.",
  bankCardExpiredTitle: "Картаның жарамдылық мерзімі дұрыс емес.",
  checkCorrectExpirationDate:
    "Картаның жарамдылық мерзімінің дұрыстығын тексеріңіз немесе жарамды картаны қолданыңыз.",
  insufficientFunds: "Қаражат жеткіліксіз.",
  useRecommendation: "Төмендегі кеңестерді қолданыңыз.",
  useAnotherCard: "Басқа картаны қолданыңыз.",
  rechargeYourCard:
    "Картаны транзакцияны жасау үшін қажетті сомамен толықтырыңыз.",
  comeBackAndChangeAmount:
    "Таңдау кезеңіне қайта оралып, картада қол жетімді соманы көрсетіңіз.",
  onlinePaymentLimited:
    "Онлайн төлем лимиті асып кеткен немесе біткендіктен, транзакцияны өңдеу мүмкін болмады.",
  threeDSecureFailedTitle: "Авторизация сәтсіз аяқталды.",
  tryAgainWithCurrentCard: "Ағымдағы картамен қайта көріңіз",
  cardAnother: "Басқа картаны қолданыңыз",
  cardSaved: "Карта сақталды",
  rememberMe: "Мені есте сақтау",
  rememberMePM: "Картаңызды болашақта қайта жүктеу үшін сақтаңыз.",
  bankLimitExceededTitle: "Онлайн төлем лимиті асып кеткен.",
  lastThreeDigits: "Несиелік картаның артқы жағындағы соңғы үш цифр.",
  sessionExpired: "Сеанс мерзімі аяқталды.",
  sessionExpiredDesc: "Сатушының сайтына оралып, төлемді қайтадан көріңіз.",
  monthTitle: "Ай",
  yearTitle: "Жыл",
  sumTitle: "Сома",
  openThe: "Ашу",
  pastePaymentCode: "Кодты қою, төлем коды арқылы төлеу",
  howToUse: "Қалай қолдану керек ",
  orderTittle: "Тапсырыс нөмірі",
  loadingTtl: "Күте тұрыңыз",
  "pm1.loadingTtl": "Күте тұрыңыз",
  "privat.loadingTtl": "Күтіңіз...",
  "mono.descriptionPaymentTtl": "Тапсырыс төлемі",
  applePayTitle: "Төлеу",
  last3Digits: "Несиелік картаның артқы жағындағы соңғы 3 цифр",
  publicAgreementStart:
    "'Қайта жүктеу' түймесін басу арқылы сіз келісім шарттарын қабылдайсыз",
  publicAgreementLink: "қоғамдық ұсыныс",
  publicAgreementEnd: "қызмет көрсетуге арналған",
  HaveAQuestionAbout:
    'Төлеміңіз туралы сұрағыңыз бар ма? Мұнда басыңыз және "Қалай жасау керек?.." бейнесін көріңіз.',
  ApprovalHappensDuring:
    "Төлемді мақұлдау төлемнен кейін 1 сағатқа дейін болады.",
  PaymentNotViaBankSlip:
    "Бұл төлемнің банк квитанциясы арқылы ЕМЕС екенін білдіреді.",
  PayInLotteryUsing: "Төмендегі мәліметтерді пайдаланып Лотереяда төлеңіз:",
  ConventionCode: "Келісім коды",
  CPFCNPJNumber: "ЖСН/БСН нөмірі",
  TotalDue: "Жалпы сома",
  amount: "Сома",
  ProductDescription: "Өнім сипаттамасы",
  SavePDF: "PDF-ті САҚТАУ",
  Copy: "Көшіру",
  Share: "Бөлісу",
  beneficiary: "Алушы",
  agencyBeneficiaryCode: "Агенттік/Алушы коды",
  species: "Түрі",
  quantity: "Саны",
  payer_name: "Төлеушінің аты",
  paymentInstructions: "Төлем нұсқаулары",
  paymentSteps: "Төлем жасау қадамдарын орындаңыз:",
  copyAlias: "CBU/Атауды көшіру",
  openBankApp: "Банк қосымшасын ашыңыз",
  selectMoneyTransfer: "'Ақша аудару' тармағын таңдаңыз",
  selectNewClient: "'Жаңа клиент' тармағын таңдаңыз",
  pasteCopiedCbuCode: "Көшіру коды CBU/Атауды қойыңыз",
  CBUCVUCopy: "CBU/CVU көшірмесі",
  aliasCopy: "Атауды көшіру",
  alias: "Атау",
  ourNumber: "Біздің нөмір",
  documentNumber: "Құжат нөмірі",
  cpfCnpj: "ЖСН/БСН",
  dueDate: "Жарамдылық мерзімі",
  documentValue: "Құжат мәні",
  discountsAllowances: "Жеңілдіктер / Шегерімдер",
  otherDeductions: "Басқа шегерімдер",
  interestPenalty: "Сыйақы / Айыппұл",
  otherAdditions: "Басқа қосымшалар",
  amountCharged: "Төленген сома",
  send: "Жіберу",
  "Amount copied": "",
  topUpAmount: "Қайта жүктеу сомасы",
  receive: "Қабылдау",
  payer: "Төлеуші",
  "Address wallet": "Әмиян мекенжайы",
  "Address tag": "Мекенжай тегі",
  mechanicalAuthentication: "Механикалық аутентификация",
  cutOnTheDottedLine: "",
  paymentLocation: "Төлем орны",
  documentDate: "Құжат күні",
  onTheDocument: "Құжатта",
  docType: "Құжат түрі",
  acceptance: "Қабылдау",
  processingDate: "Өңдеу күні",
  portfolio: "Портфель",
  lowerCode: "Төменгі код",
  mechanicalAuthenticationCompensationSlip:
    "Механикалық аутентификация өтем парағы",
  endorserGuarantor: "Алушы/Кепілгер",
  instructions:
    "Нұсқаулар (Алушының жауапкершілігі мәтіні) Чекпен төлем қабылдамау. Апта соңында жарамдылық мерзімі бар билет. Транзакция ID: {transID} Сатып алуда мәселе болса, {email} мекенжайына хабарласыңыз",
  boletoDetailsTitle:
    'Банктерде, лотереяларда, Почта бөлімшелерінде, супермаркеттерде немесе онлайн төлеуге болады. Сондай-ақ, төмендегі "PDF сақтау" түймесін басып файлды қазір сақтауға болады.',
  account: "Есепшот",
  virtualAccountTitle:
    "Бұл аударымды аяқтау үшін банкіңіздің қосымшасын қолданыңыз",
  bvn: "банк тексеру нөмірі",
  bankName: "Банк атауы",
  cardHolder: "Карта ұстаушы",
  bankCode: "Банк коды",
  accountNumber: "Есепшот нөмірі",
  accountNumberCopied: "Есепшот нөмірі көшірілді",
  bankNameCopied: "Банк атауы көшірілді",
  phoneCopied: "Телефон көшірілді",
  receiverNameCopied: "Алушы атауы көшірілді",
  cardHolderCopied: "Карта ұстаушы көшірілді",
  bankCodeCopied: "Банк коды көшірілді",
  accountNameCopied: "Есепшот атауы көшірілді",
  panCopied: "Карт нөмірі көшірілді",
  accountName: "Есепшот атауы",
  remainingTime: "Қалған уақыт",
  virtualAccountFooter: "Төлем қабылданған кезде сізге растама жіберіледі",
  preSubmitInfo: "Төлем мәліметтерін жасау үшін төмендегі батырманы басыңыз",
  showDetails: "Мәліметтерді көрсету",
  hideDetails: "Мәліметтерді жасыру",
  description: "Сипаттама",
  transactionIdCopy: "Транзакция идентификаторы көшірілді",
  amountCopy: "Сома көшірілді",
  transactionFailed: "Транзакция сәтсіз аяқталды",
  identifierCopy: "Идентификатор көшірілді",
  nameCopy: "Аты көшірілді",
  name: "Аты",
  depositInfoMainTitle:
    "Бұл әдіспен депозит салу үшін төмендегі қадамдарды орындаңыз:",
  launchApp: "Қосымшаны іске қосу",
  howToCompletePayment: "Төлемді қалай аяқтау керек?",
  openYourBankingAccount: "Банк есепшотыңызды ашыңыз",
  chooseInteracAmount: "Interac e-transfer таңдаңыз және соманы толтырыңыз",
  fillRecipEmailName: "Алушының электрондық поштасы мен атын толтырыңыз",
  completeSecret: "Құпия сұрақ пен құпия жауапты толтырыңыз",
  confirmPayment: "Төлемді растаңыз",
  confirmInBankApp: "Енді төлемді банк қосымшасында растаңыз",
  interacInfoTitle:
    "Назар аударыңыз! Дәл соманы және алушының электрондық поштасын енгізіңіз. Әйтпесе, қаражат шотыңызға аударылмайды.",
  select: "Таңдау",
  enterYourIdentifier: "Пайдаланушы идентификаторын енгізіңіз",
  enterAmount: "Соманы енгізіңіз",
  userNameTransactionAmount:
    "Пайдаланушы аты мен транзакция сомасы экраныңызда көрінеді, мәліметтерді тексеру үшін",
  selectPaymentMethod: "Төлем әдісін таңдап, 'Қазір төлеу' түймесін басыңыз",
  amountComplete15Mins:
    "Транзакция аяқталғаннан кейін қаражат 15 минут ішінде аударылады.",
  iHaveMadePayment: "Мен төлем жасадым",
  secretQuestion: "Құпия сұрақ",
  secretAnswer: "Құпия жауап",
  recipientEmail: "Алушының электрондық поштасы",
  recipientName: "Алушының аты",
  answerCopy: "Жауап көшірілді",
  questionCopy: "Қауіпсіздік сұрағы көшірілді",
  emailCopy: "Электрондық пошта көшірілді",
  dial: "Нөмірді теру",
  paymentNotificationUssd:
    "Төлеміңізді жалғастырыңыз, депозитіңіздің сәтті болғаны туралы хабарлама аласыз",
  whereFindBlikCode: "BLIK кодын қайдан табуға болады?",
  blikCodeInstructions:
    "Банк қосымшасын мобильді құрылғыда ашып, жүйеге кіріңіз.",
  blikCodeOption: "Төлемдер немесе Аударымдар бөлімінде BLIK опциясын табыңыз.",
  blikCodeGenerate: "6 таңбалы BLIK кодын жасаңыз, бірнеше минутқа жарамды.",
  blikCodeEnter: "BLIK кодын енгізіп, жіберіңіз.",
  blikCodeLabel: "BLIK коды",
  blikCodeInvalid: "6 таңба қажет",
  onYourMobileDevice: "мобильді құрылғыда",
  tryAgain: "Қайтадан көру",
  downloadReceipt: "Чекті жүктеу",
  paymentSuccessfully: "Төлем сәтті өңделді \n",
  scanQrTitlePix:
    "Төлеу үшін төмендегі QR кодын ESCANNEI етіңіз немесе Pix кодын төменде КӨШІРІП, төлем қосымшаңызға қойып, сатып алуды аяқтаңыз.",
  boletoGenerated: "Билет жасалды!",
  youWantDltCard: "Картаны жоюға сенімдісіз бе?",
  dltCardSystemTitle:
    "Карта деректері Сақталған Карталар тізімінен, сондай-ақ біздің жүйеден жойылады.",
  cancel: "Бас тарту",
  delete: "Жою",
  manageSavedCard: "Сақталған карталарды басқару",
  savedCard: "Сақталған карталар",
  transactionStatus: "Транзакция күйі",
  paymentsSlowMessage:
    "Сіздің банкіңізде төлемдер баяу жүріп жатыр. Әдетте, баяу төлемдер 30 минут ішінде расталады.",
  receiptPaymentConfirmation: "Төлеміңіз расталғаннан кейін чек аласыз.",
  doNotRefreshPage: "Осы бетті жаңартпаңыз",
  madePayment: "Мен төлем жасадым",
  mins: "мин",
  secs: "сек",
  confirmingTransfer: "Аударымды растаудамыз. Бұл шамамен",
  done: "Дайын",
  manage: "Басқару",
  chooseLanguage: "Тілді таңдаңыз",
  savePdf: "PDF сақтау",
  transactionIdTitle: "Транзакция ID",
  nameOnCard: "Картадағы аты",
  email: "Электрондық пошта",
  firstName: "Аты",
  lastName: "Тегі",
  orPayWithCard: "Немесе картамен төлеңіз",
  dateTooltip:
    "Жарамдылық мерзімі карта нөмірінің астында орналасқан (мысалы, 08/25)",
  middleName: "Ортаңғы аты",
  checkPattern: "Кірген деректерді тексеріңіз",
  "validation.phone":
    "Халықаралық форматта телефон нөмірін енгізіңіз: + және 10-15 цифрлар.",
  phone: "Телефон",
  country: "Ел",
  state_code: "Мемлекет коды",
  city: "Қала",
  address: "Мекенжай",
  zip_code: "Пошта индексі",
  card_pin: "Карта PIN коды",
  processing_your: "Өңделуде",
  payment: "төлем",
  incorrect_value: "Қате мән",
  selectAmountToPay: "Төлем мөлшерін таңдаңыз.",
  initiatedAmountDeclinedTitle: "P2P транзакциясы туралы",
  initiatedAmountDeclined:
    "P2P аударым — бұл ақша жіберудің пайдаланушылар арасында деректер тікелей алмасатын әдісі. Сондықтан енгізген сомамен операция әрдайым мүмкін болмауы мүмкін. Мұндай жағдайларда төменде көрсетілген нұсқалардан өзіңізге ең жақын соманы таңдаңыз.",
  toCompleteThePaymentFollowSteps:
    "Төлемді аяқтау үшін осы қадамдарды орындаңыз.",
  clickSubmitToConfirm:
    "Таңдалған төлем мөлшерін растау үшін 'Жіберу' түймесін басыңыз.",
  afterSelectingAmount:
    "Соманы таңдағаннан кейін сіз төлемді аяқтау үшін банк парағына бағытталасыз.",
  submit: "Жіберу",
  time_remaining: "OTP алмай қалдым: ",
  mobileMoneyMainTitle: "Осы төлемді растау үшін Mobile Money енгізіңіз",
  mobileMoneySubTitle:
    "Оператордан сұрау сіздің телефоныңызға жіберілді. Бұл төлемді растау үшін PIN кодты енгізіңіз.",
  mobileMoneyInfoTitle: "Төлемді алғанымызды растаймыз",
  resend_otp: "Қайта жіберу",
  amountToPay: "Төленетін сома",
  upi_qr_title_mob:
    'QR кодын сканерлеп, немесе "APP АШУ" түймесін басып, UPI пайдаланып төлемді тез аяқтаңыз.',
  upi_qr_title_desk:
    "UPI пайдаланып төлемді тез аяқтау үшін QR кодын сканерлеңіз.",
  choose_provider: "Провайдерді таңдаңыз",
  choose_currency: "Валютаны таңдаңыз",
  choose_receiver_bank: "Алушы банкті таңдаңыз",
  receiverName: "Алушының аты",
  bankAccountInfoTitle: "Келесі банк шотына төлем жасаңыз",
  pleaseOnlySend: "Тек",
  throughThe: "арқылы жіберіңіз",
  riskDeposit: "адресіне жіберіңіз. Әйтпесе, депозитіңізді жоғалту қаупі бар.",
  scanQRToTopUpWallet: "Әмиянды толтыру үшін QR сканерлеңіз",
  walletAddress: "Әмиян мекенжайы",
  tag: "Тег",
  tryAnotherMethod: "Басқа төлем әдісін қолданып көріңіз.",
  verification_code: "Тексеру коды",
  number: "Нөмір",
  scanQrTitleOpay: "Төлем қосымшасын ашып, QR кодын төлеу үшін сканерлеңіз",
  birthday: "Туған күн",
  account_number: "Есепшот нөмірі",
  bank_code: "Банк коды",
  code: "Код",
  codeCopied: "Код көшірілді",
  account_dob_day: "Есепшот ашылған күн",
  account_dob_month: "Есепшот ашылған ай",
  account_dob_year: "Есепшот ашылған жыл",
  pin: "PIN",
  search: "Іздеу",
  dropdownEmpty: "Ештеңе табылмады. Басқа сұрау көріңіз",
  chooseMethod: "Төлемді бастау үшін төлем әдісін таңдаңыз",
  leftHpp: "Осы төлемнен бас тартуға сенімдісіз бе?",
  noConnectionTryAgain: "Интернет қосылымы тұрақсыз. Қайта көріңіз",
  selectedMethodError: "Әдісті таңдауда қате орын алды. Қайта көріңіз",
  submitError: "Пішінді жіберуде қате орын алды. Қайта көріңіз",
  removeCardError: "Картаны жоюда қате орын алды. Қайта көріңіз",
  deletedCard: "Карта жойылды",
  infoAboutPaymentMethodDefault:
    "Төмендегі «Төлем мәліметтерін жасау» түймесін басып, жалғастырыңыз. Сіз төлем мәліметтері бар параққа және таңдаған әдіспен төлемді аяқтау үшін айқын нұсқауларға бағытталасыз.",
  buttonPayTextDefault: "Төлем мәліметтерін жасау",
  date_of_birth: "Туған күн",
  choose_mobile_operator: "Мобильді операторды таңдаңыз",
  redirectNotificationText:
    "Автоматты бағыттау бұғатталды. Жалғастыру үшін төмендегі батырманы басыңыз.",
  redirectNotificationButton: "Төлемді жалғастыру",
  cancelPayment: "Саудагерге оралу",
  cancelPaymentText:
    "Қате орын алды, транзакцияны өңдеу ұзақ уақыт алуда. Күте тұрыңыз немесе саудагерге оралыңыз",
  accountRequisitesTitleMakePayment:
    "Төлемді қалай жасауға болатындығын орындаңыз:",
  accountRequisitesCopyAccountDetails: "Есепшот мәліметтерін көшіріңіз",
  accountRequisitesCopyAccountDetailsCopied: "Есепшот мәліметтері көшірілді",
  accountRequisitesMakeTransfer:
    "Көшірілген есепшот мәліметтерімен дәл сомада аударым жасаңыз",
  accountRequisitesPressButton: "«Мен төлем жасадым» түймесін басыңыз",
  accountRequisitesMadeTransfer: "Аударым тек бір рет жасалуы мүмкін.",
  youPay: "Сіз төлейсіз",
  youGet: "Сіз аласыз",
  iAgree: "Мен келісемін",
  termsOfService: "Қызмет көрсету шарттары",
  privacyPolicy: "Құпиялылық саясаты",
  of: "туралы",
  proceed: "Жалғастыру",
  "Wallet address": "Әмиян мекенжайы",
  TAG: "Тег",
  userIdCopy: "Пайдаланушы идентификаторы көшірілді",
  walletVegaInfoContent:
    "Алғашқы депозит қауіпсіздік талаптарына байланысты сәтсіз аяқталуы мүмкін (ақ тізімге енгізу). Бұл жағдайда сіздің тіркелген электрондық пошта мекенжайыңызға Vega Wallet-тен хат аласыз. Электрондық поштаның сілтемесіне басып, аккаунтыңызды растаңыз, содан кейін депозитті қайта жасаңыз.",
  walletVegaInfoContentEnding:
    " Толық ақпарат алу үшін төмендегі сілтемеге жүгініңіз.",
  howToUseMustRead: "Қалай қолдану керек (оқу міндетті)",
  walletVegaFormFieldsTitle:
    "Төлемді жалғастыру үшін рұқсат мәліметтеріңізді енгізіңіз:",
  userId: "Пайдаланушы идентификаторы",
  userPassword: "Құпиясөз",
  "validation.walletVegaUserId":
    "U әрпімен басталып, одан кейін 6 символ болуы керек",
  "validation.walletVegaUserPassword": "2-ден 32 символға дейін болуы керек",
  success: "Сәтті аяқталды",
  backToWebsite: "Сайтқа оралу",
  "validation.birthday": "ISO форматында болуы керек: YYYY-MM-DD",
  "validation.phone_mobile_money": "Жарамсыз телефон нөмірі",
  "validation.pin": "4-тен 12 символға дейін болуы керек",
  pleaseSend: "Жіберіңіз",
  byCopyCryptoData: "әмиян мекенжайын көшіру немесе QR кодын сканерлеу арқылы:",
  correctNetworkInfoTitle:
    "Әмиянға дұрыс желі мен валютаны пайдаланып жатқаныңызға көз жеткізіңіз. Кез келген сома автоматты түрде ойын валютасына айналдырылады.",
  mistakeLostCryptoFound: "Қателіктер қаражат жоғалуына әкелуі мүмкін",
  enterInformation:
    "Транзакцияны жалғастыру үшін төмендегі ақпаратты енгізіңіз.",
  and: "және",
  paymentInProgress: "Төлем өңделуде",
  paymentInProgressDescriptionStart:
    "Егер сіз төлем жасаған болсаңыз, алаңдамаңыз! Сіздің қаражатыңыз шамамен",
  minutes: "минут",
  paymentInProgressDescriptionEnd:
    " ішінде балансыңызға аударылады, желінің жүктемесіне байланысты.",
  cryptoTopup: "Толықтыру",
  min: "Минималды",
  paymentMinimumLimit: "Минималды шектен төмен төлемдер есепке алынбайды.",
  cryptoInfoTooltip: "Крипто сомасы тең",
  rate: "бағамға",
  networkFee: "желілік комиссияға",
  cryptoCurrencyNonActiveTitle:
    "Төлемді жалғастыру үшін келесі қадамдарды орындаңыз:",
  cryptoCurrencyNonActiveFirstPoint:
    '"Әдістерге оралу" батырмасын басу арқылы төлем опциялары бетіне оралыңыз.',
  cryptoCurrencyNonActiveSecondPoint:
    "Қол жетімді опциялар тізімінен балама криптовалютаны таңдаңыз.",
  cryptoCurrencyNonActiveThirdPoint:
    "Жаңа таңдауыңызды растаңыз және төлем процесін қайта бастаңыз.",
  cryptoCurrencyNonActiveDisclaimer:
    "Таңдалған криптовалюта қазіргі уақытта транзакциялар үшін қол жетімсіз. Жалғастыру үшін қол жетімді опциялардан басқа валютаны таңдаңыз.",
};
